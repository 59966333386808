import { VariantToTrack } from '../../../shared/model/VariantToTrack';
import {
  ProductV1,
  ProductV2,
  ProductVariantTypeV2,
  TrackV2,
} from './product-mapper.types';
import { Supplier } from '../../model/supplier';
import { GetProductForTable, GetProductsResponse } from '../../model/product';

export function mapProductV1toV2(product: ProductV1): ProductV2 {
  const images =
    product.productImages?.map((image) => ({
      imageId: image.id,
      isDefault: image.isDefault,
    })) || [];
  const options = product.options || [];
  let packs = [];
  const variantToPackages = product.ProductVariants.map(
    (pv) => pv.VariantToPackages,
  ).flat();

  if (product.type === 'simple') {
    packs = product.ProductVariants.filter(
      (variant) => variant.type === 'package',
    ).map((variant) => ({
      ...variant,
      bundleOfId: variant.packageParentId ? variant.packageParentId : null,
      bundleOfSku: product.ProductVariants[0].sku,
      bundleId: variantToPackages.find(
        (vtp) => vtp?.packageVariantId === variant.id,
      )?.id,
    }));
  } else if (product.type === 'variable') {
    packs = product.ProductVariants.filter(
      (variant) => variant.type === 'package',
    ).map((variant) => ({
      ...variant,
      bundleOfId: variant.packageParentId ? variant.packageParentId : null,
      bundleOfSku: variant.variantSku,
      bundleId: variantToPackages?.find(
        (vtp) => vtp?.packageVariantId === variant.id,
      )?.id,
    }));
  }

  const productV2: ProductV2 = {
    name: product.name,
    description: product.description,
    isDsm: Boolean(!product.ProductVariants[0]?.manageStockLevel),
    isWeighted: Boolean(product.ProductVariants[0]?.isWeightedScale),
    productType: product.type,
    supplierId: product.supplierId,
    brandId: product.brandId,
    categoryId: product.categoryId,
    trackType: product.ProductVariants[0]?.trackType || null,
    variants: product.ProductVariants.map((variant) => ({
      id: variant.id,
      name: variant.name,
      sku: variant.sku,
      type: (variant.type === 'package'
        ? 'pack'
        : variant.type) as ProductVariantTypeV2,
      barcode: variant.barCode,
      description: variant.description,
      weight: variant.shippingWeight || null,
      length: variant.shippingLength || null,
      width: variant.shippingWidth || null,
      height: variant.shippingHeight || null,
      isSellable: variant.isSellable,
      isPurchasable: variant.isPurchasable,
      isTaxable: variant.isTaxable,
      isActive: variant.isSelected,
      options: Array.isArray(variant.variantAttributes)
        ? variant.variantAttributes
        : JSON.parse(variant.variantAttributes || '[]'),
      bundles: packs
        .filter((p) => p.sku === variant.sku)
        .map((pack) => ({
          bundleOfId: pack.bundleOfId,
          bundleOfSku: pack.bundleOfSku,
          variantId: pack.id,
          rate: Number(pack.rate),
          id: pack.bundleId,
        })),
      locations: variant.ProductVariantToStockLocations.map((location) => ({
        id: location.id,
        locationId: location.stockLocationId,
        taxId: location.taxId,
        cost: Number(Number(location.cost || 0).toFixed(8)),
        quantity: Number(Number(location.quantity || 0).toFixed(8)),
        buyPrice: Number(Number(location.buyPrice || 0).toFixed(8)),
        retailPrice: Number(Number(location.retailPrice || 0).toFixed(8)),
        wholesalePrice: Number(Number(location.wholeSalePrice || 0).toFixed(8)),
        locationName: location.StockLocation?.name,
        locationActive: location.StockLocation?.isActive,
      })),
      customFieldsData: variant.CustomFieldsData?.map((field) => ({
        id: field.id,
        customFieldId: field.customFieldId,
        module: field.module,
        variantId: field.contextId,
        value: field.value,
        customFieldJSON: field.customFieldJSON,
      })),
      imageId: variant.image?.id || null,
      extras:
        variant.Extras?.map((extra) => ({
          id: extra.id,
          name: extra.name,
          imageId: extra.fileId,
          locations: extra.VariantExtraLocations.map((location) => ({
            price: Number(Number(location.price).toFixed(8)),
            locationId: location.stockLocationId,
            id: location.id,
          })),
          partOf: extra.hasOtherProduct
            ? {
                productVariantId: extra.productVariantId,
                quantity: extra.rate,
              }
            : null,
        })) || [],
    })),
    images,
    options: options.map((option) => ({
      id: option.id,
      option: option.option,
      values: option.values.map((value) => ({
        id: option.valuesV2?.find((v) => v.value === value)?.id,
        value,
      })),
    })),
  };

  return productV2;
}

export function mapProductV2toV1(
  product: ProductV2,
  suppliers: Supplier[],
): ProductV1 {
  const images =
    product.images?.map((productImages) => ({
      link: productImages.image.resourceUrl,
      id: productImages.imageId,
      ProductToFile: {
        productId: product.id,
        isDefault: productImages.isDefault,
      },
    })) || [];
  const tracks = product.variants.reduce<VariantToTrack[]>(
    (acc, variant) => [
      ...acc,
      ...variant.tracks?.map((track) => ({
        productVariantId: variant.id,
        stockLocationId: track.locationId,
        issueDate: track.issuedAt,
        expiryDate: track.expiredAt,
        Supplier: suppliers.find(
          (supplier) => supplier.id === track.supplierId,
        ),
        ...track,
      })),
    ],
    [],
  );
  const packs = product.variants.filter((variant) => variant.type === 'pack');

  const productV1: ProductV1 = {
    id: product.id,
    name: product.name,
    description: product.description,
    type: product.productType,
    supplierId: product.supplierId,
    Brand: product.brandId && {
      id: product.brand?.id,
      name: product.brand?.name,
    },
    brandId: product.brandId,
    categoryId: product.categoryId,
    Category: product.categoryId && {
      id: product.category?.id,
      name: product.category?.name,
      level: product.category?.level,
      parentId: product.category?.parentId,
      parents: product.category?.parents,
    },
    Supplier: suppliers.find((supplier) => supplier.id === product.supplierId),
    VariantOptions: product.options?.map((option) => ({
      id: option.id,
      productId: product.id,
      option: option.option,
      values: option.values.map((value) => value.value),
      valuesV2: option.values,
    })),
    deletedPackageIds: [],
    deletedVariant: [],
    Files: images,
    ProductVariants: product.variants.map((variant) => ({
      name: variant.name,
      sku: variant.sku,
      type: variant.type === 'pack' ? 'package' : variant.type,
      barCode: variant.barcode,
      description: variant.description,
      shippingWeight: variant.weight ? variant.weight : null,
      shippingLength: variant.length ? variant.length : null,
      shippingWidth: variant.width ? variant.width : null,
      shippingHeight: variant.height ? variant.height : null,
      isSellable: variant.isSellable,
      isPurchasable: variant.isPurchasable,
      isPackgable: variant.isPackable,
      isSelected: variant.isActive,
      manageStockLevel: !product.isDsm,
      isWeightedScale: product.isWeighted,
      trackType: product.trackType,
      image: variant.imageId ? { id: variant.imageId } : null,
      brandId: product.brandId,
      categoryId: product.categoryId,
      isTaxable: variant.isTaxable,
      supplierId: product.supplierId,
      id: variant.id,
      productId: product.id,
      packageVariantId:
        variant.bundles?.length > 0 &&
        variant.type === 'child' &&
        product.productType === 'simple'
          ? variant.bundles[0].bundleOfId
          : null,
      variantAttributes: variant.options?.length
        ? JSON.stringify(
            variant.options.map((o) => {
              const option = product.options.find(
                (opt) => opt.id === o.optionId,
              );
              return {
                option: option.option,
                value: option?.values.find((v) => v.id === o.valueId).value,
                optionId: option.id,
              };
            }),
          )
        : '',
      Files: images
        .filter((image) => image.id === variant.imageId)
        .map((image) => ({
          link: image.link,
          ProductVariantToFile: {
            productVariantId: variant.id,
            fileId: image.id,
          },
        })),
      ProductVariantToStockLocations: variant.locations.map((location) => ({
        id: location.id,
        productVariantId: variant.id,
        stockLocationId: location.locationId,
        StockLocation: {
          name: location.locationName,
          id: location.locationId,
          isActive: location.locationActive,
        },
        taxId: location.taxId,
        cost: location.cost,
        quantity: location.quantity,
        buyPrice: location.buyPrice,
        initialQuantity: location.quantity,
        initialCost: location.cost,
        retailPrice: location.retailPrice,
        wholeSalePrice: location.wholesalePrice,
        VariantToTracks: tracks
          .filter(
            (track) =>
              track.productVariantId === variant.id &&
              track.stockLocationId === location.locationId,
          )
          .map((track) => ({
            trackNo: track.trackNo,
            issueDate: track.issueDate,
            expiryDate: track.expiryDate,
            quantity: Number(track.quantity).toFixed(2),
            stockLocationId: track.stockLocationId,
            supplierId: track.supplierId,
            Supplier: track.Supplier,
          })),
      })),
      CustomFieldsData: variant.customFieldsData?.map((field) => ({
        customFieldId: field.customFieldId,
        module: field.module,
        contextId: field.variantId,
        value: field.value,
        customFieldJSON: field.customFieldJSON,
      })),
      Extras: variant.extras?.map((extra) => ({
        id: extra.id,
        name: extra.name,
        hasSameLocationPrice: true,
        hasOtherProduct: extra.partOf !== null && extra.partOf !== undefined,
        rate: extra?.partOf?.quantity || 0,
        productVariantId: extra?.partOf?.productVariant.id,
        productVariantName: extra?.partOf?.productVariant.name,
        fileId: extra.imageId,
        File: images.find((image) => image.id === extra.imageId),
        ProductVariant: {
          id: extra?.partOf?.productVariant.id,
          name: extra?.partOf?.productVariant.name,
        },
        VariantExtraLocations: extra.locations.map((location) => ({
          price: location.price,
          id: location.id,
          stockLocationId: location.locationId,
        })),
      })),
      VariantToPackages: [],
    })),
  };

  if (productV1.type === 'simple' && packs.length > 0) {
    const bundles = packs.flatMap((pack) => pack.bundles);
    const mainVariant = productV1.ProductVariants.find(
      (variant) => variant.type === 'child',
    );
    if (mainVariant) {
      mainVariant.VariantToPackages = bundles.map((bundle) => ({
        id: bundle.id,
        rate: Number(bundle.rate),
        type: 'package',
        packageVariantId: bundle.variantId,
        productVariantId: bundle.bundleOfId,
      }));
    }
  } else if (productV1.type === 'variable' && packs.length > 0) {
    const bundles = packs.flatMap((pack) => pack.bundles);
    const childs = productV1.ProductVariants.filter(
      (variant) => variant.type === 'child',
    );
    childs.forEach((child) => {
      child.VariantToPackages = bundles
        .filter((bundle) => bundle.bundleOfId === child.id)
        .map((bundle) => ({
          id: bundle.id,
          rate: Number(bundle.rate),
          packageVariantId: bundle.variantId,
          productVariantId: bundle.bundleOfId,
        }));
    });
  }
  return productV1;
}

export function mapProductsToInvService(
  products: GetProductsResponse[],
  meta: { total: number; limit: number; offset: number },
  suppliers?: Supplier[],
): { rows: GetProductForTable[]; count: number } {
  const rows: GetProductForTable[] = [];
  products.forEach((product) => {
    const productSupplier = suppliers?.find(
      (supplier) => supplier.id === product.supplierId,
    );
    const updatedProduct: GetProductForTable = {
      ...product,
      Product: {
        type: product.productType,
      },
      ProductVariantToStockLocations: [
        {
          quantity: product.quantity,
        },
      ],
      type: product.productType,
      baseProductName: product.productName,
      supplierName: productSupplier?.name ?? '',
      retailPrice: Number(product.retailPrice.toFixed(2)),
      wholeSalePrice: Number(product.wholesalePrice.toFixed(2)),
      isWeightedScale: product.isWeighted,
      barCode: product.barcode,
      manageStockLevel: !product.isDsm,
      shippingWeight: product.weight,
      isPackgable: product.isPackable,
    };
    rows.push(updatedProduct);
  });
  return { rows, count: meta.total };
}

export function mapTracksV1ToV2(tracks: VariantToTrack[]): TrackV2 {
  const trackV2: TrackV2 = {
    productVariantId: tracks[0].productVariantId,
    tracks: tracks?.map((track) => ({
      expiredAt: track.expiryDate,
      trackNo: track.trackNo,
      quantity: track.quantity,
      issuedAt: track.issueDate,
      locationId: track.stockLocationId,
      supplierId: track.supplierId,
    })),
  };

  return trackV2;
}
