import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig} from 'primeng/dynamicdialog';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'rw-delete-supplier',
  templateUrl: './delete-supplier.component.html',
  styleUrls: ['./delete-supplier.component.scss'],
})
export class DeleteSupplierComponent implements OnInit, OnDestroy {
  @Input() content: any = {};

  @Input() dialog = true;

  lng: string;

  langCss: string;

  $destroyed = false;

  element: HTMLCollectionOf<Element>;

  constructor(
    private injector: Injector,
    private localStorageService: LocalStorageService,
    public translate: TranslateService,
    private renderer: Renderer2,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit(): void {
    const config = <DynamicDialogConfig>this.injector.get(DynamicDialogConfig);
    if (config.data && config.data.content) {
      this.content = config.data.content;
    }
    this.element = document.getElementsByClassName('modal-content');
    if (this.element.length) {
      this.renderer.addClass(this.element[0], 'mt-150'); // Use Renderer2
    }
  }

  ngOnDestroy() {
    this.$destroyed = true;
    if (this.element.length) {
      this.renderer.removeClass(this.element[0], 'mt-150'); // Use Renderer2
    }
  }

  onDelete() {
    this.content.deleteFunction(this.content.deletedSupplier);
  }
}
