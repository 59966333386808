"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountTypeReverse = exports.DiscountTypeMap = exports.TaxTypesReverse = exports.TaxTypesMap = exports.TrackTypeReverse = exports.TrackTypeMap = exports.InvoiceStatusEnumReverse = exports.InvoiceStatusEnumMap = void 0;
exports.InvoiceStatusEnumMap = {
    open: 1,
    completed: 2,
};
exports.InvoiceStatusEnumReverse = {
    1: 'open',
    2: 'completed',
};
exports.TrackTypeMap = {
    serial: 1,
    batch: 2,
    ecard: 3,
};
exports.TrackTypeReverse = {
    1: 'serial',
    2: 'batch',
    3: 'ecard',
};
exports.TaxTypesMap = {
    Inclusive: 1,
    Exclusive: 2,
};
exports.TaxTypesReverse = {
    1: 'Inclusive',
    2: 'Exclusive',
};
exports.DiscountTypeMap = {
    percentage: 1,
    amount: 2,
};
exports.DiscountTypeReverse = {
    1: 'percentage',
    2: 'amount',
};
