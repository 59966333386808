import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import {
  CustomerEnums,
  CustomerTypes,
  OfflinePosTypes,
} from '@rewaa-team/pos-sdk';
import { ConfigStoreKeys, CustomerChangeSetAPIConfig } from '../../constants';
import { Customer } from '../../model/order/Customer';
import { CompressorService } from '../compressor.service';
import { ConfigStoreService } from './indexedDB/config-store.service';
import { OfflineCustomerService } from './offline-customer.service';
import { CustomerChangeSetAPIResponse } from '../types/offline-customer.type';
import { ChangeSetActionType } from './enums';
import { CustomerStoreV2Service } from './indexedDB/customer-store-v2.service';
import { NotificationsService } from '../../../firebase-notifications/notifications.service';
import {
  NotificationSource,
  NotificationType,
} from '../../../firebase-notifications/enums';
import { FileDownLoadService } from './file-download.service';
import { PosApiService } from '../pos-api.service';
import { CustomerStoreV3Service } from './indexedDB/customer-store-v3.service';
import { OfflineFirstService } from './offline-first.service';
import { EventTrackingService } from '../../../core/services/event-tracking.service';
import { OfflineCustomerEventType } from '../../../core/constants/event-tracking.const';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../constants/feature-flag.constants';

@Injectable({
  providedIn: 'root',
})
export class CustomerCacheService implements OnDestroy {
  customerSubscription: Subscription;

  destroySubs$ = new Subject();

  downloadingOfflineCustomersFile = false;

  isDownloadingOfflineCustomersFileV2 = false;

  constructor(
    private offlineCustomerService: OfflineCustomerService,
    private configStoreService: ConfigStoreService,
    private compressorService: CompressorService,
    private customerStoreV2Service: CustomerStoreV2Service,
    private notificationService: NotificationsService,
    private fileDownLoadService: FileDownLoadService,
    private posApiService: PosApiService,
    private customerStoreV3Service: CustomerStoreV3Service,
    private offlineFirstService: OfflineFirstService,
    private eventTrackingService: EventTrackingService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.notificationService
      .getNotifications({
        type: NotificationType.CustomerChanges,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((notifications) => {
        if (notifications.length) {
          this.loadOfflineData();
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.OfflineCustomerFile,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .subscribe((notifications) => {
        if (notifications.length) {
          const data: OfflinePosTypes.OfflineCustomerFileData =
            notifications[0].m.d;
          this.downloadAndProcessOfflineCustomerFile(data);
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.CustomerChangesV2,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((notifications) => {
        if (notifications.length) {
          const notificationId = notifications[0].id;
          this.trackOfflineCustomerEvent(
            OfflineCustomerEventType.CustomerChangeNotification,
            notificationId,
          );
          this.loadOfflineDataV2(notificationId);
        }
      });

    this.notificationService
      .getNotifications({
        type: NotificationType.OfflineCustomerFileV2,
        silent: true,
        source: NotificationSource.OfflinePos,
        fromDate: new Date(),
        onlyNew: true,
      })
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((notifications) => {
        if (notifications.length) {
          const notificationId = notifications[0].id;
          const data: OfflinePosTypes.OfflineCustomerFileData =
            notifications[0].m.d;
          this.trackOfflineCustomerEvent(
            OfflineCustomerEventType.CustomerFileNotification,
            notificationId,
          );
          this.downloadAndProcessOfflineCustomerFileV2({
            ...data,
            notificationId,
          });
        }
      });

    this.featureFlagService
      .variation(FeatureFlagEnum.OfflineFirst)
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((response) => {
        if (response?.customersV2) {
          this.loadOfflineDataV2();
        }
      });
  }

  ngOnDestroy(): void {
    this.destoryCustomerSubscription();
    this.destroySubs$.next(null);
    this.destroySubs$.complete();
  }

  destoryCustomerSubscription(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  async searchCustomers(
    searchQuery: string,
  ): Promise<{ result: Customer[]; total: number }> {
    const customers = this.offlineFirstService.customerV2FeatureFlag
      ? await this.customerStoreV3Service.findCustomers(searchQuery)
      : await this.customerStoreV2Service.findCustomers(searchQuery);
    return {
      result: customers,
      total: customers.length,
    };
  }

  /**
   * @deprecated remove this after offlineCustomerV2 is live for 100% users
   */

  async loadOfflineData(): Promise<void> {
    const customerChangeSetAPIConfig =
      await this.configStoreService.getConfigration(
        ConfigStoreKeys.CustomerChangeSetAPIConfig,
      );

    if (!customerChangeSetAPIConfig) {
      this.loadOfflineCustomers();
      return;
    }
    const { updatedAt } = JSON.parse(customerChangeSetAPIConfig);
    this.loadOfflineCustomers(updatedAt);
  }

  async loadOfflineDataV2(notificationId?: string): Promise<void> {
    if (!this.offlineFirstService.customersV2Enabled) {
      return;
    }
    const customerChangeSetAPIConfigV2 =
      await this.configStoreService.getConfigration(
        ConfigStoreKeys.CustomerChangeSetAPIConfigV2,
      );

    if (!customerChangeSetAPIConfigV2) {
      this.loadOfflineCustomersV2(null, notificationId);
      return;
    }
    const { updatedAt } = JSON.parse(customerChangeSetAPIConfigV2);
    this.loadOfflineCustomersV2(updatedAt, notificationId);
  }

  /**
   * @deprecated remove this after offlineCustomerV2 is live for 100% users
   */
  private async loadOfflineCustomers(updatedAt?: string): Promise<void> {
    this.offlineCustomerService
      .getOfflineCustomersChangeSet(updatedAt)
      .pipe(takeUntil(this.destroySubs$))
      .subscribe(async (customerChangeSet: CustomerChangeSetAPIResponse[]) => {
        if (!customerChangeSet.length) return;

        const promises = [];

        const customersToAdd: Customer[] =
          this.getCustomersToAdd(customerChangeSet);

        promises.push(
          this.customerStoreV2Service.bulkPutCustomers(customersToAdd),
        );

        const customerIdsToDelete: number[] =
          this.getCustomerIdsToDelete(customerChangeSet);

        promises.push(
          this.customerStoreV2Service.deleteCustomers(customerIdsToDelete),
        );

        const latestUpdatedDate = this.getLatestUpdatedAt(customerChangeSet);
        const config: CustomerChangeSetAPIConfig = {
          updatedAt: latestUpdatedDate,
        };

        promises.push(
          this.configStoreService.setConfigration(
            ConfigStoreKeys.CustomerChangeSetAPIConfig,
            JSON.stringify(config),
          ),
        );
        await Promise.all(promises);
      });
  }

  private async loadOfflineCustomersV2(
    updatedAt?: string,
    notificationId?: string,
  ): Promise<void> {
    this.posApiService
      .getCustomerChangeSet(updatedAt)
      .pipe(takeUntil(this.destroySubs$))
      .subscribe(async (response) => {
        const customerChangeSet: CustomerTypes.CustomerChangeSetOutput[] =
          response.data;
        if (customerChangeSet.length) {
          this.trackOfflineCustomerEvent(
            OfflineCustomerEventType.ChangeSetApiResponse,
            notificationId,
          );
        }
        await this.processCustomerV2ChangeSet(
          customerChangeSet,
          notificationId,
        );
      });
  }

  async processCustomerV2ChangeSet(
    customerChangeSet: CustomerTypes.CustomerChangeSetOutput[] = [],
    notificationId?: string,
  ): Promise<void> {
    if (!customerChangeSet.length) {
      return;
    }
    const promises = [];

    const customersToAdd: Customer[] =
      this.getCustomersToAddV2(customerChangeSet);

    promises.push(this.customerStoreV3Service.bulkPutCustomers(customersToAdd));

    const customerIdsToDelete: number[] =
      this.getCustomerIdsToDeleteV2(customerChangeSet);

    promises.push(
      this.customerStoreV3Service.deleteCustomers(customerIdsToDelete),
    );

    const config: CustomerChangeSetAPIConfig = {
      updatedAt: customerChangeSet[0].updatedDate,
    };

    promises.push(
      this.configStoreService.setConfigration(
        ConfigStoreKeys.CustomerChangeSetAPIConfigV2,
        JSON.stringify(config),
      ),
    );
    await Promise.all(promises);
    const customerIdsToAdd = customersToAdd.map((customer) => customer.id);
    if (customerIdsToAdd.length) {
      this.trackOfflineCustomerEvent(
        OfflineCustomerEventType.CustomerCreated,
        notificationId,
        customerIdsToAdd.length,
      );
    }
    if (customerIdsToDelete.length) {
      this.trackOfflineCustomerEvent(
        OfflineCustomerEventType.CustomerDeleted,
        notificationId,
        customerIdsToDelete.length,
      );
    }
  }

  /**
   * @deprecated should be removed after offlineCustomerV2 is live for all customers.
   */
  private getCustomersToAdd(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): Customer[] {
    const customersToAdd = customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) =>
        changeSet.actionType === ChangeSetActionType.Updated,
    );
    if (!customersToAdd) return [];

    return customersToAdd.customers.map((customer) => {
      const nameArray = customer?.name ? customer.name.split(' ') : [];
      const searchableFields = [
        customer.mobileNumber,
        customer.code,
        customer.name,
        ...nameArray,
      ];
      return { ...customer, searchableFields };
    });
  }

  private getCustomersToAddV2(
    customerChangeSet: CustomerTypes.CustomerChangeSetOutput[],
  ): Customer[] {
    const customersToAdd = customerChangeSet.find(
      (changeSet) =>
        changeSet.actionType ===
        CustomerEnums.CustomerChangeTypeConstant.Updated,
    );
    if (!customersToAdd) return [];

    return customersToAdd.customers.map((customer) => {
      const customerName = customer.name?.trim();
      const nameArray = customerName ? customerName.split(' ') : [];
      const searchableFields = [
        customer.mobileNumber,
        customer.code,
        customerName,
        ...nameArray,
      ];
      return {
        ...customer,
        searchableFields,
        debitAmount: Number(customer.debitAmount),
        totalPaid: Number(customer.totalPaid),
      };
    });
  }

  /**
   * @deprecated should be removed after offlineCustomerV2 is live for all customers.
   */
  private getCustomerIdsToDelete(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): number[] {
    const customers: Customer[] = customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) =>
        changeSet.actionType === ChangeSetActionType.Deleted,
    )?.customers;

    return customers ? customers.map((customer) => customer.id) : [];
  }

  private getCustomerIdsToDeleteV2(
    customerChangeSet: CustomerTypes.CustomerChangeSetOutput[],
  ): number[] {
    const customers = customerChangeSet.find(
      (changeSet) =>
        changeSet.actionType ===
        CustomerEnums.CustomerChangeTypeConstant.Deleted,
    )?.customers;

    return customers ? customers.map((customer) => customer.id) : [];
  }

  /**
   * @deprecated should be removed after offlineCustomerV2 is live for all customers.
   */
  private getLatestUpdatedAt(
    customerChangeSet: CustomerChangeSetAPIResponse[],
  ): string {
    return customerChangeSet.find(
      (changeSet: CustomerChangeSetAPIResponse) => changeSet.updatedDate,
    )?.updatedDate;
  }

  /**
   * @deprecated should be removed after offlineCustomerV2 is live for all customers.
   */
  async downloadAndProcessOfflineCustomerFile(
    input: OfflinePosTypes.OfflineCustomerFileData,
  ): Promise<void> {
    if (this.downloadingOfflineCustomersFile) {
      return;
    }
    this.downloadingOfflineCustomersFile = true;
    this.fileDownLoadService
      .downloadFile(input.url)
      .pipe(
        takeUntil(this.destroySubs$),
        tap(async (res) => {
          const customerChangeSet =
            (await this.compressorService.unCompressGzipToJSON(
              new Uint8Array(res),
            )) as unknown as CustomerChangeSetAPIResponse[];
          const customersToAdd: Customer[] =
            this.getCustomersToAdd(customerChangeSet);
          const config: CustomerChangeSetAPIConfig = {
            updatedAt: input.updatedAt,
          };
          const promises = [
            this.customerStoreV2Service.bulkPutCustomers(customersToAdd),
            this.configStoreService.setConfigration(
              ConfigStoreKeys.CustomerChangeSetAPIConfig,
              JSON.stringify(config),
            ),
          ];
          await Promise.all(promises);
          this.downloadingOfflineCustomersFile = false;
          this.loadOfflineData();
        }),
        finalize(() => {
          this.downloadingOfflineCustomersFile = false;
        }),
      )
      .subscribe();
  }

  async downloadAndProcessOfflineCustomerFileV2(
    input: OfflinePosTypes.OfflineCustomerFileData,
  ): Promise<void> {
    if (this.isDownloadingOfflineCustomersFileV2) {
      return;
    }
    this.isDownloadingOfflineCustomersFileV2 = true;
    this.fileDownLoadService
      .downloadFile(input.url)
      .pipe(
        takeUntil(this.destroySubs$),
        finalize(() => {
          this.isDownloadingOfflineCustomersFileV2 = false;
        }),
      )
      .subscribe(async (res) => {
        this.trackOfflineCustomerEvent(
          OfflineCustomerEventType.CustomerFileDownloaded,
          input.notificationId,
        );
        const customerChangeSet =
          (await this.compressorService.unCompressGzipToJSON(
            new Uint8Array(res),
          )) as unknown as CustomerTypes.CustomerChangeSetOutput[];
        await this.processCustomerV2ChangeSet(
          customerChangeSet,
          input.notificationId,
        );
        this.isDownloadingOfflineCustomersFileV2 = false;
        this.loadOfflineDataV2();
      });
  }

  trackOfflineCustomerEvent(
    eventType: OfflineCustomerEventType,
    eventId: string,
    customerChangeCount?: number,
  ): void {
    if (!this.offlineFirstService.customersV2Enabled) {
      return;
    }
    this.eventTrackingService.offlineCustomerSynced({
      customerChangeCount: customerChangeCount?.toString(),
      eventType,
      eventId,
    });
  }
}
