import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TaxType } from '@rewaa-team/rewaa-common';
import { AccountHeadState } from '@rewaa-team/rewaa-accounting';
import { Expense } from '../model/expense';
import {
  ExpenseCategories,
  ExpenseStopResponse,
  Identifier,
  LocationIdentifier,
  PaymentMethodIdentifier,
  RegisterIdentifier,
  TaxMethodIdentifier,
} from '../model/expense.types';
import { ExpenseExportViaImex } from './types';
import { environment } from '../../../environments/environment';

const API_URL = '/api';
const EXPENSE_SERVICE_API_URL = environment.expenseApiPath;

@Injectable()
export class ExpenseService {
  constructor(private http: HttpClient) {}

  getExpense(expenseId: number | string): Observable<Expense> {
    return this.http
      .get<Expense>(`${EXPENSE_SERVICE_API_URL}/expenses/${expenseId}`)
      .pipe(map((response) => new Expense(this.mapGetExpense(response))));
  }

  mapGetExpense(response) {
    const locationIdSet = new Set<number>();
    response.payments.forEach((p) => {
      if (!locationIdSet.has(p.locationId)) {
        locationIdSet.add(p.locationId);
      }
    });
    const locationIds = Array.from(locationIdSet);
    const locations = locationIds.map((locationId) => ({
      stockLocationId: locationId,
    }));
    response.paymentDate = response.paymentDateTime;
    response.taxable = response.isTaxable;
    response.isTaxInclusive = response.taxType === TaxType.TaxInclusive;
    response.ExpenseCategory = response.category;
    response.expenseCategoryId = response.category?.ulid;
    response.ExpensePayments = response.payments;
    response.ExpenseStockLocations = locations;
    return response;
  }

  mapSorting(key: string): string {
    if (key === 'id') {
      return 'expenseNumber';
    }
    if (key === 'expenseCategory') {
      return 'category';
    }
    return key;
  }

  getAllExpenses(query): Observable<{ resultSet: Expense[]; total: number }> {
    const sort = JSON.parse(query.sortBy);
    if (sort) {
      const entries = Object.entries(sort);
      const [sortOn, sortBy] = entries[0];
      query.sortOn = this.mapSorting(sortOn);
      query.sortBy = (sortBy as string).toUpperCase();
    } else {
      query.sortOn = 'createdAt';
      query.sortBy = 'DESC';
    }
    let expenseQueryParams = new HttpParams()
      .set('offset', query.offset ? query.offset : 0)
      .set('limit', query.limit ?? '')
      .set('search', query.search ?? '')
      .set('searchOP', query.searchOP ?? '')
      .set('sortOn', query.sortOn ?? '')
      .set('sortBy', query.sortBy ?? '')
      .set('amount', query.amount !== undefined ? query.amount : '')
      .set('taxAmount', query.taxAmount !== undefined ? query.taxAmount : '')
      .set('createdAtFromDate', query.createdAtFromDate ?? '')
      .set('createdAtToDate', query.createdAtToDate ?? '')
      .set('updatedAtFromDate', query.updatedAtFromDate ?? '')
      .set('updatedAtToDate', query.updatedAtToDate ?? '');
    if (query.expenseCategory) {
      query.expenseCategory.split(',').forEach((categoryUlid) => {
        expenseQueryParams = expenseQueryParams.append(
          'categoryUlids[]',
          categoryUlid,
        );
      });
    }
    if (query.location) {
      query.location.split(',').forEach((locationId) => {
        expenseQueryParams = expenseQueryParams.append(
          'locationIds[]',
          locationId,
        );
      });
    }
    if (query.paymentMethod !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentMethodIds[]',
        query.paymentMethod,
      );
    }
    if (query.taxAmountOP !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'taxAmountOp',
        query.taxAmountOP ?? '',
      );
    }
    if (query.amountOP !== undefined) {
      expenseQueryParams = expenseQueryParams.set('amountOp', query.amountOP);
    }
    if (query.id !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'expenseNumber',
        query.id ?? '',
      );
    }
    if (query.taxable !== undefined) {
      expenseQueryParams = expenseQueryParams.set('taxable', query.taxable);
    }
    if (query.fromDate !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentDateFrom',
        query.fromDate ?? '',
      );
    }
    if (query.toDate !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentDateTo',
        query.toDate ?? '',
      );
    }
    return this.http.get<{ resultSet: Expense[]; total: number }>(
      `${EXPENSE_SERVICE_API_URL}/expenses`,
      {
        params: expenseQueryParams,
      },
    );
  }

  getExpenseCategory(ulid: string): Observable<ExpenseCategories> {
    return this.http
      .get<ExpenseCategories>(
        `${EXPENSE_SERVICE_API_URL}/expense-categories/${ulid}`,
      )
      .pipe(
        map(
          (response) =>
            <ExpenseCategories>{
              ulid: response.ulid,
              name: response.name,
              slug: response.slug,
              state: response.state,
              isLeaf: response.isLeaf,
            },
        ),
      );
  }

  getExpenseCategories(
    options: {
      state?: AccountHeadState;
      isLeaf?: boolean;
    } = {},
  ): Observable<ExpenseCategories[]> {
    let httpParams = new HttpParams();
    if (options.state) {
      httpParams = httpParams.set('state', options.state);
    }
    if (options.isLeaf !== undefined) {
      httpParams = httpParams.set('isLeaf', options.isLeaf.toString());
    }
    return this.http
      .get<ExpenseCategories[]>(
        `${EXPENSE_SERVICE_API_URL}/expense-categories`,
        {
          params: httpParams,
        },
      )
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <ExpenseCategories>{
                ulid: e.ulid,
                name: e.name,
                slug: e.slug,
                state: e.state,
                isLeaf: e.isLeaf,
              },
          ),
        ),
      );
  }

  exportViaImex(params: ExpenseExportViaImex): Observable<unknown> {
    return this.http.post(`${EXPENSE_SERVICE_API_URL}/expenses-export`, params);
  }

  getStockLocations(): Observable<LocationIdentifier[]> {
    return this.http
      .get<LocationIdentifier[]>(`${API_URL}/stock-location`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <LocationIdentifier>{
                id: e.id,
                name: e.name,
                isDefault: e.isDefault,
              },
          ),
        ),
      );
  }

  getStockLocationRegister(
    locationId: number,
  ): Observable<RegisterIdentifier[]> {
    return this.http
      .get<
        RegisterIdentifier[]
      >(`${API_URL}/stock-location/${locationId}/pos/registers`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <RegisterIdentifier>{
                id: e.id,
                name: e.name,
                status: e.status,
                PaymentMethodToRegisters: e.PaymentMethodToRegisters,
              },
          ),
        ),
      );
  }

  getTaxes(): Observable<TaxMethodIdentifier[]> {
    return this.http.get<TaxMethodIdentifier[]>(`${API_URL}/taxes`).pipe(
      map((response) =>
        response.map(
          (e) =>
            <TaxMethodIdentifier>{
              id: e.id,
              name: e.name,
              rate: e.rate,
            },
        ),
      ),
    );
  }

  getTaxLists(): Observable<any[]> {
    return this.http.get<Identifier[]>(`${API_URL}/taxes`);
  }

  getTaxById(taxId: number): Observable<any> {
    return this.http.get(`${API_URL}/taxes/${taxId}`);
  }

  getPaymentMethods(): Observable<PaymentMethodIdentifier[]> {
    return this.http
      .get<PaymentMethodIdentifier[]>(`${API_URL}/payment-methods/list`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <PaymentMethodIdentifier>{
                id: e.id,
                name: e.name,
                type: e.type,
              },
          ),
        ),
      );
  }

  mapExpense(payload) {
    const paymentDateTime = DateTime.fromFormat(
      payload.paymentDate,
      'yyyy-MM-dd',
    )
      .toUTC()
      .toISO();
    payload.paymentDateTime = paymentDateTime;
    payload.isTaxable = payload.taxable;
    payload.categoryUlid = payload.expenseCategoryId;
    payload.locationIds = payload.ExpenseStockLocations;
    payload.payments = payload.ExpensePayments;
    payload.costCenters = payload.costCenterIds;
    return payload;
  }

  createExpense(payload: Expense): Observable<any> {
    payload = this.mapExpense(payload);
    return this.http.post(`${EXPENSE_SERVICE_API_URL}/expenses`, payload);
  }

  updateExpense(payload: Expense): Observable<any> {
    payload = this.mapExpense(payload);
    return this.http.put(
      `${EXPENSE_SERVICE_API_URL}/expenses/${payload.ulid}`,
      payload,
    );
  }

  deleteExpense(id: number | string): Observable<any> {
    return this.http.delete(`${EXPENSE_SERVICE_API_URL}/expenses/${id}`);
  }

  stopExpense(id: number): Promise<ExpenseStopResponse> {
    // todo: change this path to expense service whenever it is available
    return firstValueFrom(
      this.http.put<ExpenseStopResponse>(`${API_URL}/expenses/stop/${id}`, {}),
    );
  }
}
