import { ProductVariantStockLocation } from '../../../shared/model/ProductVariantStockLocation';
import { ProductVariant } from '../../model/product-variant';
import { ProductVariantV2 } from './variant.mapper.types';

export function mapVariantV2toV1(
  variantsV2: ProductVariantV2[],
): ProductVariant[] {
  return variantsV2.map((variantV2) => {
    const variantV1 = new ProductVariant();

    // Direct mappings
    variantV1.id = variantV2.id;
    variantV1.name = variantV2.name;
    variantV1.sku = variantV2.sku;
    variantV1.barCode = variantV2.barcode || ''; // Default to empty string if null
    variantV1.isSellable = variantV2.isSellable;
    variantV1.isPurchasable = variantV2.isPurchasable;
    variantV1.isPackgable = variantV2.isPackable;
    variantV1.isTaxable = variantV2.isTaxable;
    variantV1.type = variantV2.type;
    variantV1.trackType = variantV2.product.trackType || null; // Map from product.trackType
    variantV1.productId = variantV2.product.id;
    variantV1.productName = variantV2.product.name;
    variantV1.description = variantV2.product.description || ''; // Default to empty string if null
    variantV1.categoryId = variantV2.product.categoryId || null;
    variantV1.supplierId = variantV2.product.supplierId || null;
    variantV1.brandId = variantV2.product.brandId || null;

    // Map locations
    variantV1.ProductVariantToStockLocations = variantV2.locations.map(
      (location) => {
        const stockLocation = new ProductVariantStockLocation();
        stockLocation.id = location.id;
        stockLocation.stockLocationId = location.locationId;
        stockLocation.variantID = location.productVariantId;
        stockLocation.quantity = location.quantity || 0;
        stockLocation.initialQuantity = location.initialQuantity || 0;
        stockLocation.locationName = location.locationName || '';
        stockLocation.buyPrice = location.buyPrice || 0;
        stockLocation.initialCost = location.initialCost || 0;
        stockLocation.cost = location.cost || 0;
        stockLocation.retailPrice = location.retailPrice || 0;
        stockLocation.wholeSalePrice = location.wholesalePrice || 0;
        stockLocation.taxId = location.taxId || null;
        return stockLocation;
      },
    );

    variantV1.Product = {
      id: variantV2.product.id,
      type: variantV2.product.productType,
      name: variantV2.product.name,
    };

    // Default values for optional fields
    variantV1.isSelected = false;
    variantV1.selected = false;
    variantV1.manageStockLevel = variantV2.product.isDsm; // Default value
    variantV1.VariantToPackages = [];
    variantV1.VariantToInvoices = [];
    variantV1.Files = [];
    variantV1.Ecards = [];
    variantV1.AppProductStatuses = [];
    variantV1.Extras = [];
    variantV1.VariantExtraLocations = [];
    variantV1.CustomFieldsData = [];

    return variantV1;
  });
}
