import { JournalEntryState } from '@rewaa-team/rewaa-accounting';

export enum UpdateAccount {
  Add = 'Add',
  Edit = 'Edit',
}

export const defaultHeadsDescription = 'default';

export const advancedAccountingTranslationPrefix = 'advancedAccounting.';

export enum JournalEntryStatusUIType {
  Info = 'info',
  Gray = 'gray',
}

export const journalEntryStateToUITypeMap: Record<
  JournalEntryState,
  JournalEntryStatusUIType
> = {
  [JournalEntryState.Posted]: JournalEntryStatusUIType.Info,
  [JournalEntryState.Draft]: JournalEntryStatusUIType.Gray,
};

export const accountingTrialWindow = 30; // days

export enum LabelTypes {
  CostCenters = 'costCenters',
  LocationLabel = 'locationLabel',
}

export const MAX_RECORDS = 1000;

export interface OpeningBalanceImport {
  code: string;
  balance: number;
  description: string;
}
