"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapEnumsRecursive = void 0;
function transformEnumValue(key, value, enumMaps) {
    if (typeof value === 'string' || typeof value === 'number') {
        const enumMap = enumMaps[key];
        if (enumMap && enumMap[value] !== undefined) {
            return enumMap[value];
        }
    }
    return value;
}
function mapEnumsRecursive(obj, enumMaps) {
    if (Array.isArray(obj)) {
        return obj.map((item) => mapEnumsRecursive(item, enumMaps));
    }
    if (obj instanceof Date) {
        return obj;
    }
    if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
            const transformedValue = transformEnumValue(key, value, enumMaps);
            return [
                key,
                typeof transformedValue === 'object' && transformedValue !== null
                    ? mapEnumsRecursive(transformedValue, enumMaps)
                    : transformedValue,
            ];
        }));
    }
    return obj;
}
exports.mapEnumsRecursive = mapEnumsRecursive;
