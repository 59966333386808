import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutePermissionsService } from '../../shared/services/route-permissions.service';

@Injectable()
export class ActiveGuardService implements CanActivate {
  constructor(
    private router: Router,
    private routePermissionsService: RoutePermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return from(this.routePermissionsService.handleUrlPermissions(state.url)).pipe(
      map((url) => {
        if (typeof url === 'boolean') {
          if (!url) {
            this.appendReturnUrlInQueryParams(state);
          }
          return !!url;
        }
        return this.router.createUrlTree([url]);
      }),
    );
  }

  appendReturnUrlInQueryParams(state: RouterStateSnapshot): void {
    if (state.url !== '/') {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    } else {
      this.router.navigate(['login']);
    }
  }
}
