
<div [ngClass]="lng ==='en' ? 'ltr':'rtl'">
    <div [ngSwitch]="content?.templateName">
        <rw-uninstall-app *ngSwitchCase="'uninstall-app'" [content]="content">
        </rw-uninstall-app>
        <rw-app-details-popup *ngSwitchCase="'app-details'" [content]="content">
        </rw-app-details-popup>
        <rw-subscription-invoice *ngSwitchCase="'subscription-invoice'" [content]="content">
        </rw-subscription-invoice>
        <rw-print-barcode *ngSwitchCase="'print-barcode'" [content]="content">
        </rw-print-barcode>
        <rw-print-bulk-barcode *ngSwitchCase="'print-bulk-barcode'" [content]="content">
        </rw-print-bulk-barcode>
        <rw-print-variant-barcode *ngSwitchCase="'print-variant-barcode'" [content]="content">
        </rw-print-variant-barcode>
        <rw-print-barcode-v2 *ngSwitchCase="'print-barcode-v2'" [content]="content">
        </rw-print-barcode-v2>
        <rw-add-withdraw-money *ngSwitchCase="'add-withdraw-money'" [content]="content">
        </rw-add-withdraw-money>
        <rw-receive-debit *ngSwitchCase="'receive-debit'" [content]="content">
        </rw-receive-debit>
        <rw-orders-receive-debit *ngSwitchCase="'receive-debit'" [content]="content">
        </rw-orders-receive-debit>
        <rw-reject-transfer-stock *ngSwitchCase="'reject-transfer-stock'" [content]="content">
        </rw-reject-transfer-stock>
        <rw-offline-hints *ngSwitchCase="'offline-hints'" [content]="content">
        </rw-offline-hints>
        <rw-error-details-popup *ngSwitchCase="'error-details-popup'" [content]="content">
        </rw-error-details-popup>
        <rw-add-salesman *ngSwitchCase="'add-salesman'" [content]="content">
        </rw-add-salesman>
        <rw-view-quote *ngSwitchCase="'view-quote'" [content]="content">
        </rw-view-quote>
        <rw-delete-bulk-products *ngSwitchCase="'delete-bulk-products'" [dialog]="false" [content]="content">
        </rw-delete-bulk-products>
        <rw-delete-supplier *ngSwitchCase="'delete-supplier'" [dialog]="false" [content]="content">
        </rw-delete-supplier>
        <rw-transfer-stock-preview *ngSwitchCase="'transfer-stock-preview'" [content]="content">
        </rw-transfer-stock-preview>
        <rw-transfer-stock-print *ngSwitchCase="'transfer-stock-print'" [content]="content">
        </rw-transfer-stock-print>
        <rw-cancel-progress *ngSwitchCase="'cancel-progress'" [content]="content">
        </rw-cancel-progress>
        <div *ngSwitchDefault class="alert-box" [ngClass]="content.class">
            <div class="modal-header pb-px" *ngIf="content.title">
                <div class="w-full">
                <span   class="modal-title w-95 pb-5">{{content.title | translate}}</span>
                </div>
            </div>
            <div class="modal-body">
                <div class="export-data" *ngIf="content.body">{{content.body | translate}}</div>
                <div class="text-center pt-4" *ngIf="content.modalParagraph && content.modalParagraphTitle">
                    <b>{{content.modalParagraphTitle | translate}}</b>
                    <div class="mt-2" [ngClass]="{'variant-names': showScroll}">
                        <span>
                            {{content.modalParagraph | translate }}
                        </span>
                    </div>
                </div>
                <div class="text-center pt-4" *ngIf="content.errorParagraph">
                    {{content.errorParagraphTitle | translate}}
                    <div class="mt-2 " [ngClass]="{'variant-names': showScroll}">
                        <span>
                            {{content.errorParagraph | translate }}
                        </span>
                    </div>
                </div>
                <div class="note-paragraph mt-3" *ngIf="content.noteParagraph">
                   <span class="note-title">{{ 'Note:' | translate }}</span> {{content.noteParagraph | translate }}
                </div>
            </div>
            <div class="modal-footer flex pb-0" [ngClass]="lng ==='en' ? 'justify-content-right':'justify-content-left'">
                <button pButton class="btn-padding p-button-text p-button-secondary" *ngIf="content.cancel_btn" type="button" (click)=content.cancel_btn.handler() [attr.pendo-tracker]="pendoTag" label="{{content.cancel_btn.text | translate}}"></button>
                <button pButton *ngIf="content.success_btn" [disabled]="(!content.modalParagraph && !content.body) || loading"
                    class="btn-padding" rwDebounceClick (debounceClick)=content.success_btn.handler() (beforeDebounce)="loading = true;"
                    type="button" [id]="content.btnId ? content.btnId : content.success_btn.text" [attr.pendo-tracker]="pendoTag" label="{{content.success_btn.text | translate}}">
                </button>
            </div>
        </div>
    </div>
</div>
