import { CustomFieldData } from '../invoice/types';
import {
  VariantTypes,
  ProductTypes,
  ProductChangeType,
  PromotionChangeType,
  PromotionDiscountType,
  PromotionType,
} from './enums';

export interface ProductVariant {
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  id: number;
  sku: string;
  isPurchasable: boolean;
  shippingWeight?: number;
  shippingLength?: number;
  shippingWidth?: number;
  shippingHeight?: number;
  manageStockLevel: boolean;
  isSellable: boolean;
  isTaxable: boolean;
  isWeightedScale: boolean;
  name: string;
  barCode?: string;
  supplierCode?: string;
  description?: string;
  variantAttributes: string;
  isPackgable: boolean;
  mediaUrl: string;
  type: VariantTypes;
  trackType?: 'serial' | 'batch';
  productId: number;
  product: Product;
  isSelected: boolean;
  productVariantToStockLocations: ProductVariantToStockLocation[];
  variantExtraLocations: VariantExtraLocation[];
  variantToPackages: VariantToPackage[];
  ecards: Ecard[];

  /**
   * Need to dismantle this package
   */
  promotion?: any;
  customFieldsData?: CustomFieldData[];
}

export interface ProductVariantToStockLocation {
  id: number;
  stockLocationId: number;
  productVariantId: number;
  quantity: number;
  initialQuantity: number;
  buyPrice: number;
  wholeSalePrice: number;
  retailPrice: number;
  cost: number;
  initialCost: number;
  taxId: number;
  tax: Tax;
  variantToTracks: VariantToTrack[];
}

export interface Tax {
  id: number;
  name: string;
  code: string;
  coumpoundTaxLineId?: number;
  taxLines?: TaxLine[];
  compoundTax?: TaxLine;
  rate?: number;
}

export interface TaxLine {
  id: number;
  name: string;
  rate: number;
  taxId: number;
  taxLineConfig?: TaxLineConfig;
}

export class TaxLineConfig {
  minAmount?: number;
}

export interface VariantToTrack {
  id: number;
  quantity: number;
  trackNo: string;
  issueDate: Date;
  expiryDate: Date;
  productVariantToStockLocationId: number;
  supplierId: number;
}

export interface Product {
  id: number;
  description?: string;
  name: string;
  batchTracked?: boolean;
  type: ProductTypes;
  brandId?: number;
  categoryId?: number;
  supplierId?: number;
  productStatusId?: number;
  variantToComposites: VariantToComposite[];
  productVariants: ProductVariant[];
  files: File[];
  variantOptions: VariantOption[];
}

export interface VariantOption {
  values: string[];
  id: number;
  option: string;
}

export interface File {
  link?: string;
  fileId?: number;
  id?: number;
  isDefault?: boolean;
  productId?: number;
}

export interface VariantToComposite {
  id: number;
  productVariantId: number;
  productId: number;
  productVariant?: ProductVariant;
  rate: number;
}

export interface VariantExtraLocation {
  id: number;
  price: number;
  extraId: number;
  stockLocationId: number;
  productVariantId: number;
  extra: Extra;
}

export interface Extra {
  id: number;
  name: string;
  hasSameLocationPrice?: boolean;
  hasOtherProduct?: boolean;
  rate: number;
  fileId?: number;
  productVariantId?: number;
  productVariant?: ProductVariant;
}

export interface VariantToPackage {
  id: number;
  rate: number;
  packageVariantId: number;
  productVariantId: number;
  productVariant?: ProductVariant;
}

export interface Ecard {
  id: number;
  productVariantId: number;
  code: string;
  isSold: boolean;
}

export interface ProductChangeSetItem {
  variant: ProductVariant;
  actionType: ProductChangeType;
}

export interface InventoryApiVariant {
  quantity: number;
  tracks: {
    trackNo: string;
    expiryDate: Date;
    id: number;
    quantity: number;
  }[];
  ecards: {
    id: number;
    code: string;
  }[];
}
export interface InventoryApiResult {
  variants: {
    [variantId: number]: InventoryApiVariant;
  };
  nextToken?: number;
  updatedAt?: string;
}

export interface ProductUpdateChangeSet {
  changeSet: ProductUpdateChangeSetItem[];
  updatedAt: Date;
  lastChunk?: boolean;
}
export interface ProductUpdateChangeSetItem {
  /**
   * Useful when variant is deleted, since we hard delete
   */
  productVariantId?: number;
  sku?: string;
  actionType: ProductChangeType;
  updatedAt?: Date;
}

export interface ClearOfflineDataInput {
  variants?: boolean;
  customers?: boolean;
  invoices?: boolean;
}

export interface PromotionChangeSet {
  promotionId: number;
  actionType: PromotionChangeType;
  updatedAt?: Date;
}

export interface Promotion {
  id: number;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  type: PromotionType;
  discountType: PromotionDiscountType;
  amount: number;
  isAllProducts: number;
  isAllLocations: number;
  promotionVariants: number[];
}

export interface PromotionChangeSetItem {
  promotion?: Promotion;
  actionType: PromotionChangeType;
}

export interface VariantFileData {
  updatedAt: string;
  url: string;
  stockLocationId: number;
}

export interface OfflineCustomerFileData {
  updatedAt: string;
  url: string;
  notificationId?: string;
}
