/* eslint-disable @typescript-eslint/naming-convention */
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';
import { CommonEnums } from '@rewaa-team/pos-sdk';

export enum APP_NAMES {
  WOO_COMMERCE = 'woocommerce',
  POINT_OF_SALE = 'point of sale',
  INTERNAL_POS = 'new POS',
  MAGENTO = 'magento',
  ZID = 'zid',
  SALLA = 'salla',
  QOYOD = 'qoyod',
  PROMOTION_MANAGMENET = 'promotion management',
  EXPENSES = 'expenses',
  ACCOUNTING = 'accounting',
  ZATCA = 'zatca',
  BONAT = 'bonat',
}

export const AppToSource = {
  [APP_NAMES.WOO_COMMERCE]: CommonEnums.SourceConstant.WooCommerce,
  [APP_NAMES.INTERNAL_POS]: CommonEnums.SourceConstant.POS,
  [APP_NAMES.ZID]: CommonEnums.SourceConstant.Zid,
  [APP_NAMES.SALLA]: CommonEnums.SourceConstant.Salla,

  /**
   * This is the actual value provided from backend
   * For some reason frontend has salla and it's used by
   * casting to lower case everywhere :)
   */
  ['Salla']: CommonEnums.SourceConstant.Salla,
};

export enum ErrorMessages {
  PageNotFound = 'The requested page could not be found',
  DisabledAccount = 'Your account has been disabled, please contact the customer support team for futher details.',
  Unauthenticated = 'An Authentication error occurred, please make sure you have access to the requested URL',
  InternalServerError = 'An error occurred in the server',
  OtherError = 'An error occurred, please check with system admin !',
  LocationDeactivateStockAvailable = 'Cannot deactivate location with stock on hand',
  LocationDeactivateChannelMapped = 'Cannot deactivate channel location',
  LocationDeactivateAll = 'Cannot deactivate all location',
  MissingTenantId = 'You have been logged out because of missing configuration. Please login again',
}

export enum INTEGRATION_STATUSES {
  LINKED = 'Linked',
  UNLINKED = 'UnLinked',
  NOT_FOUND = 'Not_Found',
  LINKING = 'Linking',
  UNLINKING = 'Unlinking',
}

export enum INVOICE_STATUS {
  ALL = 'ALL',
  VOID = 'Void',
  ACTIVE = 'Active',
  OPEN = 'Open',
  COMPLETED = 'Completed',
}

export enum CHARGEBEE_SUBSCTIPION_STATUS {
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
  IN_TRIAL = 'in_trial',
  NON_RENEWING = 'non_renewing',
  PAUSED = 'paused',
  FUTURE = 'future',
}

export enum SubscriptionStatus {
  Active = 'active',
  InTrial = 'in_trial',
  Cancelled = 'cancelled',
  Future = 'future',
  NonRenewing = 'non_renewing',
  Paused = 'paused',
  Transferred = 'transferred',
  NoSubscription = 'no_subscription',
  TrialCancelled = 'trial_cancelled',
}

export enum SUBSCTIPION_STATUS {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  DEACTIVATED = 'Deactivated',
}

export enum ORDER_STATUS {
  PENDING = 'Pending',
  PENDING_WITH_ERROR = 'Pending with Errors',
  ACCEPTED = 'Accepted',
  IN_PROCESSING = 'InProcessing',
  READY_FOR_PICKUP = 'ReadyForPickup',
  CANCELED = 'Canceled',
  CANCELED_WITH_ERRORS = 'Canceled with Errors',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
  RETURNED = 'Returned',
  PARTIALLY_RETURNED = 'PartiallyReturned',
}

export const OrderStatusTranslationKeyByOrderStatus = {
  Pending: 'pending',
  Accepted: 'accepted',
  InProcessing: 'inProcessing',
  ReadyForPickup: 'readyForPickup',
  Shipped: 'shipped',
  Delivered: 'delivered',
  Returned: 'returned',
  PartiallyReturned: 'partiallyReturned',
  'Canceled with Errors': 'canceledWithErrors',
  'Pending with Errors': 'pendingWithErrors',
  Canceled: 'canceled',
};

export enum NEXT_ORDER_STATUS {
  PROCESSING = 'Accept Order',
  READY_TO_PICKUP = 'Ready For Pickup',
  CANCEL = 'Cancel',
  SHIP = 'Ship',
  DELIVER = 'Delivered',
  RETURN = 'Return',
}

export const ONGOING_ORDER_STATUSES = [
  ORDER_STATUS.PENDING,
  ORDER_STATUS.ACCEPTED,
  ORDER_STATUS.IN_PROCESSING,
  ORDER_STATUS.SHIPPED,
];

export enum ORDER_PAYMENT_METHODS {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum SELL_TYPE {
  ALL = 'ALL',
  SELL = 'Sell',
  RETURN = 'Return',
  SALE_POS = 'Sell POS',
  RETURN_POS = 'Return POS',
}

export enum INVOICE_PAYMENT_METHODS {
  CASH = 'Cash',
  CREDIT_CARD = 'Credit Card',
  DEBIT_CARD = 'DebitCard',
  DEBIT = 'Debit',
  CREDIT = 'Credit',
}

export const DEBIT_PAYMENT_METHOD_ID = 1000000;

export enum PAYMENT_OPTIONS {
  NOW = 'PayNow',
  LATER = 'PayLater',
}

export enum ACCOUNT_TYPES {
  SAME = 'Same',
  ANOTHER = 'Another',
}

export enum TAX_TYPES {
  INCLUSIVE = 'Inclusive',
  EXCLUSIVE = 'Exclusive',
}

export enum USER_STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum USER_ROLES {
  SUPER_ADMIN = 'SuperAdmin',
  ADMIN = 'Admin',
  WAREHOUSE_MANAGER = 'WarehouseManager',
  ORDER_MANAGER = 'OrderManager',
  CUSTOM = 'Custom',
  STORE_MANAGER = 'StoreManager',
  CASHIER = 'Cashier',
}

export enum USER_ROLES_LEVEL {
  SYSTEM = 'System',
  APPLICATION = 'Application',
}

export enum PRODUCT_PERMISSIONS {
  PRODUCT_LIST = 'product.list',
  PRODUCT_DETAIL = 'product.detail',
}

export enum LOCATIONS_STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Deactive',
}

export enum APP_AUTHENTICATION_TYPES {
  OAUTH1 = 'oauth1',
  OAUTH2 = 'oauth2',
  POS = 'pos',
  TOKEN_BASED = 'token-based',
  ZID = 'zid',
  BEARER_TOKEN = 'bearer-token',
  QOYOD = 'qoyod',
}

export enum INVOICE_TYPES {
  PAY_CREDIT = 'PayCredit',
  RECEIVE_DEBIT = 'ReceiveDebit',
  CUSTOMER_RECEIVE_DEBIT = 'CustomerReceiveDebit',
  PURCHASE_ORDER = 'PurchaseOrder',
  RETURN_STOCK = 'ReturnStock',
  STOCK_COUNT = 'StockCount',
  REMOVE_STOCK = 'RemoveStock',
  TRANSFER_STOCK = 'TransferStock',
  PAY_RETURN_STOCK = 'PayReturnStock',
  PAY_PURCHASE_ORDER = 'PayPurchaseOrder',
  POS_SALE = 'POSSale',
  POS_RETURN = 'POSReturn',
  POS_PURCHASE_ORDER = 'POSPurchaseOrder',
  POS_RETURN_STOCK = 'POSReturnStock',
  ORDER_SALE = 'SaleOrder',
  ORDER_RETURN = 'ReturnOrder',
}
export enum INVOICE_TYPES_TEXT {
  PAY_CREDIT = 'Pay Credit',
  RECEIVE_DEBIT = 'Receive Debit',
  CUSTOMER_RECEIVE_DEBIT = 'Customer Receive Debit',
  PURCHASE_ORDER = 'Purchase Order',
  RETURN_STOCK = 'Return Stock',
  STOCK_COUNT = 'Stock Count',
  REMOVE_STOCK = 'Remove Stock',
  TRANSFER_STOCK = 'Transfer Stock',
}

export enum PAYMENT_STATUSES {
  PAID = 'Paid',
  DRAFT = 'Draft',
  NOT_PAID_DEBTOR = 'NotPaidDebtor',
  NOT_PAID_CREDITOR = 'NotPaidCreditor',
  PARTIALLY_PAID_DEBTOR = 'PartiallyPaidDebtor',
  PARTIALLY_PAID_CREDITOR = 'PartiallyPaidCreditor',
}

export enum FILTER_PAYMENT_STATUSES {
  All = 'All',
  Paid = 'Paid',
  NOT_PAID = 'Not Paid',
  PARTIALLY_PAID = 'Partially Paid',
}

export enum FILTER_PAYMENT_STATUSES_V2 {
  Paid = 'Fully Paid',
  NOT_PAID = 'Not Paid',
  Draft = 'Draft',
  PARTIALLY_PAID = 'Partially Paid',
}
export enum TRANSFER_STOCK_STATUSES {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  OPEN = 'Open',
  COMPLETED = 'Completed',
  VOID = 'Void',
  ACTIVE = 'Active',
  DRAFT = 'Draft',
  REQUESTED = 'Requested',
  PARTIALLY_ACCEPTED = 'PartiallyAccepted',
}

export type InvoiceTypes =
  | 'PurchaseOrder'
  | 'RemoveStock'
  | 'TransferStock'
  | 'ReturnStock'
  | 'StockCount';
export const REWAA_ALJAZIRA_IBAN = 'SA46 6010 0007 6950 0395 0001';
export const REWAA_RIYAD_IBAN = 'SA74 2000 0002 4116 3936 9940';

export enum InvoiceStatus {
  OPEN = 'Open',
  COMPLETED = 'Completed',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export const DEFAULT_LOCATION = {
  CODE: 'DEF',
  LOCATIONID: 1,
};

export enum MODES {
  ADD = 'add',
  EDIT = 'edit',
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export const PRODUCT_TYPES = {
  Simple: { label: 'Simple Product', name: 'simple', values: ['simple'] },
  Variable: {
    label: 'Variable product',
    name: 'variable',
    values: ['variable'],
  },
  Composite: {
    label: 'Composite product',
    name: 'composite',
    values: ['composite'],
  },
  Digital: {
    label: 'Digital product',
    name: 'digital',
    values: ['ecard', 'eproduct'],
  },
};
export const ACTIVITY_LOGS_SEARCH = [
  {
    en: 'Add product',
    ar: 'إضافة منتج',
  },
  {
    en: 'Edit product',
    ar: 'تعديل المنتج',
  },
  {
    en: 'Import product',
    ar: 'أستيراد منتج',
  },
  {
    en: 'Export products',
    ar: 'تصدير منتج',
  },
  {
    en: 'Delete product',
    ar: 'حذف منتج',
  },
  {
    en: 'Publish product',
    ar: 'نشر منتج',
  },
  {
    en: 'Unpublish product',
    ar: 'عدم نشر المنتج',
  },
  {
    en: 'Link product',
    ar: 'ربط منتج',
  },
  {
    en: 'Unlink product',
    ar: 'الغاء ربط منتج',
  },
  {
    en: 'Send product',
    ar: 'إرسال المنتج',
  },
  {
    en: 'Add category',
    ar: 'إضافة الفئة',
  },
  {
    en: 'Add brand',
    ar: 'إضافة  العلامة التجارية',
  },
  {
    en: 'Receive product',
    ar: 'استلام المنتج',
  },
  {
    en: 'Export batch list',
    ar: 'تصدير قائمة الدُفعات',
  },
  {
    en: 'Export serial list',
    ar: 'تصدير القائمة التسلسلية',
  },
  {
    en: 'Add variant',
    ar: 'إضافة نوع',
  },
  {
    en: 'Remove variant',
    ar: 'إزالة المتغير',
  },
  {
    en: 'Edit variant',
    ar: 'تحرير المتغير',
  },
  {
    en: 'Publish variant',
    ar: 'نشر البديل',
  },
  {
    en: 'Unpublish variant',
    ar: 'متغير إلغاء النشر',
  },
  {
    en: 'Add extra',
    ar: 'أضف المزيد',
  },
  {
    en: 'Remove extra',
    ar: 'إزالة الزائدة',
  },
  {
    en: 'Remove pack',
    ar: 'قم بإزالة العبوة',
  },
  {
    en: 'Add pack',
    ar: 'أضف الحزمة',
  },
  {
    en: 'Edit pack',
    ar: 'تحرير الحزمة',
  },
  {
    en: 'Deleted image',
    ar: 'الصورة المحذوفة',
  },
  {
    en: 'Change main image',
    ar: 'تغيير الصورة الرئيسية',
  },
  {
    en: 'Changed image',
    ar: 'الصورة المتغيرة',
  },
  {
    en: 'Updated images',
    ar: 'الصور المحدثة',
  },
  {
    en: 'Edit product active properties',
    ar: 'تحرير خصائص المنتج النشطة',
  },
  {
    en: 'Receive order',
    ar: 'استلام طلب',
  },
  {
    en: 'Install app',
    ar: 'تثبيت تطبيق',
  },
  {
    en: 'Uninstall app',
    ar: 'الغاء تثبيت تطبيق',
  },
  {
    en: 'Change location app',
    ar: 'تغيير موقع تطبيق',
  },
  {
    en: 'transferAccount Change Account',
    ar: 'تغيير حساب التحويل في قيود',
  },
  {
    en: 'inventoryAccount Change Account',
    ar: 'تغيير حساب المخزون في قيود',
  },
  {
    en: 'removeStockRevenueAccount Change Account',
    ar: 'تغيير حساب اخراج المخرون في قيود',
  },
  {
    en: 'stockCountRevenueAccount Change Account',
    ar: 'تغيير حساب جرد المخرون في قيود',
  },
  {
    en: 'inventoryExpenseAccount Change Account',
    ar: 'تغيير حساب تكلفة المخزون في قيود',
  },
  {
    en: 'inventorySalesAccount Change Account',
    ar: 'تغيير حساب مبيعات المخزون في قيود',
  },
  {
    en: 'Issue order invoice',
    ar: 'اصدار فاتورة طلب',
  },
  {
    en: 'Status change',
    ar: 'تغيير حالة طلب',
  },
  {
    en: 'Export invoices',
    ar: 'تصدير الفواتير',
  },
  {
    en: 'Send order sell invoice to Qoyod',
    ar: 'ارسال فاتورة بيع لقيود',
  },
  {
    en: 'Receive order item add',
    ar: 'استلام اضافة منتج في طلب',
  },
  {
    en: 'Receive order item delete',
    ar: 'استلام حذف منتج في طلب',
  },
  {
    en: 'Receive order item change',
    ar: 'استلام تغيير منتج في طلب',
  },
  {
    en: 'Receive order change',
    ar: 'استلام تغيير في طلب',
  },
  {
    en: 'Retry error',
    ar: 'حل خطأ في طلب',
  },
  {
    en: 'Add qoyod payment',
    ar: 'اضافة طريقة دفع في قيود',
  },
  {
    en: 'Change qoyod payment',
    ar: 'تغيير طريقة دفع في قيود',
  },
  {
    en: 'Add qoyod location',
    ar: 'اضافة موقع مخزون في قيود',
  },
  {
    en: 'Change qoyod location',
    ar: 'تغيير موقع مخزون في قيود',
  },
];

export enum DigitalProductTypes {
  E_PRODUCT = 'eproduct',
  E_CARD = 'ecard',
}

export type ProductTypes = 'simple' | 'variable' | 'composite' | 'digital';

export enum PURCHASE_ORDER_STATUS {
  OPEN = 'Open',
  COMPLETED = 'Completed',
}

export enum PURCHASE_ORDER_STATUS_V2 {
  OPEN = 'Draft',
  COMPLETED = 'Completed',
}

export const IMAGE_CONSTRAINTS = {
  UploadImageLimit: 30,
  MaxImageSize: 2097152, // = 2mb = 2*1024*1024;
};

export enum VariantTypes {
  Child = 'child',
  Package = 'package',
  Composite = 'composite',
  Simple = 'simple',
}

export const ROUTES = {
  POS_REGISTERS: '/pos/registers',
  POS_CASH_MANAGEMENT: 'pos/cash-management',
  POS_SETTING: '/pos/settings',
};

export const PRODUCT_TRACK_TYPES = {
  SERIAL: 'serial',
  BATCH: 'batch',
};

export const PrintLayouts = {
  THERMAL: 'Thermal',
  A4: 'A4',
};

export const CASH_TYPES = {
  ADD: 'Add',
  WITHDRAW: 'Withdraw',
};

export const WEIGHTED_PRODUCT_LAST_DIGITS = {
  WEIGHT: 'Weight',
  PRICE: 'Price',
};
export const POS_SETTINGS = {
  BonatRedeem: 'pos.inv.bonatRedeem',
  InvCompanyLogo: 'pos.inv.companyLogo',
  InvSalesNote: 'pos.inv.salesNote',
  InvCustomerPhoneNumber: 'pos.inv.customerPhoneNumber',
  InvCustomerLocation: 'pos.inv.customerLocation',
  InvSkuBarcode: 'pos.inv.skuBarcode',
  InvTax: 'pos.inv.tax',
  InvDiscount: 'pos.inv.discount',
  InvPromotion: 'pos.inv.promotion',
  InvHideNaDiscount: 'pos.inv.hideNaDiscount',
  InvCompositeChilds: 'pos.inv.compositeChilds',
  InvSerialNumber: 'pos.inv.serialNumber',
  InvBatchNumberBatchQty: 'pos.inv.batchNumberBatchQty',
  InvPackRateMainProduct: 'pos.inv.packRateMainProduct',
  InvWeightUnit: 'pos.inv.weightUnit',
  InvDisplayTotalItems: 'pos.inv.totalItems',
  InvQRcode: 'pos.inv.qrCode',
  thanksYouImg: 'pos.inv.thanksYouImg',
  termsOfService: 'pos.inv.termsOfService',
  InvHideNaDiscountFooter: 'pos.inv.hideNaDiscountFooter',
  InvHideNaPromotionFooter: 'pos.inv.hideNaPromotionFooter',
  InvInvoiceBarcode: 'pos.inv.invoiceBarcode',
  InvShowComment: 'pos.inv.showComment',
  InvComment: 'pos.inv.comment',
  InvPrintLayout: 'pos.inv.printLayout',
  InvTextSize: 'pos.inv.textSize',
  InvPrintLanguage: 'pos.inv.printLanguage',
  SellOnQuantityLtZero: 'sell.on.quantity.lt.zero',
  SellOnPriceLtCost: 'sell.on.price.lt.cost',
  InvCommentImage: 'pos.inv.commentImage',
  InvShowCommentImage: 'pos.inv.showCommentImage',
  SellOnDateOfSupply: 'sell.on.date.of.supply',
  InvSellInvoiceNumber: 'pos.inv.displaySellInvoiceNumberInReturnReceipt',
  InvTaxPercentage: 'pos.inv.taxPercentage',
  InvCustomerVatNumber: 'pos.inv.customerVATNumber',
  InvOrderNumberEnabled: 'pos.inv.orderNumberEnabled',
  InvOrderNumberStart: 'pos.inv.orderNumberStart',
  InvMaxOrders: 'pos.inv.maxOrders',
  InvCustomerCommercialNumber: 'pos.inv.customerCommercialNumber',
  InvCommercialNumber: 'pos.inv.commercialNumber',
  InvAddress: 'pos.inv.address',
  SellOnB2B: 'sell.on.b2b',
  SurePay: 'sell.on.surepay',
  NumberDialogue: 'sell.on.dialogue',
  SkuInSearch: 'pos.on.skuInSearch',
  SalesmanInvoice: 'pos.on.salesmanInvoice',
  SalesmanReceipt: 'pos.inv.salesmanReceipt',
  Quotation: 'pos.on.quotation',
  MultiTemplate: 'pos.on.multiTemplateEnabled',
  MerchantAddress: 'pos.inv.address',
  CommercialNumber: 'pos.inv.commercialNumber',
  CustomerAdditionalIdentifier: 'pos.inv.customerAdditionalIdentifier',
  CustomerIdentifierType: 'pos.inv.customerIdentifierType',
  CustomerName: 'pos.inv.customerName',
  TotalTaxInclusive: 'pos.inv.totalTaxInclusive',
  TotalTaxExclusive: 'pos.inv.totalTaxExclusive',
};

/**
 * General Settings required on Invoice level
 * Saved in Receipt Template settings
 */
export const POS_RECEIPT_TEMPLATE_MAP = {
  [POS_SETTINGS.SellOnB2B]: 'sellOnB2B',
  [POS_SETTINGS.SellOnDateOfSupply]: 'dateOfSupply',
  [POS_SETTINGS.SalesmanInvoice]: 'salesmanInvoice',
};

export const ORDER_SETTINGS = {
  SellOnQuantityLtAvailable: 'sell_when_quantity_less_than_0',
  SellOnPriceLtCost: 'sell_when_price_less_than_cost',
  AutomateInvoiceIssuance: 'automate_invoice_issuance',
};

export enum CustomPolicies {
  DASHBOARD = 'Dashboard',
  REPORTS = 'Reports',
  INVENTORY = 'Inventory',
  ORDER = 'Order Management',
  STOCK = 'Stock Control',
  APPLICATION = 'Application',
  SETTINGS = 'Settings',
}

export enum CommonPolicies {
  DASHBOARD_SEARCH = 'dashboard.search',
  DASHBOARD_REVENUE = 'dashboard.revenue',
  DASHBOARD_GROSS_PROFIT = 'dashboard.gross_profit',
  DASHBOARD_TRANSACTION = 'dashboard.transaction',
  DASHBOARD_INVENTORY_VALUE = 'dashboard.inventory_value',
  DASHBOARD_AVERAGE_SALES = 'dashboard.average_sales',
  DASHBOARD_TOTAL_SALES = 'dashboard.total_sales',
  DASHBOARD_AVERAGE_ITEMS_PER_SALE = 'dashboard.average_items_per_sale',
  DASHBOARD_TOP_5_PRODUCTS = 'dashboard.top_5_products',
  DASHBOARD_AMOUNT_COLLECTED = 'dashboard.amount_collected',
  DASHBOARD_EXPENSE = 'dashboard.expense',
  DASHBOARD_NET_INCOME = 'dashboard.net_income',
  DASHBOARD_READ = 'dashboard.read',
  NEW_DASHBOARD_SALES_TARGET = 'new_dashboard.sales_target',
  NEW_DASHBOARD_SALES_TRANSACTION = 'new_dashboard.sales_transaction',
  NEW_DASHBOARD_SALES_BY_DAY = 'new_dashboard.sales_by_day',
  NEW_DASHBOARD_SALES_BY_PRODUCT = 'new_dashboard.sales_by_product',
  NEW_DASHBOARD_SALES_BY_CATEGORY = 'new_dashboard.sales_by_category',
  NEW_DASHBOARD_SALES_BY_BRANCH = 'new_dashboard.sales_by_branch',
  NEW_DASHBOARD_SALES_BY_SALESMAN = 'new_dashboard.sales_by_salesman',
  NEW_DASHBOARD_NOTIFICATIONS = 'new_dashboard.notifications',
  NEW_DASHBOARD_LOGS = 'new_dashboard.logs',
  NEW_DASHBOARD_ORDERS_AND_SALES = 'new_dashboard.latest_orders_and_sales',
  NEW_DASHBOARD_REGISTER_CHART = 'new_dashboard.register_chart',
  NEW_DASHBOARD_INVENTORY_VALUE = 'new_dashboard.inventory_value',
  NEW_DASHBOARD_AMOUNT_COLLECTED = 'new_dashboard.amount_collected',
  PRODUCT_MANAGEMENT_READ = 'product_management.read',
  SETTING_READ = 'setting.read',
  ERROR_LIST_READ = 'error_list.read',
  PRODUCT_AVAILABLE_QUANTITY_READ = 'product.available_quantity.read',
  PRODUCT_RETAIL_PRICE_READ = 'product.retail_price.read',
  PRODUCT_WHOLESALE_PRICE_READ = 'product.wholesale_price.read',
  PRODUCT_BUY_PRICE_READ = 'product.buy_price.read',
  PRODUCT_COST_READ = 'product.cost.read',
  PRODUCT_SUPPLIER_READ = 'product.supplier.read',
  PRODUCT_SOLD_QUANTITY_READ = 'product.sold_quantity.read',
  SUPPLIER_DETAIL_READ = 'supplier.detail.read',
  SUPPLIER_DETAIL_CREATE = 'supplier.detail.create',
  SUPPLIER_DETAIL_UPDATE = 'supplier.detail.update',
  SUPPLIER_DETAIL_DELETE = 'supplier.detail.delete',
  PRODUCT_DETAILS_SUPPLIER_READ = 'product_details.supplier.read',
  PRODUCT_DETAILS_SUPPLIER_CREATE = 'product_details.supplier.create',
  PRODUCT_DETAILS_SUPPLIER_UPDATE = 'product_details.supplier.update',
  PRODUCT_DETAILS_RETAIL_PRICE_READ = 'product_details.retail_price.read',
  PRODUCT_DETAILS_RETAIL_PRICE_UPDATE = 'product_details.retail_price.update',
  PRODUCT_DETAILS_WHOLESALE_PRICE_READ = 'product_details.wholesale_price.read',
  PRODUCT_DETAILS_WHOLESALE_PRICE_UPDATE = 'product_details.wholesale_price.update',
  PRODUCT_DETAILS_BUY_PRICE_READ = 'product_details.buy_price.read',
  PRODUCT_DETAILS_BUY_PRICE_UPDATE = 'product_details.buy_price.update',
  PRODUCT_DETAILS_AVERAGE_COST_READ = 'product_details.average_cost.read',
  PRODUCT_DETAILS_AVERAGE_COST_UPDATE = 'product_details.average_cost.update',
  PRODUCT_DETAILS_QUANTITY_READ = 'product_details.quantity.read',
  PRODUCT_DETAILS_QUANTITY_UPDATE = 'product_details.quantity.update',
}

export enum ZatcaAppPermissions {
  ZatcaDashboardRead = 'zatca.dashboard.read',
  ZatcaDashboardUpdate = 'zatca.dashboard.update',
  ZatcaSettingsRead = 'zatca.settings.read',
  ZatcaErrorListRead = 'zatca.error_list.read',
}

export const receiptTextSizes = {
  Thermal: ['10', '11', '12', '13'],
  A4: ['11', '12', '13', '14', '15', '16'],
};

export const UI_SYSTEM_POLICIES = [
  { name: 'Location', logo: 'location_permission.svg' },
  { name: 'Dashboard', logo: 'dashboard_permission.svg' },
  { name: 'Reports', logo: 'settings_permission.svg' },
  { name: 'Inventory', logo: 'inventory_permission.svg' },
  { name: 'Order management', logo: 'order_management_permission.svg' },
  { name: 'Stock control', logo: 'stock_control_permission.svg' },
  { name: 'Custom Field', logo: 'custom_fields.svg' },
  { name: 'Applications', logo: 'applications_permission.svg' },
  { name: 'Settings', logo: 'settings_permission.svg' },
];

export const UI_NEW_MENU_PERMISSION_NAMES = {
  Location: 'Location',
  Dashboard: 'Dashboard',
  Reports: 'Reports',
  Inventory: 'Inventory new',
  'Order management': 'Order management new',
  'Stock control': 'Stock control new',
  'Custom Field': 'Custom Field',
  Applications: 'Applications',
  Settings: 'Settings',
};

export const UI_APP_POLICIES = [
  {
    name: 'POS',
    logo: 'rewaa_new_pos.svg',
    class: 'rewaa-new-logo',
    roles: [
      { name: USER_ROLES.STORE_MANAGER, label: 'Store Manager' },
      { name: USER_ROLES.CASHIER, label: 'Cashier' },
      { name: USER_ROLES.CUSTOM, label: 'Custom' },
    ],
    level: USER_ROLES_LEVEL.APPLICATION,
    managed: true,
    appName: APP_NAMES.INTERNAL_POS,
  },
  {
    name: 'Zatca',
    logo: 'zatca_app.svg',
    class: 'zata-logo',
    level: USER_ROLES_LEVEL.APPLICATION,
    appName: APP_NAMES.ZATCA,
  },
  {
    name: 'Woo Commerce',
    logo: 'woocommerce-temp.svg',
    class: 'woo-logo',
    appName: APP_NAMES.WOO_COMMERCE,
  },
  {
    name: 'Magento',
    logo: 'magento_full.png',
    class: 'magento-logo',
    appName: APP_NAMES.MAGENTO,
  },
  {
    name: 'Zid',
    logo: 'zid.svg',
    class: 'zid-logo',
    appName: APP_NAMES.ZID,
  },
  {
    name: 'Qoyod',
    logo: 'qoyod_logo.webp',
    class: 'qoyod-logo',
    appName: APP_NAMES.QOYOD,
  },
  {
    name: 'Salla',
    logo: 'salla-new-logo.webp',
    class: 'salla-logo',
    appName: APP_NAMES.SALLA,
  },
  {
    name: 'Point of Sale',
    logo: 'rewaa_pos.svg',
    class: 'rewaa-logo',
    appName: APP_NAMES.POINT_OF_SALE,
  },
  {
    name: 'Promotion Management',
    logo: 'promotion_management.svg',
    class: 'rewaa-logo',
    managed: true,
    appName: APP_NAMES.PROMOTION_MANAGMENET,
  },
  {
    name: 'Expenses',
    logo: 'expenses.svg',
    class: 'rewaa-logo',
    managed: true,
    appName: APP_NAMES.EXPENSES,
  },
  {
    name: 'Accounting',
    logo: 'accounting.svg',
    class: 'rewaa-logo',
    managed: true,
    appName: APP_NAMES.ACCOUNTING,
  },
];

export const UI_REPORTS_POLICIES = [
  { name: 'Inventory Reports' },
  { name: 'Tax Reports' },
  { name: 'Sales Reports' },
  { name: 'Stock Control Reports' },
  { name: 'Reports List' },
];

export const UI_INVENTORY_POLICIES = [
  { name: 'Product list' },
  { name: 'Product details' },
];

export enum InventoryProductPolicyName {
  LIST = 'list',
  DETAIL = 'detail',
}

export enum PaymentMethodTypes {
  CASH = 'Cash',
  CARD = 'Card',
  OTHER = 'Other',
  SOFTPOS = 'SoftPos',
}

export enum PaymentMethodNames {
  NEARPAY = 'Rewaa Pay',
}

export enum TRACK_TYPE {
  SERIAL = 'serial',
  BATCH = 'batch',
}

export enum RewaaInformation {
  vatNumber = '310188259700003',
}

export enum LAYOUT_TYPE {
  CLASSIC = 'Classic',
  CUSTOMIZED = 'Customized',
}
export const APP_PERMISSION_PREFIXES: Array<any> = [
  { name: APP_NAMES.WOO_COMMERCE, prefix: 'woo_commerce' },
  { name: APP_NAMES.MAGENTO, prefix: 'magento' },
  { name: APP_NAMES.ZID, prefix: 'zid' },
  { name: APP_NAMES.QOYOD, prefix: 'qoyod' },
  { name: APP_NAMES.SALLA, prefix: 'salla' },
  { name: APP_NAMES.POINT_OF_SALE, prefix: 'point_of_sale' },
  { name: APP_NAMES.PROMOTION_MANAGMENET, prefix: 'promotion_management' },
  { name: APP_NAMES.ACCOUNTING, prefix: 'accounting_app' },
  { name: APP_NAMES.EXPENSES, prefix: 'expenses' },
  { name: APP_NAMES.ZATCA, prefix: 'zatca' },
  { name: APP_NAMES.BONAT, prefix: 'bonat' },
];

export const APP_SUB_PERMISSION_PREFIXES = {
  DASHBOARD: 'dashboard',
  PRODUCT_MANAGEMENT: 'product_management',
  SETTING: 'setting',
  ERROR_LIST: 'error_list',
};

export const SCROLL_PARAMETERS = {
  throttle: 50,
  scrollDistance: 1,
  scrollUpDistance: 2,
};

export const LAYOUT_CATEGORY_CONFIG_PARAMS = {
  UNCATEGORIZED: { name: 'Uncategorised Products' },
  QUICK_MENU: { name: 'Quick Menu' },
};

export enum ReportType {
  Rep_Inventory = 'Inventory',
  Rep_Sales = 'Sales',
  Rep_Tax = 'Taxes',
  Rep_Stock = 'Stock Control',
  Rep_Finance = 'Finance',
}

export const BARCODE_SIZE = {
  STANDARD: {
    value: 'STANDARD',
    name: 'Standard Size',
    height: 22,
    width: 1,
    cssClass: 'standard',
    fontSize: 14,
  },
  LARGE: {
    value: 'LARGE',
    name: 'Large Size',
    height: 40,
    width: 2,
    cssClass: 'large',
    fontSize: 28,
  },
};

export const BARCODE_SIZE_REVAMP = {
  STANDARD: {
    value: 'STANDARD',
    name: 'Standard Size',
    height: 22,
    width: 1,
    cssClass: 'standard',
    fontSize: 12,
  },
  LARGE: {
    value: 'LARGE',
    name: 'Large Size',
    height: 40,
    width: 1.5,
    cssClass: 'large',
    fontSize: 20,
  },
};

export const POS_PO_TYPES = {
  IN: 'IN',
  OUT: 'OUT',
  TRANS: 'TRANS',
};

export enum STORAGE_INSTANCE_TYPE {
  STORE_NAME = 'MIMS',
  CONFIG = 'config',
  PRODUCTS = 'products',
  SELL_INVOICES = 'sellInvoices',
  CUSTOMER = 'customer',
  PARK = 'park',
}

export const STORE_INSTANCES = [
  { key: 'productStore', storeName: STORAGE_INSTANCE_TYPE.PRODUCTS },
  { key: 'customerStore', storeName: STORAGE_INSTANCE_TYPE.CUSTOMER },
  { key: 'invoiceStore', storeName: STORAGE_INSTANCE_TYPE.SELL_INVOICES },
  { key: 'configStore', storeName: STORAGE_INSTANCE_TYPE.CONFIG },
];

export const INVOICE_ID_PARAMS = {
  LAST_INVOICE_INTERVAL: 10800000,
  INVOICE_INCREMENTAL_NUMBER: 'invoiceIncrementalNumber',
  PAYMENT_INCREMENTAL_NUMBER: 'paymentIncrementalNumber',
};
export const OfflineInvoicesCount = 'OfflineInvoicesCount';
export const OfflineInvoicesCountV2 = 'OfflineInvoicesCountV2';
export const OfflineInvoicesCountChangeSet = 'OfflineInvoicesCountChangeSet';
export const RewaaPaySessionToken = 'RewaaPaySessionToken';
export const RewaaPayTerminalInfo = 'RewaaPayTerminalInfo';
export const RewaaPayRetryAttempts = 15;
export const RewaaPayRetryInterval = 60000;

export const SyncCompletionDisappearTime = 60000;

export const INVOICE_ORDER_NUMBER = {
  START: 'orderNumberStart',
  CURR: 'orderNumberCurrent',
};

export const WEB_SOCKET_ACTIONS = {
  PRODUCT_EXPORT: 'productsExport',
  PRODUCT_EXPORT_V2: 'productsExport',
  PRODUCT_UPDATE_EXPORT: 'productsUpdateExport',
  INVOICES_EXPORT: 'invoicesExport',
  STOCK_INVOICES_EXPORT: 'stockInvoicesExport',
  GET_SCANNED_INVOICE: 'getScannedInvoice',
};

export enum StockControlPermissions {
  StockCountDetailViewCost = 'stock.count.detail.view_cost',
  StockCountListViewCost = 'stock.count.list.view_cost',

  TransferStockDetailViewCost = 'transfer.stock.detail.view_cost',
  TransferStockDetailUpdate = 'transfer.stock.detail.update',
  TransferStockDetailCreate = 'transfer.stock.detail.create',
  TransferStockDetailRead = 'transfer.stock.detail.read',

  TransferStockListSearch = 'transfer.stock.list.search',
  TransferStockListExport = 'transfer.stock.list.export',
  TransferStockListRead = 'transfer.stock.list.read',
}

export enum WEB_SOCKET_WORKER_ACTIONS {
  INITIALISE = 'INITIALISE',
  SEND = 'SEND',
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  START_PING_PONG = 'START_PING_PONG',
  STOP_PING_PONG = 'STOP_PING_PONG',
  TOGGLE_FORCE_OFFLINE = 'TOGGLE_FORCE_OFFLINE',
  DESTROY = 'DESTROY',
  SET_AUTH_DETAILS = 'SET_AUTH_DETAILS',
}

export const OFFLINE_INVOICE_STORAGE_LIMIT =
  environment.offline_config.invoice_limit;

export const COUNTRY_CODE = 966;

export const CONDITION_TYPE = {
  startsWith: 'STARTS_WITH',
  endsWith: 'ENDS_WITH',
  contains: 'CONTAINS',
  notContains: 'NOT_CONTAINS',
  notEquals: 'NOT_EQUAL',
  equals: 'EQUAL_TO',
  equal: 'EQUAL_TO',
  lt: 'LESS_THEN',
  lte: 'LESS_THEN_EQUAL',
  gt: 'MORE_THEN',
  gte: 'MORE_THEN_OR_EQUAL',
};

export enum LANGUAGES {
  en = 'en',
  ar = 'ar',
}

export const APP_FILTER = {
  ALL: 'All',
  INSTALLED: 'Installed',
  MORE_APPS: 'More Apps',
};

export const UNINSTALL_REASONS_KEY = {
  NOT_FULFILLED: 'doesNotFulFillMyNeed',
  NOT_PERFORMING_WELL: 'appIsNotPerformingWell',
  NO_NEEDED_ANYMORE: 'notNeededAnymore',
  SETUP_COMPLICATIONS: 'setupCausedComplications',
  OTHER: 'other',
  TRAIL_ONLY: 'onlyTrail',
};

export const UNINSTALL_REASONS = [
  {
    name: 'Does not fulfill my need',
    key: UNINSTALL_REASONS_KEY.NOT_FULFILLED,
  },
  {
    name: 'App is not performing well',
    key: UNINSTALL_REASONS_KEY.NOT_PERFORMING_WELL,
  },
  { name: 'Not needed anymore', key: UNINSTALL_REASONS_KEY.NO_NEEDED_ANYMORE },
  {
    name: 'Setup caused complications',
    key: UNINSTALL_REASONS_KEY.SETUP_COMPLICATIONS,
  },
  { name: 'Other', key: UNINSTALL_REASONS_KEY.OTHER },
];

export const PLAN_SHORT_ID = {
  LOCATION: 'location',
  SMALL_ANNUALLY: 'small-annually',
  SMALL_2YEARS: 'small-2years',
  MEDIUM_ANNUALLY: 'medium-annually',
  MEDIUM_2YEARS: 'medium-2years',
  LARGE_ANNUALLY: 'large-annually',
  LARGE_2YEARS: 'large-2years',
  SPECIAL_ADVANCED_ANNUALLY: 'special-advanced-plan-annually',
  SPECIAL_ADVANCED_2YEAR: 'special-advanced-plan-2years',
  SPECIAL_ENTERPRISE_ANNUALLY: 'special-enterprise-plan-annually',
  SPECIAL_ENTERPRISE_2YEARS: 'special-enterprise-plan-2years',
  SPECIAL_ENTERPRISE_LOCATION: 'special-enterprise-location',
  SPECIAL_ADVANCED_LOCATION: 'special-advanced-location',
};

export const SubscriptionPlanDuration = {
  Plan1Year: '1-year',
  Plan2Year: '2-year',
};

export const PlanType = {
  Standard: 'Standard',
  Special: 'Special',
  Custom: 'Custom',
};

export const AppModuleIds = {
  Dashboard: 'dashboard',
  StockCount: 'stock-count',
  TransferStock: 'transfer-stock',
  Inventory: 'inventory',
  CustomerManagement: 'customer-management',
  SupplierManagement: 'supplier-management',
  PurchaseOrder: 'purchase-order',
  Reports: 'reports',
  StockControl: 'stock-control',
  Locations: 'locations',
  GettingStarted: 'getting-started',
};

export const SubscriptionPaymentMethod = {
  PayByCard: 'pay-by-card',
  PayByMobile: 'pay-by-mobile',
  BankTransfer: 'bank-transfer',
};

export const PLAN_NAMES = {
  BASIC_ANNUALLY: 'Basic',
  ADVANCED_ANNUALLY: 'Advanced',
  ENTERPRISE_ANNUALLY: 'Enterprise',
  BASIC_2YEAR: 'Basic for 2 years',
  ADVANCED_2YEAR: 'Advanced for 2 years',
  ENTERPRISE_2YEAR: 'Enterprise for 2 years',
  SPECIAL_ENTERPRISE_ANNUALLY: 'Special Enterprise',
  SPECIAL_ENTERPRISE_2YEAR: 'Special Enterpise for 2 years',
  SPECIAL_ADVANCED_ANNUALLY: 'Special Advanced',
  SPECIAL_ADVANCED_2YEAR: 'Special Advanced for 2 years',
  SPECIAL_ENTERPRISE_LOCATION: 'Special Enterprise Location',
  SPECIAL_ADVANCED_LOCATION: 'Special Advanced Location',
  TRIAL: 'Trial',
  PLAN: 'Plan',
};

export const REJECT_TRANSFER_STOCK_KEY = {
  MISSING_PRODUCTS: 'missingProducts',
  ADDITIONAL_PRODUCTS: 'additionalProducts',
  DIFFERENT_PRODUCTS: 'differentProducts',
  DAMAGED_PRODUCTS: 'damagedProducts',
  OTHER: 'other',
};
export const modulesMapping: { path: string; moduleName: string }[] = [
  { path: '/getting-started', moduleName: AppModuleIds.GettingStarted },
  { path: '/dashboard', moduleName: AppModuleIds.Dashboard },
  { path: '/inventory/products', moduleName: AppModuleIds.Inventory },
  {
    path: 'reports',
    moduleName: AppModuleIds.Reports,
  },
  { path: '/invoices/stock-count', moduleName: AppModuleIds.StockCount },
  {
    path: '/invoices/transfer-stock',
    moduleName: AppModuleIds.TransferStock,
  },
  {
    path: 'order-management/customers',
    moduleName: AppModuleIds.CustomerManagement,
  },
  {
    path: '/invoices/suppliers',
    moduleName: AppModuleIds.SupplierManagement,
  },
  {
    path: '/invoices/purchase-orders',
    moduleName: AppModuleIds.PurchaseOrder,
  },
  {
    path: '/users-settings/locations',
    moduleName: AppModuleIds.Locations,
  },
];

export const REJECT_TRANSFER_STOCK_REASONS = [
  { name: 'Missing products', key: REJECT_TRANSFER_STOCK_KEY.MISSING_PRODUCTS },
  {
    name: 'Additional products',
    key: REJECT_TRANSFER_STOCK_KEY.ADDITIONAL_PRODUCTS,
  },
  {
    name: 'Different products',
    key: REJECT_TRANSFER_STOCK_KEY.DIFFERENT_PRODUCTS,
  },
  { name: 'Damaged products', key: REJECT_TRANSFER_STOCK_KEY.DAMAGED_PRODUCTS },
  { name: 'Other', key: REJECT_TRANSFER_STOCK_KEY.OTHER },
];

export type DROPDOWN_VALUES = Array<Date | DateTime>;

export enum DATE_DROPDOWN_SELECTION {
  RANGE = 'range',
  SPECIFIC = 'specific',
}

export const DATE_DROPDOWN_SELECTION_TYPE = {
  RANGE: 'range',
  SPECIFIC: 'specific',
};

export const DATE_LOOKUP_OPTIONS = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  WEEK: 'Last 7 Days',
  MONTH: 'Last 30 Days',
  QUARTER: 'Last 90 Days',
};

export const DATE_LOOKUP_OPTIONS_V4 = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  WEEK: 'thisWeek',
  MONTH: 'thisMonth',
  QUARTER: 'lastThreeMonths',
  SPECIFIC_RANGE: 'specificRange',
};

export enum COMPANY_TYPES {
  OTHER = 'Other',
  SUPERMARKET = 'Supermarkets & Groceries',
  FRUIT_AND_VEG = 'Fruits & vegetables',
  BUTCHER = 'Butcher Shop',
  BAKERY = 'Bakery & Sweets',
  SPICE_ROASTARY_NUTS = 'Spice & Roastery & Nuts',
  WATER_AND_REFRESHMENTS = 'Water & Refreshments',
  TOBACCO = 'Tobacco Store',
  GAS = 'Gas Station',
  TECH = 'Communication & Technology',
  TEXTILE = 'Textile & Tailor',
  BUILDING = 'Building Material & Plumbing & Carpentry & Blacksmith & Glass',
  DECOR = 'Decor & Design',
  ELECTRICAL = 'Electrical Household appliances',
  FURNITURE = 'Furniture & Kitchens',
  BOOK_AND_PRINT = 'BookStore & Printshop & Studio',
  HOME_GOODS = 'Home Goods',
  APPARELS_AND_FOOTWEAR = 'Apparels & Footwear',
  PERFUME_AND_BEAUTY = 'Perfume & Beauty Products',
  PHARMA = 'Pharmacies & Medical Goods',
  OPTICS = 'Eye Glass Shop',
  JEWELLERY = 'Gold & Jewelry',
  VETERINARY = 'Veterinary & Pet Store',
  GIFTS = 'Gifts & Flowers & Crafts & ِArt',
  HOUSE_GAS_AND_COAL = 'House Gas & Coal',
  AUTO_PARTS = 'Selling of Car Auto Parts',
  CAR_MAINTENANCE = 'Car Maintenance & Car Wash',
  CAR_DEALERSHIP = 'Car Dealership',
  CONTRACTOR = 'Contractor',
  REAL_ESTATE = 'Real Estate',
  WAREHOUSE_AND_TRANSPORT = 'Warehousing & Transportation',
  SHORT_TERM_ACCOMODATION = 'Short Term Accommodations',
  RESTAURANT = 'Restaurant',
  DINING_RESTAURANT = 'Dining Restaurants',
  FAST_FOOD_RESTAURANT = 'Fast Food and Quick Service Restaurants',
  CAFETERIA = 'Cafeteria',
  KIOSKS = 'Food Trucks & Kiosks',
  CATERING = 'Catering',
  JUICE_AND_COFFE = 'Juicery & Coffee Shop',
  LEGAL = 'Consulting & Legal activities',
  AD = 'Advertising',
  GYM_AND_SPORTS = 'Gyms & Sport Courts',
  LAUNDRY = 'Laundry',
  SALON = 'Salons & Barber Shops & Massage',
  HOUSE_SERVICES = 'House Services',
  SHOPS_AND_STORES = 'Shops & Stores',
}

export enum Sector {
  Retail = 'Retail',
  FAndB = 'F&B',
  Service = 'Service',
  Other = 'Other',
}

export const companyTypeToSector = {
  [COMPANY_TYPES.AD]: Sector.Service,
  [COMPANY_TYPES.APPARELS_AND_FOOTWEAR]: Sector.Retail,
  [COMPANY_TYPES.AUTO_PARTS]: Sector.Retail,
  [COMPANY_TYPES.BAKERY]: Sector.FAndB,
  [COMPANY_TYPES.BOOK_AND_PRINT]: Sector.Retail,
  [COMPANY_TYPES.BUILDING]: Sector.Retail,
  [COMPANY_TYPES.BUTCHER]: Sector.Retail,
  [COMPANY_TYPES.CAR_DEALERSHIP]: Sector.Retail,
  [COMPANY_TYPES.CAR_MAINTENANCE]: Sector.Service,
  [COMPANY_TYPES.CONTRACTOR]: Sector.Service,
  [COMPANY_TYPES.DECOR]: Sector.Service,
  [COMPANY_TYPES.ELECTRICAL]: Sector.Retail,
  [COMPANY_TYPES.FRUIT_AND_VEG]: Sector.Retail,
  [COMPANY_TYPES.FURNITURE]: Sector.Retail,
  [COMPANY_TYPES.GAS]: Sector.Retail,
  [COMPANY_TYPES.GIFTS]: Sector.Retail,
  [COMPANY_TYPES.GYM_AND_SPORTS]: Sector.Service,
  [COMPANY_TYPES.HOME_GOODS]: Sector.Retail,
  [COMPANY_TYPES.HOUSE_GAS_AND_COAL]: Sector.Retail,
  [COMPANY_TYPES.HOUSE_SERVICES]: Sector.Service,
  [COMPANY_TYPES.JEWELLERY]: Sector.Retail,
  [COMPANY_TYPES.JUICE_AND_COFFE]: Sector.FAndB,
  [COMPANY_TYPES.LAUNDRY]: Sector.Service,
  [COMPANY_TYPES.LEGAL]: Sector.Service,
  [COMPANY_TYPES.OPTICS]: Sector.Retail,
  [COMPANY_TYPES.OTHER]: Sector.Other,
  [COMPANY_TYPES.PERFUME_AND_BEAUTY]: Sector.Retail,
  [COMPANY_TYPES.PHARMA]: Sector.Retail,
  [COMPANY_TYPES.REAL_ESTATE]: Sector.Service,
  [COMPANY_TYPES.RESTAURANT]: Sector.FAndB,
  [COMPANY_TYPES.DINING_RESTAURANT]: Sector.FAndB,
  [COMPANY_TYPES.FAST_FOOD_RESTAURANT]: Sector.FAndB,
  [COMPANY_TYPES.CAFETERIA]: Sector.FAndB,
  [COMPANY_TYPES.KIOSKS]: Sector.FAndB,
  [COMPANY_TYPES.CATERING]: Sector.FAndB,
  [COMPANY_TYPES.SALON]: Sector.Service,
  [COMPANY_TYPES.SHORT_TERM_ACCOMODATION]: Sector.Service,
  [COMPANY_TYPES.SPICE_ROASTARY_NUTS]: Sector.Retail,
  [COMPANY_TYPES.SUPERMARKET]: Sector.Retail,
  [COMPANY_TYPES.TECH]: Sector.Retail,
  [COMPANY_TYPES.TEXTILE]: Sector.Service,
  [COMPANY_TYPES.TOBACCO]: Sector.Retail,
  [COMPANY_TYPES.VETERINARY]: Sector.Retail,
  [COMPANY_TYPES.WAREHOUSE_AND_TRANSPORT]: Sector.Service,
  [COMPANY_TYPES.WATER_AND_REFRESHMENTS]: Sector.Retail,
};

export enum DiscountTypes {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export const offlineEvent = {
  isOnline: false,
};

export const onlineEvent = {
  isOnline: true,
};

export const POSTemplateSettingMap = {
  dateOfSupply: POS_SETTINGS.SellOnDateOfSupply,
  sellOnB2B: POS_SETTINGS.SellOnB2B,
  salesmanInvoice: POS_SETTINGS.SalesmanInvoice,
  termsOfService: POS_SETTINGS.termsOfService,
  thanksYouImg: POS_SETTINGS.thanksYouImg,
};

export enum PrintFrameId {
  PosReceipt = 'pos-receipt-print',
  ProductBarcode = 'product-barcode-print',
  RegisterReport = 'register-report-print',
  returnStock = 'return-stock-print',
  removeStock = 'remove-stock-print',
  SoftPosReceipt = 'softpos-receipt-print',
}

export const UserGuideId = {
  downloadPrinterPlugin: {
    en: 69979,
    ar: 69980,
  },
  keyboardShortcut: {
    en: 76789,
    ar: 76790,
  },
  productDetailsSurvey: 78540,
  keyboardShortcutSurvey: 82845,
  advancedAccountingTeaser: 84154,
};

export interface WebSocketAuth {
  email: string;
  token: string;
}

export const SupportedLanguages = ['ar', 'en'];

export const AppSyncingTimeout = {
  [APP_NAMES.SALLA]: 'SallaSyncTimeout',
  [APP_NAMES.WOO_COMMERCE]: 'WooCommerceSyncTimeout',
  [APP_NAMES.POINT_OF_SALE]: 'PointOfSaleSyncTimeout',
  [APP_NAMES.INTERNAL_POS]: 'InternalPosSyncTimeout',
  [APP_NAMES.MAGENTO]: 'MagentoSyncTimeout',
  [APP_NAMES.ZID]: 'ZidSyncTimeout',
  [APP_NAMES.QOYOD]: 'QoyodSyncTimeout',
  [APP_NAMES.PROMOTION_MANAGMENET]: 'PromotionManagementSyncTimeout',
  [APP_NAMES.EXPENSES]: 'ExpensesSyncTimeout',
  [APP_NAMES.ACCOUNTING]: 'AccountingSyncTimeout',
  [APP_NAMES.ZATCA]: 'ZatcaSyncTimeout',
};

export enum LocalStorageKey {
  // the explicitly used keys for localStorage
  Language = 'language',
  LanguageEn = 'languageen',
  LanguageAr = 'languagear',
  returnUrl = 'returnUrl',
  needsRefresh = 'needsRefresh',
  User = 'user',
  FullStoreUrl = 'fullStoreUrl',
  ReferralCode = 'referralCode',
  Latitude = 'latitude',
  Longitude = 'longitude',
  ForceRefresh = 'forceRefresh',
  AffiliatesCouponForInvitee = 'affiliatesCouponForInvitee',
  NewDesignEnabled = 'newDesignEnabled',
  NewNavDesignEnabled = 'newNavDesignEnabled',
  DashboardDateRange = 'dashboardDateRange',
  DashboardSelectedBranches = 'dashboardSelectedBranches',
  /**
   * @deprecated There is no need for use to be storing the token in localStorage
   * when aws congnio pakcage is already does that for us. Instead all other
   * services should be using the aws cognito package to get the token. The
   * function to use is @see getAuthorizationToken from @see UserAuthenticationService
   */
  IdToken = 'idToken',
  PrintBarcodeIncludeCompany = 'print.barcode.includeCompany',
  PrintBarcodeIncludeName = 'print.barcode.includeName',
  PrintBarcodeIncludePrice = 'print.barcode.includePrice',
  PrintBarcodeIncludeTaxType = 'print.barcode.includeTaxType',
  AppSubscription = 'app_subscribe',
  LayoutProductDeleteInfo = 'layout-product-delete-info',
  TransferStockPrint = 'rw_transfer_stock_print',
  StockCount = 'rw_stock_count_default_columns',
  SallaSyncTimeout = 'salla syncingTimeout',
  WooCommerceSyncTimeout = 'woocommerce syncingTimeout',
  PointOfSaleSyncTimeout = 'point of sale syncingTimeout',
  InternalPosSyncTimeout = 'new POS syncingTimeout',
  MagentoSyncTimeout = 'magento syncingTimeout',
  ZidSyncTimeout = 'zid syncingTimeout',
  QoyodSyncTimeout = 'qoyod syncingTimeout',
  PromotionManagementSyncTimeout = 'promotion management syncingTimeout',
  ExpensesSyncTimeout = 'expenses syncingTimeout',
  AccountingSyncTimeout = 'accounting syncingTimeout',
  ZatcaSyncTimeout = 'zatca syncingTimeout',
  CustomField = 'CustomField',
  keyboardShortcutTooltipEnabled = 'keyboardShortcutTooltipEnabled',
  wasOnPromotionTrial = 'wasOnPromotionTrial',
  wasOnAccountingTrial = 'wasOnAccountingTrial',
  CoBrowsingActivationToken = 'cobrowsing_activated',
  DidRefreshOnSaleError = 'DidRefreshOnSaleError',
  HideSallaTokenBanner = 'HideSallaTokenBanner',
  BasePlanId = 'basePlanId',
  TrialEndedPopUpShown = 'trialEndedPopUpShown',
  subscriptionDataDirtyKey = 'subscriptionDataDirtyKey',
  resendTimer = 'resendTime',
  referralLink = 'referralLink',
  Permissions = 'permissions',
  ExternalAppProductImportAndLinkStartTime = 'externalAppProductImportAndLinkStartTime',
  ZidSyncBatchId = 'zidSyncBatchId',
  SallaSyncBatchId = 'sallaSyncBatchId',
  InstallImportBatchId = 'InstallImportBatchId',
  ExpenseColumnCustomization = 'expenseColumnCustomization',
  LocationFilterIncomeStatement = 'locationFilterIncomeStatement',
  RenewalReminder = 'renewalReminder',
  OrderListV2ColumnCustomization = 'orderListV2ColumnCustomization',
  // StartingResendTime = 'startingResendTime',
  PreviousRegisterData = 'previousRegisterData',
  IsInternalInventoryMigrationNeededForAppInstallation = 'isInternalInventoryMigrationNeededForAppInstallation',
  AccountHasSalesTransactions = 'accountHasSalesTransactions',
  HideOnlineOrdInvBanner = 'hideOnlineOrdInvBanner',
  HideOnlineOrdProdErrorBanner = 'hideOnlineOrdProdErrorBanner',
  HideOnlineOrdTrialBalReportErrorBanner = 'hideOnlineOrdTrialBalReportErrorBanner',
  BusinessType = 'businessType',
  OnboardingStatus = 'onboardingStatus',
}

export const SELL_ON_PRICE_LESS_THAN_COST_DISABLED_STATE = '0';

export const OFFLINE_MODE_FULL_TEXT_SEARCH_LIMIT = 1000;

export enum ConfigStoreKeys {
  ProductApiConfig = 'ProductApiConfig',
  InventoryApiConfig = 'InventoryApiConfig',
  PromotionApiConfig = 'PromotionApiConfig',
  PromotionV2ApiConfig = 'PromotionV2ApiConfig',
  CustomerChangeSetAPIConfig = 'CustomerChangeSetAPIConfig',
  CustomerChangeSetAPIConfigV2 = 'CustomerChangeSetAPIConfigV2',
  UserDeviceIdentifier = 'UserDeviceIdentifier',
}

export enum TrialState {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  PAID = 'Paid',
}

export interface ProductApiConfig {
  updatedAt?: string;
  locationId: number;
}

export interface InventoryApiConfig {
  updatedAt: Date;
  locationId: number;
}

export interface PromotionApiConfig {
  updatedAt?: Date;
  locationId: number;
  nextToken?: string;
}

export interface PromotionV2ApiConfig {
  locationId: number;
  updatedAt?: string;
}

export interface CustomerChangeSetAPIConfig {
  updatedAt: string;
}

export const InventoryApiDefaultInterval = 2 * 60000; // 2 mins
export type IntegrationStatus =
  | INTEGRATION_STATUSES.LINKED
  | INTEGRATION_STATUSES.UNLINKED
  | INTEGRATION_STATUSES.NOT_FOUND
  | INTEGRATION_STATUSES.LINKING
  | INTEGRATION_STATUSES.UNLINKING;

export const SCROLLABLE_TABS_ARROWS = {
  LEFT_ARROW_DIRECTION: -1,
  RIGHT_ARROW_DIRECTION: 1,
};

export const DEFAULT_SELECTED_SCROLLABLE_TAB_INDEX = 0;

export const SCROLLABLE_TAB_SCROLL_SPEED = 500;

export enum PermissionModules {
  Dashboard = 'dashboard',
  Inventory = 'inventory',
  Reports = 'reports',
  OrderManagement = 'order-management',
  StockControl = 'stock-control',
  Applications = 'applications',
  Settings = 'settings',
  Pos = 'pos',
  Accounting = 'accounting',
  Bonat = 'bonat',
  Promotions = 'promotions',
  Qoyod = 'qoyod',
  Salla = 'salla',
  WooCommerce = 'woo-commerce',
  Zatca = 'zatca',
  Zid = 'zid',
  Expenses = 'expenses',
  Magento = 'magento',
  CustomField = 'custom-field',
  GettingStarted = 'getting-started',
}

export enum ExternalChannelTrackingEvents {
  SubscriptionUpdate = 'subscriptionUpdate',
  ProductsImportAndLink = 'productsImportAndLink',
  OrderErrorResolution = 'orderErrorResolution',
}

export const closeAllRegistersPermission = 'pos.sales_screen.close_all';

export const headerTranslations = {
  Invoices: {
    old: 'Invoices',
    new: 'Sale Invoices',
  },
  Orders: {
    old: 'Orders',
    new: 'eCommerce Orders',
  },
  Supplier: {
    old: 'Supplier',
    new: 'Suppliers',
  },
};
