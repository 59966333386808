"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPlainObject = void 0;
const protobuf_timestamp_1 = require("./protobuf-timestamp");
function toPlainObject(obj) {
    if (Array.isArray(obj)) {
        return obj.map(toPlainObject);
    }
    if (obj && typeof obj === 'object') {
        if ('seconds' in obj && 'nanos' in obj) {
            return (0, protobuf_timestamp_1.fromProtobufTimestamp)(obj);
        }
        return Object.fromEntries(Object.entries(obj)
            .filter(([key]) => key !== '__proto__' && key !== 'constructor')
            .map(([key, value]) => [key, toPlainObject(value)]));
    }
    return obj;
}
exports.toPlainObject = toPlainObject;
