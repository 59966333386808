"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDatesToProtobuf = exports.toProtobufTimestamp = exports.isISODateString = exports.convertProtobufDates = exports.fromProtobufTimestamp = void 0;
function fromProtobufTimestamp(timestamp) {
    if (!(timestamp === null || timestamp === void 0 ? void 0 : timestamp.seconds))
        return null;
    const seconds = typeof timestamp.seconds === 'object'
        ? timestamp.seconds.low
        : timestamp.seconds;
    const nanos = timestamp.nanos || 0;
    return new Date(seconds * 1000 + nanos / 1e6).toISOString();
}
exports.fromProtobufTimestamp = fromProtobufTimestamp;
function convertProtobufDates(obj) {
    if (Array.isArray(obj)) {
        return obj.map(convertProtobufDates);
    }
    if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
            if (value && typeof value === 'object') {
                if ('seconds' in value && 'nanos' in value) {
                    return [key, fromProtobufTimestamp(value)];
                }
                return [key, convertProtobufDates(value)];
            }
            return [key, value];
        }));
    }
    return obj;
}
exports.convertProtobufDates = convertProtobufDates;
function isISODateString(value) {
    return (typeof value === 'string' &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/.test(value));
}
exports.isISODateString = isISODateString;
function toProtobufTimestamp(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return {
        seconds: Math.floor(date.getTime() / 1000),
        nanos: (date.getTime() % 1000) * 1e6,
    };
}
exports.toProtobufTimestamp = toProtobufTimestamp;
function convertDatesToProtobuf(obj) {
    if (Array.isArray(obj)) {
        return obj.map(convertDatesToProtobuf);
    }
    if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
            if (value instanceof Date || isISODateString(value)) {
                return [key, toProtobufTimestamp(value)];
            }
            return [key, convertDatesToProtobuf(value)];
        }));
    }
    return obj;
}
exports.convertDatesToProtobuf = convertDatesToProtobuf;
