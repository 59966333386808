<div class="flex flex-column gap-4 py-5">
  <div class="flex flex-column gap-2">
    <label class="font-bold text-2xl mb-0">
      {{ translationKey + 'signUpTitle' | translate }}
    </label>
    <p class="font-normal text-lg mb-0">
      {{ translationKey + 'signUpSubTitle' | translate }}
    </p>
    <ul class="wizard-progress">
      <li 
        *ngFor="let idx of [0, 1, 2];"
        [ngClass]="{
          'active': idx === 0,
          'done': idx < 0,
        }"
      ></li>
    </ul>
  </div>

  <!-- Email Already Used Warning - Start -->
  <div *ngIf="userAlreadyExists" class="border-1 border-red-40 rw__notification-error">
    <div class="notification-body align-items-start">
        <i class="border-3 border-red-30 rounded-circle fa-regular fa-circle-exclamation rw__fs-5"></i>
        <div>
            <p class="rw__m-0">
                {{ translationKey + 'emailInUse.emailAlreadyUsed' | translate }}
                {{ translationKey + 'emailInUse.useDifferentEmail' | translate }}
                {{ translationKey + 'emailInUse.or' | translate }}
                <a [routerLink]="['login']" class="rw__link">
                  {{ translationKey + 'emailInUse.tryToSignIn' | translate }}
                </a>
            </p>
        </div>
    </div>
  </div>
  <!-- Email Already Used Warning - End -->

  <div class="flex flex-column gap-3">
    <form class="flex flex-column gap-3" [formGroup]="signUpForm" #ngForm (ngSubmit)="submitSignUp()">

      <!-- Company Name - Start -->
      <div>
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-store"></i>
          <input name="companyName" type="text" pInputText placeholder="{{ 'Company' | translate }}" 
            [formControl]="companyName" context="register" [required]="true" />
        </span>
        <ng-container *ngIf="companyName.invalid && companyName.touched">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="companyName.errors.required" class="p-error block mx-1">
              {{ translationKey + 'cRequired' | translate }}
            </small>
          </div>
        </ng-container>
      </div>
      <!-- Company Name - End -->

      <!-- Email - Start -->
      <div>
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-envelope"></i> 
          <input name="email" type="text" pInputText placeholder="{{ 'Email' | translate }}" 
            [formControl]="email" context="register" [required]="true" />
        </span>
        <ng-container *ngIf="email.invalid && email.touched">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="email.errors.required" class="p-error block mx-1">
              {{ translationKey + 'emailRequired' | translate }}
            </small>
            <small *ngIf="email.errors.pattern" class="p-error block mx-1">
              {{ translationKey + 'emailInvalid' | translate }}
            </small>
          </div>
        </ng-container>
      </div>
      <!-- Email - End -->

      <!-- Phone - Start -->
      <div class="flex flex-column">
        <div class="flex gap-2 ltr">
          <span class="rw__input rw__input-float p-float-label minw-0 w-14rem" pendo-tracker="sign_up_country_code_drop_down_clicked">
            <p-dropdown panelStyleClass="rw__input-country-dropdown" [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="localeName" [autoDisplayFirst]="false"
              (onChange)="onCountrySelect()" [ngModelOptions]="{standalone: true}" appendTo="body">
              <ng-template pTemplate="filter" let-options="options">
                <div class="d-flex">
                  <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full" (click)="$event.stopPropagation()" [ngClass]="{'rw__input-icon-close': filterValue}">
                    <i class="fa-regular fa-magnifying-glass"></i>
                    <input type="text" pInputText placeholder="{{ 'Search' | translate }}" [(ngModel)]="filterValue" [ngModelOptions]="{standalone: true}" (keyup)="options.filter($event)">
                    <i class="fa-regular fa-xmark" (click)="myResetFunction(options)"></i>
                  </span>
                </div>
              </ng-template>
              <ng-template pTemplate="selectedItem">
                  <div class="flex align-item-center" *ngIf="selectedCountry">
                    <img class="border-round" src="https://flagsapi.com/{{selectedCountry.countryKey}}/flat/32.png" alt="{{ selectedCountry.name }} Flag" width="22" />
                    <span class="rw__ms-3">
                      +{{selectedCountry.code}}
                    </span>
                  </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                  <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <span>
                          {{ country.localeName }}
                        </span>
                        <span class="rw__ms-2 ltr">
                          (+{{country.code}})
                        </span>
                      </div>
                  </div>
              </ng-template>
            </p-dropdown>
          </span>
          <span class="rw__input rw__input-default minw-0 w-full">
            <input name="phone" type="text" pInputText [formControl]="phone"
              pendo-tracker="sign_up_phone_number_field_typing" autocomplete="one-time-code" />
          </span>
        </div>
        <ng-container *ngIf="phone.invalid && phone.touched">
          <div class="d-flex flex-column">
            <div *ngIf="phone.errors.required" class="d-flex rw__mt-2">
              <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
              <small class="p-error block mx-1">
                {{ translationKey + 'phoneRequired' | translate }}
              </small>
            </div>
            <div *ngIf="!phone.errors.required">
              <div *ngIf="phone.errors.zeroSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">
                  {{ translationKey + 'zeroSaudiPattern' | translate }}
                </small>
              </div>
              <div *ngIf="phone.errors.saudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">
                  {{ translationKey + 'saudiPattern' | translate }}
                </small>
              </div>
              <div *ngIf="phone.errors.saudiZeroOrZeroFive" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">
                  {{ translationKey + 'saudiZeroOrZeroFive' | translate }}
                </small>
              </div>
              <div *ngIf="phone.errors.otherThanSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-solid fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">
                  {{ translationKey + 'phoneErrorNotSaudiPattern' | translate }}
                </small>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Phone - End -->

      <!-- License Key - Start -->
      <span *ngIf="showLicenseKey" class="rw__input rw__input-default rw__mt-2" [ngClass]="lang === 'ar' ? 'rw__input-mask' : ''">
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-key"></i>
          <p-inputMask  mask="99999 - 99999 - 99999 - 99999" placeholder="XXXXX - XXXXX - XXXXX - XXXXX" slotChar="x"
            [unmask]="true" [autoClear]="false" [formControl]="licenseKey" [showClear]="true" pendo-tracker="sign_up_license_key_field_typing"
            (onComplete)="onLicenseKeyComplete()" (keyup)="validateLicenseKeyInput()" (onBlur)="validateLicenseKeyInput()" (onClear)="clearLicenseKey()">
            <input type="text" pInputText/>
          </p-inputMask>
        </span>
        <ng-container *ngIf="licenseKey.touched && licenseKey.invalid">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="licenseKey.errors.minlength || licenseKey.errors.maxlength" class="p-error block mx-1">
              {{ translationKey + 'licenseLengthError' | translate }}
            </small>
            <small *ngIf="licenseKey.errors.notValid" class="p-error block mx-1">
              {{ translationKey + 'licenseNotValidError' | translate }}
            </small>
          </div>
        </ng-container>
        <small class="rw__fs-10 text-grey-50 block rw__mt-2">
          {{ translationKey + 'youCanFindLicenseKeyMessage' | translate }}
        </small>
      </span>
      <!-- License Key - End -->

      <!-- Referral Code - Start -->
      <div *ngIf="showReferralCode">
        <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
          <i class="fa-regular fa-gift"></i>
          <input name="referralCode" type="text" pInputText 
            placeholder="{{ translationKey + 'enterReferralCode' | translate }}" 
            [formControl]="referralCode" context="register" (input)="onReferralCodeChange()"/>
          <p-progressSpinner *ngIf="referralCode.pending" 
            [style]="{ 'width': '24px', 'height': '24px', 'position': 'absolute', 'inset-inline-end': '1rem' }"
            class="rw_spinner_field"
            styleClass="flex rw__spinner" strokeWidth="4"></p-progressSpinner>
        </span>
        <ng-container *ngIf="referralCode.invalid && referralCode.touched">
          <div class="flex align-items-center mt-1">
            <i class="fa-solid fa-circle-exclamation p-error"></i>
            <small *ngIf="referralCode.errors.invalidReferralCode" class="p-error block mx-1">
                {{ translationKey + 'referralCodeInvalid' | translate }}
            </small>
            <small *ngIf="referralCode.errors.validationFailed" class="p-error block mx-1">
              {{ translationKey + 'validationFailed' | translate }}
          </small>
          </div>
        </ng-container>
      </div>
      <!-- Referral Code - End -->

      <!-- License Key And Referral Code - Start -->
      <div class="flex flex-column">
        <div class="d-flex align-items-center gap-2">
          <div>
            <span>{{ translationKey + 'haveLicenseKeyOrReferral.one' | translate }}</span>
            <span (click)="handleShowReferralCode()" class="cursor-pointer text-teal-60 ">
              {{ translationKey + 'haveLicenseKeyOrReferral.two' | translate }}
            </span>
            <span>{{ translationKey + 'haveLicenseKeyOrReferral.three' | translate }}</span>
            <span (click)="handleShowLicenseKey()" class="cursor-pointer text-teal-60 ">
              {{ translationKey + 'haveLicenseKeyOrReferral.four' | translate }}
            </span>
          </div>

          <div>
            <button type="button" (click)="openLicenseKeyDialog()" class="text-grey-60 bg-transparent border-0 rw__p-0">
              <i class="fa-regular fa-circle-info"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- License Key And Referral Code - End -->

      <!-- Agree - Start -->
      <div class="mt-3">
        <div class="flex align-items-start">
          <p-checkbox 
            formControlName="isTermsAndPrivacyAccepted" 
            [binary]="true"
            inputId="isTermsAndPrivacyAccepted" 
            class="rw__me-3"
          />
          <label for="isTermsAndPrivacyAccepted" class="rw__mb-0">
            {{ translationKey + 'termsAndConditions.agreeToRewaa' | translate }}
            <a [href]="getTermsAndConditionsHRef()" target="_blank" class="rw__link">
              {{ translationKey + 'termsAndConditions.terms&Conditions' | translate }}
            </a>
            {{ translationKey + 'termsAndConditions.andRewaas' | translate }}
            <a [href]="getPrivacyPolicyHRef()" target="_blank" rel="noopener noreferrer" class="rw__link">
              {{ translationKey + 'termsAndConditions.privacyPolicy' | translate }}
            </a>
            {{ translationKey + 'termsAndConditions.forRewaa' | translate }}
          </label>
        </div>
        <ng-container *ngIf="termsWarning">
          <div class="flex mt-1">
            <i class="fa-solid fa-circle-exclamation p-error mt-1"></i>
            <small class="p-error block mx-1">
              {{ translationKey + 'agreementIsRequired' | translate }}
            </small>
          </div>
        </ng-container>
      </div>
      <!-- Agree - End -->

      <!-- Submit - Start -->
      <div class="mt-2 gap-2">
        <button
          pButton pRipple
          pendo-tracker="sign_up_next_cta_button_click"
          type="submit"
          class="flex align-items-center justify-content-center w-full"
          [disabled]="nextDisabled">
          <p-progressSpinner *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
            styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner>
          <span class="text-base">
            {{ 'Next' | translate }}
          </span>
        </button>
      </div>
      <!-- Submit - End -->
    </form>
  </div>  

  <!-- Already Account - Start -->
  <div class="text-center">
    <span class="text-base text-grey-50">
      {{ translationKey + 'alreadyHaveAccount' | translate }}
      <a [routerLink]="['/login']" class="rw__link" pendo-tracker="sign_up_login_cta_button_click">
        {{ translationKey + 'login' | translate }}
      </a>
    </span>
  </div>
  <!-- Already Account - End -->
</div>

<!-- License Key Modal - Start -->
<rw-license-key [dialogVisible]="openLicenseKeyDialogVisible" (closeDialog)="closeLicenseKeyDialog()"></rw-license-key>
<!-- License Key Modal - End -->