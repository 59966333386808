import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenPaginatedResponse } from '@rewaa-team/types';
import { OfflinePosTypes } from '@rewaa-team/pos-sdk';
import { PromotionChangeSetV2, VariantChangeSetResponse } from '../types/types';

@Injectable()
export class OfflineProductService {
  constructor(private http: HttpClient) {}

  /**
   * @deprecated
   */
  getOfflineVariants(queryParams): Observable<any> {
    let productQueryParams = new HttpParams();
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key]) {
        productQueryParams = productQueryParams.set(key, queryParams[key]);
      }
    });
    return this.http.get(`/api/offline-data/variants`, {
      params: productQueryParams,
    });
  }

  triggerVariantFileCreation(stockLocationId: number) {
    return this.http.get(
      `/api/pos-service/products/variants/${stockLocationId}/trigger-file-creation`,
    );
  }

  getOfflineProductVariantsChangeSet(
    stockLocationId: number,
    updatedAt?: string,
    useNewApi = false,
  ): Observable<VariantChangeSetResponse> {
    if (useNewApi) {
      return this.getOfflineProductVariantsChangeSetV2(
        stockLocationId,
        updatedAt,
      );
    }
    let queryParams = new HttpParams().set('stockLocationId', stockLocationId);
    if (updatedAt) {
      queryParams = queryParams.set('updatedAt', updatedAt);
    }
    return this.http.get<VariantChangeSetResponse>(
      `/api/pos-service/products/variants/change-set`,
      {
        params: queryParams,
      },
    );
  }

  getOfflineProductVariantsChangeSetV2(
    stockLocationId: number,
    updatedAt?: string,
    useNewApi = false,
  ): Observable<VariantChangeSetResponse> {
    let queryParams = new HttpParams().set('locationId', stockLocationId);
    if (updatedAt) {
      queryParams = queryParams.set('updatedAt', updatedAt);
    }
    return this.http.get<VariantChangeSetResponse>(
      `/api/inventory-service/mims-legacy/variants/change-set`,
      {
        params: queryParams,
      },
    );
  }

  getOfflinePromotionChangeSet(
    stockLocationId: number,
    updatedAt?: Date,
    nextToken?: string,
  ): Observable<
    TokenPaginatedResponse<OfflinePosTypes.PromotionChangeSetItem, string>
  > {
    return this.http.post<
      TokenPaginatedResponse<OfflinePosTypes.PromotionChangeSetItem, string>
    >(`/api/pos-service/promotions/change-set`, {
      stockLocationId,
      updatedAt,
      nextToken,
    });
  }

  getOfflinePromotionChangeSetV2(
    stockLocationId: number,
    updatedAt?: Date,
  ): Observable<PromotionChangeSetV2[]> {
    let queryParams = new HttpParams().set(
      'stockLocationId',
      stockLocationId.toString(),
    );

    if (updatedAt) {
      queryParams = queryParams.set('updatedAt', updatedAt.toISOString());
    }
    return this.http.get<PromotionChangeSetV2[]>(
      '/api/marketing/promotions/change-set',
      {
        params: queryParams,
      },
    );
  }

  getInventory(
    stockLocationId: number,
    updatedAt?: string,
    nextToken?: number,
  ): Observable<OfflinePosTypes.InventoryApiResult> {
    let queryParams = new HttpParams()
      .set('stockLocationId', stockLocationId)
      .set('limit', 1000);
    if (updatedAt) {
      queryParams = queryParams.set('lastUpdatedAt', updatedAt);
    }
    if (nextToken) {
      queryParams = queryParams.set('nextToken', nextToken);
    }
    return this.http.get<OfflinePosTypes.InventoryApiResult>(
      `/api/offline-data/inventory`,
      {
        params: queryParams,
      },
    );
  }

  deleteOfflineVariants(): Observable<Object> {
    return this.http.delete(`/api/offline-data/variants`);
  }

  downloadVariantsFile(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, {
      responseType: 'arraybuffer',
    });
  }
}
