import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { LayoutCategory } from 'src/app/internal-apps/pos/model/Layout';
import { Product } from 'src/app/inventory/model/product';
import { Category, CreatedCategoryResult } from '../../model/category';
import { PageQuery } from '../../../shared/services/pagination-interface';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';

const API_URL = '/api';
const featureFlagName = FeatureFlagEnum.InventoryService;
const baseInvUrl = '/inventory-service';

@Injectable()
export class CategoryService {
  private categories$: BehaviorSubject<Category[] | undefined> =
    new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  getCategories(): Observable<Category[]> {
    if (this.categoriesLoaded()) return this.getCategoriesObs();
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          return this.getCategoriesFromInventoryService();
        }
        return this.getCategoriesFromMims();
      }),
      first(),
    );
  }

  getCategoriesFromInventoryService(): Observable<Category[]> {
    return this.http
      .get<{ data: Category[] }>(`${API_URL}${baseInvUrl}/categories`)
      .pipe(
        map((response) => {
          const categories = response.data.map((type) => new Category(type));
          this.categories$.next(categories);
          return categories;
        }),
      );
  }

  getCategoriesFromMims(): Observable<Category[]> {
    return this.http.get<Category[]>(`${API_URL}/categories`).pipe(
      map((response) => {
        const categories = response.map((type) => new Category(type));
        this.categories$.next(categories);
        return categories;
      }),
    );
  }

  private categoriesLoaded(): boolean {
    return Boolean(this.categories$.getValue());
  }

  private getCategoriesObs() {
    return this.categories$.asObservable();
  }

  getCategoriesPage(
    { limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' },
  ) {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          return this.getCategoriesPageFromInventoryService({
            limit,
            offset,
            query,
          });
        }
        return this.getCategoriesPageFromMims({ limit, offset, query });
      }),
      first(),
    );
  }

  getCategoriesPageFromInventoryService(
    { limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' },
  ) {
    return this.http
      .get<{
        data: Category[];
        meta: {
          total: number;
          limit: number;
          offset: number;
        };
      }>(
        `${API_URL}${baseInvUrl}/categories?offset=${offset}&limit=${limit}&query=${query}`,
      )
      .pipe(
        map((response) => ({
          result: response.data.map((category) => new Category(category)),
          count: response.meta.total,
        })),
      );
  }

  getCategoriesPageFromMims(
    { limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' },
  ) {
    return this.http.get<{ result: Category[]; count: number }>(
      `${API_URL}/categories?offset=${offset}&limit=${limit}&query=${query}`,
    );
  }

  createCategory(category: Category): Observable<CreatedCategoryResult> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          return this.createCategoryInInventoryService(category);
        }
        return this.createCategoryInMims(category);
      }),
      first(),
    );
  }

  createCategoryInInventoryService(
    category: Category,
  ): Observable<CreatedCategoryResult> {
    return this.http
      .post<{ data: Category }>(`${API_URL}${baseInvUrl}/categories`, category)
      .pipe(
        map((response) => {
          if (response.data) {
            response.data = new Category(response.data);
            this.pushCategory(response.data);
          }
          return { category: response.data };
        }),
      );
  }

  createCategoryInMims(category: Category): Observable<CreatedCategoryResult> {
    return this.http
      .post<CreatedCategoryResult>(`${API_URL}/categories`, category)
      .pipe(
        map((response) => {
          if (response.category) {
            response.category = new Category(response.category);
            this.pushCategory(response.category);
          }
          return response;
        }),
      );
  }

  pushCategory(category: Category) {
    this.categories$.getValue().push(category);
    this.categories$.next(this.categories$.getValue());
  }

  public getCategoryByLevel(level: number): Observable<Category[]> {
    return this.http
      .get<Category[]>(`${API_URL}/categories?level=${level}`)
      .pipe(map((response) => response.map((type) => new Category(type))));
  }

  async getCategoryChildren(id: number): Promise<LayoutCategory[]> {
    return this.http
      .get<LayoutCategory[]>(`${API_URL}/categories/${id}/children`)
      .pipe(map((response) => response.map((type) => new LayoutCategory(type))))
      .toPromise();
  }

  async getCategoryProduct(
    id: number,
    query: { offset?: string; limit?: string; all?: boolean },
  ): Promise<{ count: number; rows: Product[] }> {
    return this.http
      .get<{
        count: number;
        rows: any[];
      }>(
        `${API_URL}/categories/${id}/products?offset=${query.offset}&limit=${query.limit}&all=${query.all || false}`,
      )
      .toPromise();
  }

  async fetchUncategorizedProducts(query: {
    offset?: string;
    limit?: string;
    all?: boolean;
  }): Promise<{ count: number; rows: Product[] }> {
    return this.http
      .get<{
        count: number;
        rows: any[];
      }>(
        `${API_URL}/categories/none/products?offset=${query.offset}&limit=${query.limit}&all=${query.all || false}`,
      )
      .toPromise();
  }

  searchVariantsByCategoryId(id, query) {
    // id none if uncategorised product
    const selectedId = id || 'none';
    return this.http.get(
      `${API_URL}/categories/${selectedId}/variants?query=${query.search}&offset=${query.offset}&limit=${query.limit}`,
    );
  }

  getCategoryByName(categoryName: string) {
    return of(
      this.categories$
        .getValue()
        .find((category) => category.name === categoryName),
    );
  }
}
