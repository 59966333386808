import { PaymentSource } from '../invoice/types';
import { TemplateTypes } from '../template';
import { DiscountLevel } from './enums';

export interface InvoiceMerchant {
  id: number;
  logoUrl: string;
  name: string;
  vatNumber: string;
  commercialNumber: string;
  address: string;
  email: string;
}

export interface InvoiceMerchantLocation {
  id: number;
  name: string;
  registerId: number;
  registerName: string;
  salesmanId: number;
  salesman: string;
  address: string;
  commercialRegisterNumber?: string;
}

export interface InvoiceCustomer {
  id: number;
  code?: string;
  name?: string;
  address?: string;
  vatNumber?: string;
  commercialNumber?: string;
  nationalId?: string;
  phoneNumber?: string;
  debitAmount?: number;
  customField?: InvoiceCustomField[];
}

export interface InvoiceLineItemPromotion {
  id: number;
  name: string;
  amount: number;
}

export interface InvoiceWeightedProduct {
  id: number;
  unit: string;
}

export interface InvoiceCompositeProduct {
  id: number;
  name: string;
  quantity: number;
}

export interface InvoiceBatchProduct {
  id: number;
  sno: number;
  trackNo: string;
  quantity: number;
}

export interface InvoiceSerialProduct {
  id: number;
  sno: number;
  trackNo: string;
}

export interface InvoicePackProduct {
  id: number;
  name: string;
  quantity: number;
  rate: number;
}

export interface InvoiceExtraProduct {
  id: number;
  sno: number;
  sku?: string;
  name: string;
  price: number;
  quantity: number;
  subtotal: number;
  total: number;
}

export interface InvoiceECardProduct {
  id: number;
  code: string;
}

export interface InvoiceEProduct {
  id: number;
  url: number;
}

export interface InvoiceLineItem {
  id: number;
  name: string;
  type: string;
  quantity: number;
  sku: string;
  price: number;
  priceAfterPromoOrDiscount?: number;
  taxAmount: number;
  taxRate: number;
  discountAmount: number;
  discountRate: number;
  subtotal: number;
  totalTaxExc: number;
  total: number;
  categoryIds?: number[];
  customFields?: InvoiceCustomField[];
  promotion?: InvoiceLineItemPromotion;
  weighted?: InvoiceWeightedProduct;
  composites: InvoiceCompositeProduct[];
  batches: InvoiceBatchProduct[];
  serials: InvoiceSerialProduct[];
  packs: InvoicePackProduct[];
  extras: InvoiceExtraProduct[];
  eCards: InvoiceECardProduct[];
}

export interface InvoicePayment {
  id?: number;
  paymentNumber: string;
  methodId: number;
  methodName: string;
  userId: number;
  username?: string;
  date: Date;
  formattedDate?: string;
  paymentAmount: number;
  source?: PaymentSource;
}

export interface InvoiceDiscount {
  id: string;
  name: string;
  amount: number;
}

export interface InvoiceTax {
  id?: number;
  name?: string;
  amount?: number;
  rate?: number;
  taxLines?: TaxLineSummary[];
  taxableAmount?: number;
  compound?: CompoundTaxSummary;
}

export interface CompoundTaxSummary {
  name: string;
  rate: number;
  price: number;
}

export interface TaxLineSummary {
  name: string;
  rate: number;
  price: number;
}
export interface InvoiceSummary {
  totalItems: number;
  /**
   * Invoice + Product + Promotion
   */
  totalDiscount: number;
  /**
   * Invoice + Product
   */
  totalOtherDiscount: number;
  /**
   * change from cash payment when no multiple payment methods
   */
  change?: number;
  /**
   * received from cash payment when no multiple payment methods
   */
  receivedAmount?: number;
  totalDebitAmount: number;
  totalTax: number;
  subtotal: number;
  total: number;
  taxes: InvoiceTax[];
  discounts: Record<DiscountLevel, number>;
}

export interface InvoiceCustomField {
  id?: number;
  customFieldId: number;
  module: any;
  referenceId: number;
  value: string;
  customFieldJSON?: JSON & {
    name: string;
    addToReceiptOption?: boolean;
  };
}

export const ReceiptTypeConstant = {
  CreditNote: 'creditNote',
  TaxableInvoice: 'taxableInvoice',
  SimpleTaxableInvoice: 'simpleTaxableInvoice',
  InvoicePaymentReceipt: 'invoicePaymentReceipt',
} as const;

export type ReceiptType =
  (typeof ReceiptTypeConstant)[keyof typeof ReceiptTypeConstant];

export const InvoiceTypeConstant = {
  ReturnInvoice: 'returnInvoice',
  SellInvoice: 'sellInvoice',
} as const;

export type InvoiceType =
  (typeof InvoiceTypeConstant)[keyof typeof InvoiceTypeConstant];

export interface InvoiceSnapshot {
  id?: number;
  invoiceNumber: string;
  saleInvoiceNumber?: string;
  completedAt: string;
  userId: number;
  username: string;
  orderNumber?: string;
  dateOfSupply?: string;
  note: string;
  commentImageUrl?: string;
  templateSetting: TemplateTypes.TemplateSettings;
  templateVersionNumber: number;
  merchant: InvoiceMerchant;
  merchantLocation: InvoiceMerchantLocation;
  customer?: InvoiceCustomer;
  lineItems: InvoiceLineItem[];
  payments?: InvoicePayment[];
  summary: InvoiceSummary;
  customFields?: InvoiceCustomField[];
  type: ReceiptType;
  isOffline?: boolean;
  invoiceType: InvoiceType;
  rewaaPayReceiptId?: string;
  rewaaPayReceiptUrl?: string;
  qrCode?: string;
  [key: string]: any;
  currencySymbol?: string;
}
