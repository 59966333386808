import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first, map, switchMap } from 'rxjs';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import {
  mapAcceptV1ToV2,
  mapAllTransferStockToInvoices,
  mapCreateMultipleTransferStock,
  mapCreateRequestMultipleTransferStock,
  mapRejectV1ToV2,
  mapTransferStockToInvoice,
  mapUpdateTransferStock,
} from '../../../shared/utility/transfer-stock-mappers';
import { TransferStockStatus } from '../../../shared/types/transfer-stock-status';
import { Invoice } from '../../../shared/model/invoice/Invoice';
import { TransferStockV2 } from '../../../shared/types/TransferStock';
import { IInvoiceSearchFilterV2 } from '../../../shared/model/invoice/invoice-search-and-filter';

const API_URL = '/api';

@Injectable()
export class TransferStockService {
  constructor(
    private http: HttpClient,
    private readonly featureFlag: FeatureFlagService,
  ) {}

  createTransferStock(
    transferStock: any,
    isNullAllowed: boolean,
    draftId?: number,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapCreateMultipleTransferStock(
              transferStock,
              draftId
                ? TransferStockStatus.Draft
                : TransferStockStatus.Requested,
              isNullAllowed,
            );
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock`,
              mapped,
            );
          }
          return this.http.post<any>(
            `${API_URL}/invoice/stock-transfer/create`,
            {
              ...transferStock,
              draftId,
            },
          );
        }),
      );
  }

  createDraftTransferStock(
    transferStock: any,
    isNullAllowed: boolean,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapCreateMultipleTransferStock(
              transferStock,
              TransferStockStatus.Draft,
              isNullAllowed,
            );
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock`,
              mapped,
            );
          }
          return this.http.post<any>(
            `${API_URL}/invoice/stock-transfer/draft`,
            transferStock,
          );
        }),
      );
  }

  updateDraftTransferStock(
    transferStock: any,
    invoiceId: number,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            return this.http.patch<any>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}`,
              transferStock,
            );
          }
        }),
      );
  }

  createMultipleTransferStock(
    transactions: any[],
    isNullAllowed: boolean,
    draftId?: number,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            if (draftId) {
              const mappedDraft = mapUpdateTransferStock(
                transactions,
                isNullAllowed,
              );
              return this.updateDraftTransferStock(mappedDraft, draftId);
            }
            const mappedCompleted = mapCreateMultipleTransferStock(
              transactions,
              TransferStockStatus.Pending,
              isNullAllowed,
            );
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock`,
              mappedCompleted,
            );
          }
          return this.http.post<any>(
            `${API_URL}/invoice/stock-transfer/create-multiple`,
            { Transactions: transactions, draftId },
          );
        }),
      );
  }

  createMultipleTransferStockDraft(
    transactions: any[],
    isNullAllowed: boolean,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mappedCompleted = mapCreateMultipleTransferStock(
              transactions,
              TransferStockStatus.Draft,
              isNullAllowed,
            );
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock`,
              mappedCompleted,
            );
          }
          return this.http.post<any>(
            `${API_URL}/invoice/stock-transfer/create-multiple-draft`,
            { Transactions: transactions },
          );
        }),
      );
  }

  getTransferStockList(
    filtersData: IInvoiceSearchFilterV2,
  ): Observable<{ result: Invoice[]; total: number }> {
    return this.http
      .get<{ result: TransferStockV2[]; total: number }>(
        `${API_URL}/stock-control/transfer-stock`,
        {
          params: { ...filtersData },
        },
      )
      .pipe(
        map((response) => ({
          result: mapAllTransferStockToInvoices(response.result),
          total: response.total,
        })),
      );
  }

  createMultipleTransferRequest(
    transactions: any[],
    isNullAllowed: boolean,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapCreateRequestMultipleTransferStock(
              transactions,
              isNullAllowed,
            );
            return this.http.post<TransferStockV2>(
              `${API_URL}/stock-control/transfer-stock/request`,
              mapped,
            );
          }
          return this.http.post<any>(
            `${API_URL}/invoice/stock-transfer/create-multiple-request`,
            { Transactions: transactions },
          );
        }),
        first(),
      );
  }

  getAllTransferStocks(): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            return this.http.get<any>(
              `${API_URL}/stock-control/transfer-stock`,
            );
          }
          return this.http.get<any>(`${API_URL}/invoice/stock-transfer`);
        }),
        first(),
      );
  }

  getTransferStockById(invoiceId: number): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            return this.http
              .get<TransferStockV2>(
                `${API_URL}/stock-control/transfer-stock/${invoiceId}`,
              )
              .pipe(map((response) => mapTransferStockToInvoice(response)));
          }
          return this.http.get<any>(
            `${API_URL}/invoice/stock-transfer/${invoiceId}`,
          );
        }),
        first(),
      );
  }

  // add params
  acceptTransferStock(invoiceId): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}/accept`,
              { params: { invoiceId } },
            );
          }
          return this.http.get<any>(
            `${API_URL}/invoice/stock-transfer/accept/${invoiceId}`,
          );
        }),
      );
  }

  acceptMultipleTransferStock(
    invoiceId: number,
    variantToInvoices: any,
    skipValidation?: boolean,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapAcceptV1ToV2(variantToInvoices, skipValidation);
            return this.http.post<{ success: boolean }>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}/accept`,
              mapped,
            );
          }
          return this.http.put<any>(
            `${API_URL}/invoice/stock-transfer/accept/${invoiceId}`,
            variantToInvoices,
          );
        }),
        first(),
      );
  }

  cancelTransferStock(invoiceId, requestBody: any): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapRejectV1ToV2(requestBody);
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}/reject`,
              mapped,
            );
          }
          return this.http.put<any>(
            `${API_URL}/invoice/stock-transfer/reject/${invoiceId}`,
            requestBody,
          );
        }),
        first(),
      );
  }

  getTransferStockUsers(): Observable<any> {
    return this.http.get<any>(`${API_URL}/invoice/stock-transfer/user-names`);
  }

  // add params
  rejectRequest(invoiceId): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = { rejectReason: 'Request Rejected' };
            return this.http.post<any>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}/reject`,
              mapped,
            );
          }
          return this.http.put<any>(
            `${API_URL}/invoice/stock-transfer/reject-request/${invoiceId}`,
            {},
          );
        }),
        first(),
      );
  }

  completeRequest(
    invoiceId: number,
    transactions: any,
    isNullAllowed: boolean,
  ): Observable<any> {
    return this.featureFlag
      .isEnabled(FeatureFlagEnum.TransferStockV2, false)
      .pipe(
        switchMap((isTransferStockServiceEnabled) => {
          if (isTransferStockServiceEnabled) {
            const mapped = mapUpdateTransferStock(transactions, isNullAllowed);
            return this.http.patch<{ success: boolean }>(
              `${API_URL}/stock-control/transfer-stock/${invoiceId}`,
              mapped,
            );
          }
          return this.http.put<any>(
            `${API_URL}/invoice/stock-transfer/accept-request/${invoiceId}`,
            { Transactions: transactions },
          );
        }),
        first(),
      );
  }

  getValidatedFileData(rows: any[]): Observable<any> {
    return this.http.post<any>(
      `${API_URL}/invoice/stock-transfer/validate-uploaded-rows`,
      { productRows: rows },
    );
  }

  acceptBulkTransferStock(invoices) {
    return this.http.put<any>(`${API_URL}/invoice/stock-transfer/accept-bulk`, {
      bulkInvoices: invoices,
    });
  }

  rejectBulkTransferStock(ids, reason): Observable<any> {
    return this.http.put<any>(`${API_URL}/invoice/stock-transfer/reject-bulk`, {
      ids,
      reason,
    });
  }

  exportAllTransferStocks(input: {
    queryParams: IInvoiceSearchFilterV2;
    lang: string;
  }): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${API_URL}/stock-control/transfer-stock/export`,
      input,
    );
  }

  getExportFile(id: number): Observable<{ fileName: string; fileUrl: string }> {
    return this.http.get<{ fileName: string; fileUrl: string }>(
      `${API_URL}/stock-control/transfer-stock/export/${id}`,
    );
  }

  acceptAllTransferStocks(payload: {
    ids: number[];
    skipValidation?: boolean;
  }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${API_URL}/stock-control/transfer-stock/acceptAll`,
      payload,
    );
  }

  rejectAllTransferStocks(payload: {
    ids: number[];
    rejectReason: string;
  }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${API_URL}/stock-control/transfer-stock/rejectAll`,
      payload,
    );
  }
}
