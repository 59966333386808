import { InvoiceSnapshotTypes, TemplateTypes } from '@rewaa-team/types';
import { CommonEnums, InvoiceEnums, ZatcaApiTypes } from '..';
import { DiscountType } from '../cart/enums';
import {
  Cart,
  LineItemTax,
  SellApiCart,
  SellApiLineItem,
  TaxSummary,
} from '../cart/types';
import { AmountType, Source } from '../common/enums';
import { InvoiceSnapshot } from '../invoice-snapshot/types';
import { PromotionGroup, PromotionType } from '../promotions/types';
import {
  AutoCompleteKey,
  InvoicePaymentStatus,
  InvoiceSellTaxStatus,
  InvoiceStatus,
  InvoiceType,
  InvoiceVariantType,
  InvoiceZatcaStatus,
  PaymentStatus,
  PaymentType,
  Platform,
  PriceMode,
} from './enums';

export interface CustomFieldData {
  id?: number;
  customFieldId: number;
  referenceId: number;
  module: string;
  value: string;
  customFieldJSON?: JSON & {
    name: string;
    addToReceiptOption?: boolean;
  };
}

export interface IOfflineInvoiceData {
  orderNumber?: number;
  invoiceNumber?: string;
  offlineFirst?: boolean;
  isOffline?: boolean;
}

export interface Customer {
  id: number;
  code: string;
  name: string;
  mobileNumber: string;
  vatNumber?: string;
  nationalId?: string;
  commercialRegisterNumber?: string;
  address?: string;
  CustomFieldsData?: InvoiceSnapshotTypes.InvoiceCustomField[];
  totalPaid: number;
  debitAmount: number;
}

export interface Salesman {
  id: number;
  name: string;
}

export interface StockLocation {
  id: number;
  streetName: string;
  district: string;
  additionalNumber: number;
  buildingNumber: number;
  postalCode: string;
  crn: string;
  city: string;
}

export interface InvoiceCreationPayload {
  completionDate?: string;
  customFieldsData?: CustomFieldData[];
  notes?: string;
  paymentDueDate?: string;
  dateOfSupply?: string;
  payments: InvoicePayment[];
  change?: number;
  receivedAmount?: number;
  cart: SellApiCart;
  registerId?: number;
  registerName?: string;
  shiftId?: number;
  stockLocationId: number;
  stockLocation?: StockLocation;
  salesman?: Salesman;
  customer?: Customer;
  sourceId?: number;
  platform?: Platform;
  source: Source;
  type: InvoiceType;
  offlineData?: IOfflineInvoiceData;
  status?: InvoiceStatus;
  templateSettings: TemplateTypes.TemplateSettings;
  snapshot?: InvoiceSnapshotTypes.InvoiceSnapshot;
  deviceIdentifier?: string;
  otherValues?: ExtraInvoiceData;
  currencySymbol?: string;
}

export interface ExtraInvoiceData {
  shippingFees: number;
  otherFees: number;
  shippingDiscount: number;
  orderCreatedAt: Date;
  promotionName: string;
  couponName: string;
  feeDiscount: number;
}

export interface InvoiceReturnPayload {
  sellInvoiceId: number;
  sellInvoiceNumber: string;
  customFieldsData?: CustomFieldData[];
  notes?: string;
  payments: InvoicePayment[];
  cart: Cart;
  registerId?: number;
  registerName?: string;
  shiftId: number;
  stockLocationId: number;
  salesman?: Salesman;
  customer?: Customer;
  sourceId?: number;
  platform?: Platform;
  source: Source;
  type: InvoiceType;
  status?: InvoiceStatus;
  templateSettings: TemplateTypes.TemplateSettings;
  snapshot?: InvoiceSnapshotTypes.InvoiceSnapshot;
  promotionGroupIds?: number[];
  orderNumber?: number;
  currencySymbol?: string;
}

export interface InvoiceListParams {
  source?: CommonEnums.Source;
  fromDate?: Date;
  toDate?: Date;
  status?: InvoiceEnums.InvoiceStatus;
  paymentIds?: number[];
  locationIds?: number[];
  customer?: string;
  type?: InvoiceEnums.InvoiceTransactionType;
  serialNumber?: string;
  offset?: number;
  limit?: number;
  variantName?: string;
  variantSku?: string;
  withCustomer?: boolean;
  invoiceNumber?: string;
}

export interface InvoiceSnapshotParams {
  invoiceId?: number;
  invoiceNumber?: string;
}

export interface InvoiceListOutput {
  id: number;
  tenantId: number;
  stockLocationId: number;
  source: CommonEnums.Source;
  sourceId?: number;
  status: InvoiceEnums.InvoiceStatus;
  total: number;
  completionDate?: Date;
  invoiceNumber: string;
  isReturn: boolean;
  zatcaStatus?: InvoiceEnums.InvoiceZatcaStatus;
  createdAt: Date;
  customerName?: string;
  customerId?: number;
  type?: InvoiceEnums.InvoiceType;
  paidAmount: number;
  paymentMethod: string;
  rewaaPay?: {
    receiptId?: string;
    receiptUrl?: string;
  };
}

export type ZatcaMessageCategory =
  | 'INVOICE_ERRORS' // invoice hash or invoice missing
  | 'XSD_SCHEMA_ERROR' // XML UBL 2.1 standards
  | 'EN_KSA_ERRORS' // XML rules error
  | 'Authentication-Errors' // Certificate unauthorized
  | 'HTTP-Errors' // 500
  | 'QR_CODE_ERROR';

export type ZatcaMessageType = 'INFO' | 'ERROR' | 'WARNING';

export interface ZatcaMessage {
  type: ZatcaMessageType;
  code: string;
  status: string;
  category: ZatcaMessageCategory;
  message: string;
}

export interface ZatcaValidationResult {
  infoMessages: ZatcaMessage[];
  warningMessages: ZatcaMessage[];
  errorMessages: ZatcaMessage[];
  status: string;
}

export interface InvoiceCreationOutput {
  invoiceSnapshot?: InvoiceSnapshotTypes.InvoiceSnapshot;
  verificationResponse?: ZatcaApiTypes.IValidationResult;
  zatcaStatus?: InvoiceZatcaStatus;
  transactionId?: string;
}

export interface GetInvoiceNumberInput {
  completionDate: Date;
  stockLocationId: number;
  registerId?: number;
  isReturn?: boolean;
  isOffline?: boolean;
  isPaymentReceipt?: boolean;
  invoiceNumber: number;
  timezone?: string;
}

export interface GetPaymentNumberInput {
  completionDate: Date;
  stockLocationId?: number;
  registerId?: number;
  isReturn?: boolean;
  isOffline?: boolean;
  paymentNumber: number;
  timezone?: string;
}

export interface CreateInvoiceSnapshotOptions {
  invoiceNumber: string;
  orderNumber?: number;
  invoiceId?: number;
  isOffline?: boolean;
  isReturn?: boolean;
  timezone?: string;
  merchant: InvoiceSnapshotTypes.InvoiceMerchant;
  merchantLocation: InvoiceSnapshotTypes.InvoiceMerchantLocation;
  payments: InvoiceSnapshotTypes.InvoicePayment[];
  change?: number;
  receivedAmount?: number;
  saleInvoiceNumber?: string;
  templateVersionNumber: number;
  userId: number;
  username: string;
  dateOfSupply?: string;
  customerDebitAmount?: number;
}

export interface GetNextSequences {
  invoiceNumber: number;
  paymentNumber: number;
}

export interface SyncInvoiceNumbersPayload {
  invoiceNumbers: string[];
  shiftId: number;
}

export type InvoiceVariantMeta = Partial<
  Pick<
    SellApiLineItem,
    | 'productId'
    | 'type'
    | 'trackDetails'
    | 'trackType'
    | 'unit'
    | 'composites'
    | 'extras'
    | 'packs'
    | 'productType'
    | 'eCards'
    | 'promotionDetails'
    | 'categoryIds'
    | 'availableQuantity'
  >
>;

export interface InvoiceVariantDiscount {
  id: number;
  invoiceId: number;
  invoice?: Invoice;
  invoiceVariantId: number;
  invoiceVariant?: InvoiceVariant;
  discountReferenceId?: number;
  name: string;
  rate: number;
  amountType: AmountType;
  type: DiscountType;
  unitAmount: number;
  total: number;
  totalWithTax: number;
  meta?: InvoiceVariantDiscountMeta;
}

export type InvoiceVariantDiscountMeta = {
  promotion?: {
    type: PromotionType;
  };
};

export interface InvoiceVariant {
  id: number;
  invoiceId: number;
  invoice?: Invoice;
  variantId: number;
  quantity: number;
  promotionQuantity: number;
  serialNumber: number;
  total: number;
  subtotal: number;
  tax: number;
  sellPrice: number;
  originalPrice: number;
  unitExtrasTotal: number;
  cost: number;
  taxConfig?: LineItemTax;
  meta?: InvoiceVariantMeta;
  type: InvoiceVariantType;
  discounts?: InvoiceVariantDiscount[];
  sku: string;
  name: string;
  tenantId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  lineItemId?: string;
  manageStockLevel: boolean;
}

export interface InvoicePayment {
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  tenantId?: number;
  id?: number;
  invoiceId?: number;
  paymentMethodId: number;
  amount: number;
  paymentNumber?: string;
  registerId?: number;
  shiftId?: number;
  stockLocationId?: number;
  transactionId?: string;
  receiptId?: string;
  softPosTerminalId?: string;
  softPosTransactionId?: string;
  paymentMethodName?: string;
  softPosMeta?: unknown;
  status: InvoicePaymentStatus;
  type: PaymentType;
  paymentMethodType: string;
}

export class ZatcaLog {
  response: {
    data: {
      validationResults: {
        errorMessages?: ZatcaMessage[];
      };
    };
  };
}

export interface Invoice {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  id: number;
  sourceId?: number;
  total: number;
  subtotal: number;
  generalDiscountType: AmountType;
  generalDiscount: number;
  generalDiscountRate: number;
  tax: number;
  isOffline: boolean;
  isOfflineFirst: boolean;
  isReturn: boolean;
  status: InvoiceStatus;
  source: Source;
  zatcaStatus: InvoiceZatcaStatus;
  cartId: string;
  customerId?: number;
  saleInvoiceId?: number;
  orderNumber: number;
  invoiceNumber: string;
  type: InvoiceType;
  registerId: number;
  shiftId: number;
  stockLocationId: number;
  salesmanId: number;
  paymentStatus: PaymentStatus;
  completionDate: Date;
  paymentCompletionDate: Date;
  platform: Platform;
  sellTaxStatus: InvoiceSellTaxStatus;
  notes: string;
  promotionGroups?: PromotionGroup[];
  taxSummary?: TaxSummary;
  sequenceNumber: number;
  invoiceHash?: string;
  invoiceXML?: string;
  saleType: PriceMode;
  userId: number;
  invoiceSnapshots: InvoiceSnapshot[];
  invoiceVariants: InvoiceVariant[];
  invoicePayments: InvoicePayment[];
  customFieldsData: CustomFieldData[];
  customer?: Customer;
  meta?: InvoiceMeta;
  referenceId?: string;
  zatcaLogs: ZatcaLog[];
}

export interface InvoiceMeta {
  zatcaEnabled?: boolean;
  salesmanName?: string;
  userName?: string;
  promotionGroupIds?: number[];
  paymentDueDate?: string;
  dateOfSupply?: string;
  integrationV1Invoice?: boolean;
}

export interface NextOrderNumberResponse {
  orderNumber: number | null;
}

export interface getPaymentReceiptInvoiceResult {
  snapshot?: InvoiceSnapshotTypes.InvoiceSnapshot;
  logoUrl: string;
}

export interface GetPaymentReceiptInvoiceResult {
  snapshot?: InvoiceSnapshotTypes.InvoiceSnapshot;
  logoUrl: string;
}

export type AutoCompleteSuggestion = {
  id: number;
} & {
  [K in AutoCompleteKey]: string;
};

export type ZatcaApprovedInvoicesCount = {
  count: number;
};
