import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationToastMessage } from '../model/NotificationToastMessage';
import { CustomToastService } from '../services/custom-toast.service';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(
    public translate: TranslateService,
    private customToast: CustomToastService,
  ) {}

  private responseMessageToaster(
    responseMessage: NotificationToastMessage,
    statusCode: number,
  ) {
    if (responseMessage.status.toLowerCase() === 'success') {
      if (responseMessage.message === 'Variant Updated Successfully') {
        return;
      }
      this.customToast.success(this.translate.instant(responseMessage.message));
    } else if (responseMessage.status.toLowerCase() === 'warning') {
      this.customToast.warning(this.translate.instant(responseMessage.message));
    } else if (responseMessage.status.toLowerCase() === 'error') {
      if (statusCode < 500) {
        this.customToast.error(this.translate.instant(responseMessage.message));
      }
    } else {
      this.customToast.info(this.translate.instant(responseMessage.message));
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((evt) => {
        if (evt instanceof HttpResponse && evt.body) {
          let { responseMessage } = evt.body;
          if (
            evt?.url.includes('enigma') ||
            evt?.url.includes('inventory-service')
          ) {
            responseMessage = evt.body?.data?.responseMessage;
          }
          const statusCode = evt.status;
          if (responseMessage) {
            this.responseMessageToaster(responseMessage, statusCode);
            const { body } = evt;
            return evt.clone({ body });
          }
          return evt;
        }
        return evt;
      }),
    );
  }
}
