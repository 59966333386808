import { Tax } from './Tax';
import { StockLocation } from './StockLocation';
import { VariantToTrack } from './VariantToTrack';

export class ProductVariantStockLocation {
  id?: number;

  stockLocationId?: number;

  variantID?: number;

  quantity?: number;

  initialQuantity?: number;

  locationName?: string;

  buyPrice = 0;

  initialCost?: number = 0;

  cost?: number = 0;

  name?: string;

  sku?:string;

  wholeSalePrice: number = 0;

  retailPrice: number = 0;

  Tax?: Tax;

  StockLocation?: StockLocation;

  taxId?: number;

  packageStockId?: number;

  VariantToTracks?: VariantToTrack[];

  SelectedVariantToTracks?: VariantToTrack[];

  productVariantId?:number;
}
