/* eslint-disable @typescript-eslint/naming-convention */
import { Component, SecurityContext, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { environment } from '../../../../environments/environment';
import { RegexEnum } from '../../../shared/model/enum/RegexPattern';
import { UserType } from '../../../shared/types/auth.types';

export enum OnboardingScreens {
  SignUp = 'signUp',
  Password = 'password',
  VerifyOtp = 'VerifyOtp',
  Preparing = 'preparingAccount',
}

@Component({
  selector: 'rw-register-v2',
  templateUrl: './register-v2.component.html',
  styleUrls: ['./register-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterV2Component {
  OnboardingScreens = OnboardingScreens;

  activeIndex = 0;

  readonly currentScreens = [
    OnboardingScreens.SignUp,
    OnboardingScreens.Password,
    OnboardingScreens.VerifyOtp,
    OnboardingScreens.Preparing,
  ];

  signUpForm: UntypedFormGroup;

  passwordForm: UntypedFormGroup;

  changeLang: string;

  lang: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public translate: TranslateService,
    protected activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }
    this.createForm();
    this.checkForVerifyOtpFlow();
    this.checkForMarketingLandingPageFlow();
  }

  get email(): AbstractControl | null {
    return this.signUpForm.get('email');
  }

  get phone(): AbstractControl | null {
    return this.signUpForm.get('phone');
  }

  get selectedCountry(): AbstractControl | null {
    return this.signUpForm.get('selectedCountry');
  }

  private getRegisterDataFromLocalStorage(): object {
    const registerData = this.localStorageService.getItem<any>(
      LocalStorageKey.PreviousRegisterData,
    );

    if (!registerData) {
      return null;
    }

    if (new Date().getTime() > registerData.expiryTime) {
      this.localStorageService.removeItem(LocalStorageKey.PreviousRegisterData);
      return null;
    }

    delete registerData.expiryTime;

    return registerData;
  }

  checkForVerifyOtpFlow(): void {
    const { queryParams } = this.activatedRoute.snapshot;
    const requiredParams = ['email', 'type'];
    if (
      requiredParams.some((param) => !Object.keys(queryParams).includes(param))
    ) {
      return;
    }

    let registerData = this.router.getCurrentNavigation()?.extras?.state;
    if (!registerData) {
      registerData = this.getRegisterDataFromLocalStorage();
      if (!registerData) {
        this.router.navigate([], { replaceUrl: true }).then(() => {
          window.location.reload();
        });
      }
    }

    let type = this.sanitizer.sanitize(SecurityContext.HTML, queryParams.type);
    let countryCode = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.cc,
    );
    let phone = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.phone,
    );

    if (type === UserType.GccWithoutPh && phone.startsWith('+966')) {
      countryCode = '966';
      phone = phone.slice(4);
      type = UserType.Gcc;
    }

    this.signUpForm.patchValue({
      email: this.sanitizer.sanitize(SecurityContext.HTML, queryParams.email),
      selectedCountry: {
        countryKey: type === UserType.Gcc ? 'SA' : '',
        code: countryCode,
      },
      phone,
      companyName: registerData.companyName,
      licenseKey: registerData.licenseKey,
    });

    this.passwordForm.patchValue({
      password: registerData.password,
    });

    this.goToPage(OnboardingScreens.VerifyOtp);
  }

  checkForMarketingLandingPageFlow(): void {
    const { queryParams } = this.activatedRoute.snapshot;
    const requiredParams = ['company', 'phone', 'email', 'cc'];
    if (
      requiredParams.some((param) => !Object.keys(queryParams).includes(param))
    ) {
      return;
    }

    const companyName = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.company,
    );
    const email = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.email,
    );
    const phone = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.phone,
    );
    const countryCode = this.sanitizer.sanitize(
      SecurityContext.HTML,
      queryParams.cc,
    );

    this.signUpForm.patchValue({
      email,
      selectedCountry: {
        countryKey: countryCode === '966' ? 'SA' : '',
        code: countryCode,
      },
      phone,
      companyName,
    });

    this.goToPage(OnboardingScreens.Password);
  }

  private goToPage(page: OnboardingScreens): void {
    this.activeIndex = this.currentScreens.indexOf(page);
  }

  createForm(): void {
    const emailRegex = environment.allowAliasedEmails
      ? RegexEnum.laxEmail
      : RegexEnum.email;

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      companyName: ['', [Validators.required]],
      phone: [''],
      utmParams: [''],
      referralCode: ['', { updateOn: 'blur' }],
      licenseKey: ['', [Validators.minLength(20), Validators.maxLength(20)]],
      isTermsAndPrivacyAccepted: [true, [Validators.required]],
      selectedCountry: [null],
    });

    this.passwordForm = this.formBuilder.group({
      password: [''],
      showPassword: [''],
    });
  }

  get activeScreen(): OnboardingScreens {
    return this.currentScreens[this.activeIndex];
  }

  handleSignUpFormEvent(signUpFormEvent: UntypedFormGroup): void {
    this.signUpForm = signUpFormEvent;
  }

  nextPage(): void {
    this.activeIndex += 1;
  }

  prevPage(): void {
    this.activeIndex -= 1;
  }
}
