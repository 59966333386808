import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LocalStorageService } from '../../core/services/local-storage.service';
import {
  APP_NAMES,
  APP_PERMISSION_PREFIXES,
  CommonPolicies,
  LocalStorageKey,
  PermissionModules,
  TrialState,
  ZatcaAppPermissions,
} from '../constants';
import {
  appNamesToPrefixes,
  legacyPermissionstoNewPermissions,
} from '../constants/permissions.constants';
import {
  LocationFields,
  VariantLocationFields,
} from '../../inventory/products/components/create/product/simple-product/types';
import { SettingsService } from '../../users-settings/services/settings.service';
import { ApplicationsHelperService } from './applications.helper.service';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';
import { UserService } from '../../auth/services/user.service';
import { ConfigurationName } from '../../configuration/configuration.service';
import { AdvancedAccountingService } from '../../advanced-accounting/advanced-accounting.service';

export interface PermissionDTO {
  id: number;
  permission: string;
}

export interface Field<T> {
  id: T;
  name: string;
  disabled: boolean;
  pendo: string;
}

export enum CustomerPermissions {
  CustomerDetailDelete = 'order-management:customer-detail:delete',
  CustomerDetailUpdate = 'order-management:customer-detail:update',
}

@Injectable()
export class PermissionHelperServiceV2 {
  private permissionsSubject = new BehaviorSubject<PermissionDTO[] | null>(
    null,
  );

  public permissions$ = this.permissionsSubject.asObservable();

  readonly dashboardPermissions: string[] = [
    'dashboard::',
    'dashboard::search',
    'dashboard:activity-logs:read',
    'dashboard:activity-logs:search',
    'dashboard:amount-collected-v2:read',
    'dashboard:amount-collected:read',
    'dashboard:average-items-per-sale:read',
    'dashboard:average-sales:read',
    'dashboard:gross-profit:read',
    'dashboard:inventory-value-v2:read',
    'dashboard:inventory-value:read',
    'dashboard:latest-orders-and-sales:read',
    'dashboard:logs:read',
    'dashboard:notifications:read',
    'dashboard:register-chart:read',
    'dashboard:revenue:read',
    'dashboard:sales-by-branch:read',
    'dashboard:sales-by-category:read',
    'dashboard:sales-by-day:read',
    'dashboard:sales-by-product:read',
    'dashboard:sales-by-salesman:read',
    'dashboard:sales-target:read',
    'dashboard:sales-transaction:read',
    'dashboard:top-5-cards:read',
    'dashboard:total-sales:read',
    'dashboard:transaction:read',
  ];

  readonly dashboardExpensePermissions: string[] = [
    'dashboard:expense:read',
    'dashboard:net-income-v2:read',
    'dashboard:net-income:read',
  ];

  readonly POSPermissionGroups = {
    retailPrice: [
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_RETAIL_PRICE_UPDATE,
    ],
    wholeSalePrice: [
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_WHOLESALE_PRICE_UPDATE,
    ],
    buyPrice: [
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_READ,
      CommonPolicies.PRODUCT_DETAILS_BUY_PRICE_UPDATE,
    ],
    availableQty: [CommonPolicies.PRODUCT_AVAILABLE_QUANTITY_READ],
    initialCost: [CommonPolicies.PRODUCT_DETAILS_AVERAGE_COST_READ],
  };

  readonly externalApps: APP_NAMES[] = [
    APP_NAMES.WOO_COMMERCE,
    APP_NAMES.MAGENTO,
    APP_NAMES.ZID,
    APP_NAMES.SALLA,
    APP_NAMES.ZATCA,
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private settingsService: SettingsService,
    private applicationService: ApplicationsHelperService,
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
    private advanceAccountingService: AdvancedAccountingService,
  ) {}

  public setPermissions(permissions: PermissionDTO[]): void {
    this.permissionsSubject.next(permissions);
  }

  public getUserPermissions(): PermissionDTO[] {
    return this.permissionsSubject.value;
  }

  /**
   * Fetches the user's permissions asynchronously.
   *
   * This function checks if the user's permissions are already fetched and stored.
   * If user permissions are not stored, this function retrieves the permissions asynchronously,
   * updates the local storage, and assigns the permissions to the `userPermissions` property.
   *
   * @returns nothing.
   */
  public async fetchUserPermissions(): Promise<void> {
    if (this.permissionsSubject.value || this.userService.isLoggedOut()) {
      return;
    }
    console.trace(`[Permission] Fetching user permissions`);
    const nucleusPermissions = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.NucleusPermissions),
    );
    const { permissions } = await firstValueFrom(
      this.settingsService.getPermissions(nucleusPermissions),
    );
    if (permissions) {
      this.setPermissions(permissions);
      this.localStorageService.setItem(
        LocalStorageKey.Permissions,
        permissions,
      );
    }
  }

  public userHasSubscription(): boolean {
    const user = this.userService.getCurrentUser();

    return user && !!user.RewaaAccountSubscription;
  }

  /**
   * Checks if the user has at least one of the required permissions.
   * Supports both V1 and V2 permission formats by checking direct matches first,
   * then trying to match legacy V1 permissions by converting them to V2 format.
   *
   * @param requiredPermissions - An array of permission strings in either V1 or V2 format
   * @returns True if at least one required permission is found, otherwise false
   */
  public hasAtLeastOnePermission(requiredPermissions: string[]): boolean {
    const permissions = this.permissionsSubject.value;
    if (!permissions) {
      return false;
    }

    const userPermissions = permissions.map((p) => p.permission);

    const hasDirectMatch = requiredPermissions.some((reqPerm) =>
      userPermissions.includes(reqPerm),
    );

    if (hasDirectMatch) {
      return true;
    }

    const v2Permissions = this.updatePermissionsFromV1toV2(requiredPermissions);
    return v2Permissions.some((reqPerm) => userPermissions.includes(reqPerm));
  }

  public hasAllPermissions(requiredPermissions: string[]): boolean {
    const permissions = this.permissionsSubject.value;
    if (!permissions) {
      return false;
    }

    const userPermissions = permissions.map((p) => p.permission);

    const hasDirectMatch = requiredPermissions.every((reqPerm) =>
      userPermissions.includes(reqPerm),
    );

    if (hasDirectMatch) {
      return true;
    }

    const v2Permissions = this.updatePermissionsFromV1toV2(requiredPermissions);
    return v2Permissions.every((reqPerm) => userPermissions.includes(reqPerm));
  }

  public isPermissionExist(permission: string): boolean {
    return this.hasAtLeastOnePermission([permission]);
  }

  /**
   * Checks if the user has the specified permission for the given app.
   *
   * This function constructs the required permission string by combining
   * the app's prefix (determined by the app name) with the provided permission suffix.
   * It then asynchronously checks if the user has at least one of the specified permissions.
   *
   * @param appName - The name of the app, which determines the permission prefix.
   * @param permissionSuffix - The suffix of the permission to check for.
   * @returns A promise that resolves to true if the user has the specified permission,
   *          otherwise false.
   */
  public doesAppPermissionExist(
    appName: APP_NAMES,
    permissionSuffix: string,
  ): boolean {
    const appPrefix = appNamesToPrefixes[appName.toLowerCase()];
    if (!appPrefix) return false;

    const v2Permission = `${appPrefix}:${permissionSuffix}`;
    if (this.hasAtLeastOnePermission([v2Permission])) {
      return true;
    }

    const v1Prefix = APP_PERMISSION_PREFIXES.find(
      (a) => a.name.toLowerCase() === appName.toLowerCase(),
    );

    const v1Permission = `${v1Prefix ? v1Prefix.prefix : ''}.${permissionSuffix}`;

    return this.hasAtLeastOnePermission([v1Permission]);
  }

  public filterAvailableFields<
    T extends VariantLocationFields | LocationFields,
  >(fields: Field<T>[]): Field<T>[] {
    const [
      hasRetailPricePermission,
      hasWholeSalePricePermission,
      hasBuyPricePermission,
      hasInitialCostPermission,
      hasAvailableQtyPermission,
    ] = [
      this.hasAtLeastOnePermission(this.POSPermissionGroups.retailPrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.wholeSalePrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.buyPrice),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.initialCost),
      this.hasAtLeastOnePermission(this.POSPermissionGroups.availableQty),
    ];

    const fieldToAvailabilityMap = {
      [VariantLocationFields.retailPrice]: hasRetailPricePermission,
      [VariantLocationFields.wholeSalePrice]: hasWholeSalePricePermission,
      [VariantLocationFields.buyPrice]: hasBuyPricePermission,
      [VariantLocationFields.initialCost]: hasInitialCostPermission,
      [LocationFields.QTY]: hasAvailableQtyPermission,
      [LocationFields.RETAIL_PRICE]: hasRetailPricePermission,
      [LocationFields.WHOLE_SALE_PRICE]: hasWholeSalePricePermission,
      [LocationFields.BUY_PRICE]: hasBuyPricePermission,
    };

    return fields.filter((field) => {
      const isAvailable = fieldToAvailabilityMap[field.id as string];
      return isAvailable ?? true;
    });
  }

  /**
   * Retrieves the dashboard permissions.
   *
   * This function combines the default dashboard permissions with additional permissions
   * specific to the expense feature, if it is installed.
   *
   * @param isExpenseInstalled - A boolean indicating if the expense feature is installed. Defaults to false.
   * @returns An array of dashboard permissions as strings.
   */
  public getDashboardPermissions = (isExpenseInstalled = false): string[] => [
    ...this.dashboardPermissions,
    ...(isExpenseInstalled ? this.dashboardExpensePermissions : []),
  ];

  /**
   * Retrieves the application read permissions with a specified app's prefix.
   *
   * This function combines default dashboard permissions and common application permissions,
   * and adds the specified app prefix to each permission. If the expense feature is installed,
   * additional expense-related permissions are included in the dashboard permissions.
   *
   * @param appPrefix - A string to be prefixed to each permission in the format: "prefix:permission".
   * @param isExpenseInstalled - A boolean indicating if the expense feature is installed. Defaults to false.
   * @returns An array of permissions as strings, each prefixed with the provided prefix.
   */
  getAppReadPermissionsLists = (
    appPrefix: string,
    isExpenseInstalled = false,
  ): Array<string> => {
    const dashboardPermissions =
      this.getDashboardPermissions(isExpenseInstalled);

    const commonAppPermissions = [
      'dashboard:read',
      'product-management:read',
      'setting:read',
      'error-list:read',
    ];

    const allPermissions = [...dashboardPermissions, ...commonAppPermissions];

    return allPermissions.map((permission) => `${appPrefix}:${permission}`);
  };

  /**
   * Checks whether at least one read permission exists for a subscribed app.
   * Uses synchronously loaded permissions and subscribed apps to determine access.
   *
   * @returns True if user has at least one subscribed app read permission, otherwise false.
   */
  public checkAtLeastOneAppReadPermission(): boolean {
    const subscribedExtApps =
      this.applicationService
        .getSubscribedAppsSync()
        ?.filter((subscribedApp) =>
          this.externalApps.some(
            (app) => app.toLowerCase() === subscribedApp.name?.toLowerCase(),
          ),
        ) || [];

    if (subscribedExtApps.length === 0) return false;

    return subscribedExtApps.some((app) => {
      const appPrefix = appNamesToPrefixes[app.name.toLowerCase()];
      const allAppRead = this.getAppReadPermissionsLists(appPrefix);
      return this.hasAtLeastOnePermission(allAppRead);
    });
  }

  /**
   * Maps legacy (V1) permissions to their V2 format using the legacy permissions mapping.
   * If a permission doesn't have a V2 mapping, it is returned as-is.
   *
   * @param permissions - Array of permission strings in V1 format
   * @returns Array of permission strings mapped to V2 format where applicable
   */
  public updatePermissionsFromV1toV2(permissions: string[]): string[] {
    const getUpdatedPermission = (permission: string): string =>
      legacyPermissionstoNewPermissions[permission] || permission;

    if (permissions && permissions.length > 0) {
      return permissions.map((permission) => getUpdatedPermission(permission));
    }
    return [];
  }

  private isAppInstalled(appName: APP_NAMES): boolean {
    const subscribedApps = this.applicationService.getSubscribedAppsSync();
    return (
      subscribedApps?.some(
        (app) => app.name.toLowerCase() === appName.toLowerCase(),
      ) || false
    );
  }

  public isAdvAccountingAppInstalled(): boolean {
    return this.isAppInstalled(APP_NAMES.ACCOUNTING);
  }

  public isAccountingAppInstalled(): boolean {
    return this.isAppInstalled(APP_NAMES.EXPENSES);
  }

  public isPromotionAppInstalled(): boolean {
    return this.isAppInstalled(APP_NAMES.PROMOTION_MANAGMENET);
  }

  public isPosAppInstalled(): boolean {
    return this.isAppInstalled(APP_NAMES.INTERNAL_POS);
  }

  /**
   * Gets the Zatca permissions status for the current user
   *
   * @returns An object with boolean flags for each Zatca permission
   */
  public getZatcaPermissions(): { [key in ZatcaAppPermissions]: boolean } {
    const permissions = {
      [ZatcaAppPermissions.ZatcaDashboardRead]: false,
      [ZatcaAppPermissions.ZatcaDashboardUpdate]: false,
      [ZatcaAppPermissions.ZatcaErrorListRead]: false,
      [ZatcaAppPermissions.ZatcaSettingsRead]: false,
    };

    Object.keys(permissions).forEach((permissionPath) => {
      permissions[permissionPath] = this.isPermissionExist(permissionPath);
    });

    return permissions;
  }

  /**
   * Checks the installation status and trial state of the accounting app.
   *
   * @param subscribedApps - Array of app subscriptions to check
   * @returns Object with installation and trial status information
   */
  public async checkAccountingAppInstallationAndStatus(): Promise<{
    isAccountingAppInstalled: boolean;
    isAccountingAppTrial: boolean;
    isTrialNotStarted: boolean;
    isAccountingTrialEnd: boolean;
  }> {
    const subscribedApps = this.applicationService.getSubscribedAppsSync();
    const accountingApp = subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );
    const res = {
      isAccountingAppInstalled: false,
      isAccountingAppTrial: false,
      isTrialNotStarted: false,
      isAccountingTrialEnd: false,
    };
    if (accountingApp) {
      res.isAccountingAppInstalled = true;
      if (accountingApp.trialStatus !== TrialState.PAID) {
        res.isAccountingAppTrial = true;
        const configuration = await firstValueFrom(
          this.advanceAccountingService.getSetting(ConfigurationName.SETUP),
        );
        res.isTrialNotStarted =
          !configuration || !configuration.value?.bankAndOpeningBalance;

        if (accountingApp.trialStatus === TrialState.EXPIRED) {
          res.isAccountingTrialEnd = true;
        }
      }
    }
    return res;
  }

  /**
   * Gets all permissions that start with the specified module name
   *
   * @param moduleName - The module name prefix to filter permissions by
   * @returns An array of string containing the filtered permissions
   */
  public getPermissionByModuleName(moduleName: PermissionModules): string[] {
    if (!this.permissionsSubject.value) {
      return [];
    }

    const permissions = this.permissionsSubject.value;
    if (permissions && permissions.length > 0) {
      return permissions
        .filter((perm) => perm.permission.startsWith(moduleName))
        .map((per) => per.permission);
    }

    return [];
  }
}
