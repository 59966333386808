import { IPOTableColumn } from '../shared/components/datatable-po-variant/types';
import { IAvailableModules, ITileType } from './custom-field.types';

export const CustomFieldAvailableModules: IAvailableModules[] = [
  {
    name: 'CustomerModule',
    code: 'customer',
    pendoTracker:
      'custom_fields_add_new_field_module_customers_dropdown_selected',
  },
  {
    name: 'Inventory',
    code: 'inventory',
    pendoTracker:
      'custom_fields_add_new_field_module_inventory_dropdown_selected',
  },
  {
    name: 'Sales Summary',
    code: 'sales',
    pendoTracker:
      'custom_fields_add_new_field_module_possales_dropdown_selected',
  },
];

export const getCustomFieldTiles = (lng: 'ar' | 'en'): ITileType[] => [
  {
    value: 'text',
    text: 'Text',
    imgSrc:
      lng === 'en'
        ? '../../../../../assets/icons/text_en.svg'
        : '../../../../../assets/icons/text_ar.svg',
    pendoTracker: 'custom_fields_add_new_field_data_type_text_card_selected',
  },
  {
    value: 'multi-select',
    text: 'Multi Select',
    imgSrc: '../../../../../assets/icons/multiselect.svg',
    pendoTracker:
      'custom_fields_add_new_field_module_inventory_dropdown_selected',
  },
  {
    value: 'single-select',
    text: 'Single Select',
    imgSrc: '../../../../../assets/icons/select.svg',
    pendoTracker:
      'custom_fields_add_new_field_data_type_singleselect_card_selected',
  },
  {
    value: 'number',
    text: 'Number',
    imgSrc: '../../../../../assets/icons/number.svg',
    pendoTracker: 'custom_fields_add_new_field_data_type_number_card_selected',
  },
  {
    value: 'datetime',
    text: 'Date and Time',
    imgSrc: '../../../../../assets/icons/datetime.svg',
    pendoTracker:
      'custom_fields_add_new_field_data_type_date&time_card_selected',
  },
  {
    value: 'switch',
    text: 'Switch',
    imgSrc: '../../../../../assets/icons/switch.svg',
    pendoTracker: 'custom_fields_add_new_field_data_type_switch_card_selected',
  },
];

export const CustomFieldTableToServerColumnsMapping = {
  name: 'name',
  module: 'module',
  type: 'type',
  printable: 'add_to_receipt_option',
  required: 'required',
};

export const CustomFieldTableColumns: IPOTableColumn[] = [
  {
    header: 'Field Name',
    field: 'name',
    dataType: 'link',
    isSelected: true,
    frozen: true,
    defaultFilter: false,
    skipSort: false,
    skipFilter: true,
    placeholder: 'Field Name',
  },
  {
    header: 'Module',
    field: 'module',
    dataType: 'enum',
    multiple: true,
    isSelected: true,
    matchMode: 'in',
    filter: [],
    defaultFilter: false,
    skipSort: false,
    skipFilter: false,
    placeholder: 'Module',
  },
  {
    header: 'Data Type',
    field: 'type',
    dataType: 'enum',
    multiple: true,
    isSelected: true,
    filter: [],
    matchMode: 'in',
    skipSort: false,
    skipFilter: false,
  },
  {
    header: 'Printable on the invoice',
    field: 'printable',
    isSelected: true,
    dataType: 'boolean',
    filter: false,
    skipSort: false,
    skipFilter: true,
  },
  {
    header: 'Required',
    field: 'required',
    dataType: 'boolean',
    isSelected: true,
    filter: false,
    matchMode: 'in',
    skipSort: false,
    skipFilter: false,
  },
];

export const ExcludedCustomFieldColumnsFromSelection = new Set(['name']);

export const CUSTOM_FIELD_MESSAGES = {
  CREATE_SUCCESS: 'customFields.createSuccess',
  UPDATE_SUCCESS: 'customFields.updateSuccess',
  TOTAL_ROWS_LABEL: 'customFields.totalRowsLabel',
  DELETE_SUCCESS: 'customFields.deleteSuccess',
  DELETE_ERROR: 'customFields.deleteError',
  UPGRADE_SUBSCRIPTION: 'customFields.upgradeSubscription',
  PACKAGE_STATUS_LABEL: 'customFields.packageStatusLabel',
  PACKAGE_LIMIT_REACHED: 'customFields.packageLimitReached',
  PACKAGE_STATUS_SUMMARY: 'customFields.packageStatusSummary',
};
