export const GenderConstant = {
  Male: 'Male',
  Female: 'Female',
  PreferNotToSay: 'Prefernottosay',
} as const;

export type Gender = (typeof GenderConstant)[keyof typeof GenderConstant];

export const CustomerWalletSourceConstant = {
  POSSale: 'POSSale',
  POSReturn: 'POSReturn',
  ReceiveDebit: 'ReceiveDebit',
  JournalEntry: 'JournalEntry',
  OpeningBalance: 'OpeningBalance',
} as const;

export type CustomerWalletSource =
  (typeof CustomerWalletSourceConstant)[keyof typeof CustomerWalletSourceConstant];

export const AutoCompleteFieldConstant = {
  Name: 'name',
  Code: 'code',
  MobileNumber: 'mobileNumber',
};

export type AutoCompleteField =
  (typeof AutoCompleteFieldConstant)[keyof typeof AutoCompleteFieldConstant];

export const CustomerChangeTypeConstant = {
  Updated: 'Updated',
  Created: 'Created',
  Deleted: 'Deleted',
};

export type CustomerChangeType =
  (typeof CustomerChangeTypeConstant)[keyof typeof CustomerChangeTypeConstant];

export type ReceiveDebitSource =
  (typeof ReceiveDebitSourceConstant)[keyof typeof ReceiveDebitSourceConstant];

export const ReceiveDebitSourceConstant = {
  Invoice: 'Invoice',
  OpeningBalance: 'OpeningBalance',
};
