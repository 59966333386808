import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { TranslateModule } from '@ngx-translate/core';
import { AuthRoutingModule } from './auth-routing.module';
import { ConfirmRegistrationComponent } from './components/confirm/confirm-registration.component';
import { LicenseKeyComponent } from './components/dialogs/license-key/license-key.component';
import { ResetPasswordComponent } from './components/forget-password/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterV2Component } from './components/self-onboarding/register-v2.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { SettingAccountComponent } from './components/dialogs/setting-account/setting-account.component';
import { TrialSubscribeUserComponent } from './components/trial-subscribe-user/trial-subscribe-user.component';
import { UserStep1Component } from './components/trial-subscribe-user/components/user-step-1/user-step-1.component';
import { UserStep2Component } from './components/trial-subscribe-user/components/user-step-2/user-step-2.component';
import { UserStep3Component } from './components/trial-subscribe-user/components/user-step-3/user-step-3.component';
import { UserStep4Component } from './components/trial-subscribe-user/components/user-step-4/user-step-4.component';
import { UserStep5Component } from './components/trial-subscribe-user/components/user-step-5/user-step-5.component';

import { AuthService } from './services/auth.service';
import { GuardService } from './services/guard.service';
import { MerchantService } from './services/merchant.service';

import { appsReducer } from '../applications/reducers/app.reducers';
import { CoreModule } from '../core/core.module';
import { DataPermissionsService } from '../data-permissions/services/data-permissions.service';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { NewPasswordComponent } from './components/forget-password/new-password/new-password.component';
import { ActiveGuardService } from './services/active.guard.service';
import { PermissionService } from './services/permission.service';
import { UserAuthenticationService } from './services/user-authentication.service';
import { TimerService } from './services/timer.service';
import { UnAuthenticatedGuardService } from './services/unauthenticated-auth.guard.service';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { SignUpComponent } from './components/self-onboarding/sign-up/sign-up.component';
import { CreatePasswordComponent } from './components/self-onboarding/create-password/create-password.component';
import { PreparingAccountComponent } from './components/self-onboarding/preparing-account/preparing-account.component';
import { VerifyOtpComponent } from './components/self-onboarding/verify-otp/verify-otp.component';
import { UpdatePhoneComponent } from './components/self-onboarding/update-number/update-number.component';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { ResetPasswordV2Component } from './components/forget-password/reset-password-v2/reset-password-v2.component';
import { NewPasswordV2Component } from './components/forget-password/new-password-v2/new-password-v2.component';
import { OnboardingGuideComponent } from './components/onboarding-guide/onboarding-guide.component';

@NgModule({
  imports: [
    BrowserModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature('applications-subscriptions', appsReducer),
    SharedModule,
    UiModule,
    CoreModule,
    FormsModule,
    InputMaskModule,
    PasswordModule,
    TranslateModule,
  ],
  providers: [UserAuthenticationService],
  exports: [
    LoginComponent,
    LoginV2Component,
    RegisterComponent,
    RegisterV2Component,
    SignUpComponent,
    CreatePasswordComponent,
    VerifyOtpComponent,
    UpdatePhoneComponent,
    PreparingAccountComponent,
    SidePanelComponent,
    ResetPasswordComponent,
    ResetPasswordV2Component,
    NewPasswordComponent,
    NewPasswordV2Component,
    ConfirmRegistrationComponent,
    ActivateUserComponent,
    LicenseKeyComponent,
    SettingAccountComponent,
    TrialSubscribeUserComponent,
    UserStep1Component,
    UserStep2Component,
    UserStep3Component,
    UserStep4Component,
    UserStep5Component,
  ],
  declarations: [
    LoginComponent,
    LoginV2Component,
    RegisterComponent,
    RegisterV2Component,
    SignUpComponent,
    CreatePasswordComponent,
    VerifyOtpComponent,
    UpdatePhoneComponent,
    PreparingAccountComponent,
    SidePanelComponent,
    ResetPasswordComponent,
    ResetPasswordV2Component,
    NewPasswordComponent,
    NewPasswordV2Component,
    ConfirmRegistrationComponent,
    ActivateUserComponent,
    LicenseKeyComponent,
    SettingAccountComponent,
    TrialSubscribeUserComponent,
    UserStep1Component,
    UserStep2Component,
    UserStep3Component,
    UserStep4Component,
    UserStep5Component,
    OnboardingGuideComponent,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        GuardService,
        UnAuthenticatedGuardService,
        ActiveGuardService,
        MerchantService,
        AuthService,
        TimerService,
        OnboardingService,
        PermissionService,
        DataPermissionsService,
      ],
    };
  }
}
