import { Injectable } from '@angular/core';
import { PosInvoiceTypes, ProductTypes } from '@rewaa-team/types';
import Dexie, { Table } from 'dexie';
import { Config } from 'firebase/auth';
import { v4 } from 'uuid';
import { InvoiceTypes, PromotionTypes } from '@rewaa-team/pos-sdk'
import { Sell, ParkedInvoice } from '../internal-apps/pos/model/Sell';
import { Invoice } from '../shared/model/invoice/Invoice';
import { Customer } from '../shared/model/order/Customer';
import { OfflineProductVariant } from '../shared/services/offline/indexedDB/stores/OfflineProductVariantStore';
import { OfflinePromotion } from '../shared/services/offline/indexedDB/stores/OfflinePromotionStore';
import { TableOnSteroids } from './lib/table-on-steroids';
import {
  TableName,
  Operation,
  DexieWorkerAction,
  BulkPutInTransactionInput,
} from './types';
import { ParkedSale } from '../point-of-sale/types/parked-sale.type';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends Dexie {
  public variants!: Table<ProductTypes.Variant, number>;

  public customers!: Table<Customer, number>;

  public config!: Table<Config, string>;

  public invoices!: Table<Invoice, string>;

  public sell!: Table<Sell, string>;

  public parkedInvoices!: Table<ParkedInvoice, number>;

  public parkedSales!: Table<ParkedSale, number>;

  public offlineProductVariants!: Table<OfflineProductVariant, number>;

  public offlinePromotions!: Table<OfflinePromotion, number>;

  public promotions!: Table<PromotionTypes.Promotion, number>;

  public sales!: Table<InvoiceTypes.InvoiceCreationPayload, string>;

  private worker: Worker;

  private requests: Map<string, any> = new Map();

  private static db: DatabaseService;

  private constructor(schemaName: string) {
    super(schemaName);
    const schema = {
      [TableName.Variants]: null,
      [TableName.Customers]: null,
      /**
       * @deprecated Should be removed after CustomerV2 is live for 100% users
       */
      [TableName.OfflineCustomers]:
        'id, name, code, mobileNumber, *searchableFields',
      [TableName.OfflineCustomersV2]:
        'id, name, code, mobileNumber, *searchableFields',
      [TableName.Config]: 'key',
      [TableName.Invoices]: 'displayInvoiceNumber',
      [TableName.ParkedInvoices]: '++id, [registerId+createdAt]',
      [TableName.OfflineProductVariants]:
        'id, name, sku, barCode, product.name, productId',
      [TableName.Sell]: 'displayInvoiceNumber',
      /**
       * startDate + endDate is not indexed as compound since multiEntry indices
       * are not supported by indexDB when the index is compound
       * Therefore Dates are compared in memory since the promotions
       * will most likely be active anyways
       * */
      [TableName.OfflinePromotions]:
        'id, isAllProducts, *promotionVariants, endDate',
      [TableName.Promotion]: 'id, endDate, isAll, itemType, *itemIds',
      [TableName.ParkedSales]: '++id, [registerId+createdAt]',
      [TableName.Sales]: 'cart.id',
    };
    const version = 16;
    this.version(version).stores(schema);

    this.worker = new Worker('../../../assets/scripts/dexie-worker.js');
    this.worker.onmessage = (e) => {
      const request = this.requests.get(e.data.requestId);
      if (request) {
        if (e.data.error) {
          request.reject(e.data.error);
        } else {
          request.resolve(e.data.response);
        }
        this.requests.delete(e.data.requestId);
      }
    };
    this.worker.postMessage({
      message: DexieWorkerAction.Initialise,
      schemaName,
      schema,
      version,
    });
  }

  static async getDB(): Promise<DatabaseService> {
    const { schemaName } = JSON.parse(localStorage.getItem('user'));
    if (!this.db || (this.db && this.db.name !== schemaName)) {
      this.db = new DatabaseService(schemaName);
    }

    if (!this.db.isOpen()) this.db.open();
    return this.db;
  }

  getRepository(tableName: TableName, inTransaction = false): TableOnSteroids {
    const tableOnSteroids = new TableOnSteroids(
      this,
      this.table(tableName),
      inTransaction,
    );
    return tableOnSteroids;
  }

  async bulkPutInTransaction(operations: BulkPutInTransactionInput[]) {
    const requestId = `${v4()}`;
    this.worker.postMessage({
      operations,
      requestId,
      transaction: true,
    });
    return new Promise((resolve, reject) => {
      this.requests.set(requestId, {
        resolve,
        reject,
      });
    });
  }

  async operateOnSteroids(operations: Operation[], tableName: string) {
    const requestId = `${v4()}`;
    this.worker.postMessage({
      operations,
      requestId,
      tableName,
    });
    return new Promise((resolve, reject) => {
      this.requests.set(requestId, {
        resolve,
        reject,
      });
    });
  }
}
