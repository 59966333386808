"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPurchaseOrderEnumMapsReverse = exports.createPurchaseOrderEnumMaps = void 0;
const enums_1 = require("./enums");
exports.createPurchaseOrderEnumMaps = {
    status: enums_1.InvoiceStatusEnumMap,
    trackType: enums_1.TrackTypeMap,
    taxType: enums_1.TaxTypesMap,
    type: enums_1.DiscountTypeMap,
};
exports.createPurchaseOrderEnumMapsReverse = {
    status: enums_1.InvoiceStatusEnumReverse,
    trackType: enums_1.TrackTypeReverse,
    taxType: enums_1.TaxTypesReverse,
    type: enums_1.DiscountTypeReverse,
};
