<div
  class="container px-1 bulk-delete-container"
  [ngClass]="langCss"
>
  <div class="px-4">
    <div class="title">
      <span> Delete Supplier </span>
    </div>
  </div>
  <div class="px-4 mb-4">
    <div class="que-wrapper mt-4">
      <span> {{ 'deleteSupplierConfirmation' | translate }}</span>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button
      pendo-tracker="ds-1"
      pButton
      class="btn text-capitalize pt-3 pb-3 pr-5 pl-5 mr-2 p-button-text"
      (click)="content.cancelFunction()"
    >
      {{ 'Cancel' | translate }}
    </button>
    <button
      pendo-tracker="ds-2"
      pButton
      class="btn text-capitalize pt-3 pb-3 pr-5 pl-5 p-button-text delete-btn"
      (click)="onDelete()"
    >
      {{ 'Delete' | translate }}
    </button>
  </div>
</div>
