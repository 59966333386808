export enum TrackingEventName {
  /** @deprecated */
  OfflineInvoiceCreated = 'OfflineInvoiceCreated',
  /** @deprecated */
  OfflineInvoiceSynced = 'OfflineInvoiceSynced',

  PosSaleInvoiceCreated = 'PosSaleInvoiceCreated',
  PosSaleInvoiceCreationFailed = 'PosSaleInvoiceCreationFailed',
  PosSaleInvoiceSynced = 'PosSaleInvoiceSynced',
  PosSaleInvoiceSyncingFailed = 'PosSaleInvoiceSyncingFailed',
  PosOfflineInvoicesSyncCompleted = 'PosOfflineInvoicesSyncCompleted',
  KeyboardShortcutTriggered = 'KeyboardShortcutTriggered',
  PosOfflineSaleInvoiceSyncCompleted = 'PosOfflineSaleInvoiceSyncCompleted',
  RewaaPayConnectionStatus = 'RewaaPayConnectionStatus',
  RewaaPayTransactionStatus = 'RewaaPayTransactionStatus',
  OmniInsfOrUnlinkedErrorResolutionTriggered = 'OmniInsfOrUnlinkedErrorResolutionTriggered',
  OmniPriceLessThanCostResolutionTriggered = 'OmniPriceLessThanCostResolutionTriggered',
  OmniInsfQtyResolutionModeSelected = 'OmniInsfQtyResolutionModeSelected',
  OmniInsfQtyResolutionTriggered = 'OmniInsfQtyResolutionTriggered',
  OmniUnlinkedResolutionModeSelected = 'OmniUnlinkedResolutionModeSelected',
  OmniUnlinkedResolutionTriggered = 'OmniUnlinkedResolutionTriggered',
  OmniReturnInvoiceCreationTriggered = 'OmniReturnInvoiceCreationTriggered',
  OmniAppInstallationAccountConnect = 'OmniInstallationAccountConnect',
  OmniInstallationStockLocationSelect = 'OmniInstallationStockLocationSelect',
  OmniInstallationProductImportStart = 'OmniInstallationProductImportStart',
  OmniInstallationProductImportNext = 'OmniInstallationProductImportNext',
  OfflineCustomerEvent = 'OfflineCustomerEvent',
  OfflineProductFileSynced = 'OfflineProductFileSynced',
  offlineProductChangeSetSynced = 'OfflineProductChangeSetSynced',
}

export enum PosSaleInvoiceMode {
  Offline = 'Offline',
  Online = 'Online',
  OfflineFirst = 'OfflineFirst',
}

export enum PerformanceMarkTag {
  PosSellStarted = 'pos:sell:started',
  PosSellInvoked = 'pos:sell:invoked',
  PosSellBrowserPrintReady = 'pos:sell:browserPrint:ready',
  PosSellPluginPrintInvoked = 'pos:sell:pluginPrint:invoked',
  RewaaPayConnected = 'rewaa:pay:connected',
  RewaaPayDisconnected = 'rewaa:pay:disconnected',
  RewaaPayConnectionDuration = 'rewaa:pay:connection:duration',
  RewaaPayTransactionStarted = 'rewaa:pay:transaction:started',
  RewaaPayTransactionFailed = 'rewaa:pay:transaction:failed',
  RewaaPayTransactionCompleted = 'rewaa:pay:transaction:completed',
  RewaaPayTransactionDuration = 'rewaa:pay:transaction:duration',
}

export enum OfflineCustomerEventType {
  CustomerChangeNotification = 'pos:customer-change-notification',
  CustomerFileNotification = 'pos:customer-file-notification',
  CustomerCreated = 'pos:customer-created',
  CustomerDeleted = 'pos:customer-deleted',
  ChangeSetApiResponse = 'pos:customer-changeset-api-response',
  CustomerFileDownloaded = 'pos:customer-file-downloaded',
}
