export const getStockControlProtoDirectory = (): string =>
  'assets/proto/services/stock-control/proto';

export const prepareProtobufRequestPayload = (
  protoBufferPayload: Uint8Array,
): ArrayBuffer =>
  protoBufferPayload.buffer.slice(
    protoBufferPayload.byteOffset,
    protoBufferPayload.byteOffset + protoBufferPayload.byteLength,
  );
