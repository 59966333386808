import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

const API_URL = '/api';
const baseInvUrl = '/inventory-service';

@Injectable()
export class FileUploadService {
  constructor(private http: HttpClient) {}

  requestUploadURL(file): Observable<any> {
    return this.http.post<any>(`${API_URL}/requestImageUploadURL`, file);
  }

  uploadFile(url, file): Observable<any> {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
    });
    return this.http.request(req);
  }

  safeUploadFile(params): Observable<any> {
    return this.http.post<any>(`${API_URL}/files-safe`, params);
  }

  saveFileDetails(
    name: string,
    fileType: string,
    bucketUrl: string,
  ): Observable<any> {
    const fileDetails = { s3TmpKey: name, link: bucketUrl, type: fileType };
    return this.http
      .post(`${API_URL}/files`, fileDetails, { observe: 'response' })
      .pipe(
        map((response) => {
          if (response.status === 200 && response.body !== null) {
            return response.body;
          }
          return null;
        }),
      );
  }

  deleteFile(fileId: number): Observable<boolean> {
    const deleteParams = new HttpParams().set('id', fileId.toString());
    return this.http
      .delete(`${API_URL}/files`, { params: deleteParams, observe: 'response' })
      .pipe(map((response) => response.status === 200));
  }

  getFile(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  uploadToInventoryService(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(
      `${API_URL}${baseInvUrl}/images/upload`,
      formData,
    );
  }
}
