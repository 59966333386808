import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Brand, BrandResult } from '../model/brand';
import { PageQuery } from '../../shared/services/pagination-interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';

const API_URL = '/api';
const featureFlagName = FeatureFlagEnum.InventoryService;
const baseInvUrl = '/inventory-service';

@Injectable()
export class BrandService {
  private brands$: BehaviorSubject<Brand[] | undefined> = new BehaviorSubject(
    undefined,
  );

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  getBrands(): Observable<Brand[]> {
    if (this.brandsLoaded()) return this.getBrandsObs();
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          return this.getBrandsFromInventoryService();
        }
        return this.getBrandsFromMims();
      }),
      first(),
    );
  }

  getBrandsFromInventoryService(): Observable<Brand[]> {
    return this.http
      .get<{ data: Brand[] }>(`${API_URL}${baseInvUrl}/brands`)
      .pipe(
        map((response) => {
          const brands = response.data.map((brand) => new Brand(brand));
          this.brands$.next(brands);
          return brands;
        }),
      );
  }

  getBrandsFromMims(): Observable<Brand[]> {
    return this.http.get<Brand[]>(`${API_URL}/brands`).pipe(
      map((response) => {
        const brands = response.map((brand) => new Brand(brand));
        this.brands$.next(brands);
        return brands;
      }),
    );
  }

  private brandsLoaded(): boolean {
    return Boolean(this.brands$.getValue());
  }

  private getBrandsObs() {
    return this.brands$.asObservable();
  }

  getBrandsPage(
    { limit, offset, query }: PageQuery = { limit: 10, offset: 0, query: '' },
  ): Observable<{ result: Brand[]; count: number }> {
    return this.http.get<{ result: Brand[]; count: number }>(
      `${API_URL}/brands?offset=${offset}&limit=${limit}&query=${query}`,
    );
  }

  public addBrand(brand): Observable<BrandResult> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          return this.addBrandToInventoryService(brand);
        }
        return this.addBrandToMims(brand);
      }),
      first(),
    );
  }

  private addBrandToInventoryService(brand): Observable<BrandResult> {
    return this.http
      .post<{ data: Brand }>(`${API_URL}${baseInvUrl}/brands`, brand)
      .pipe(
        map((response) => {
          if (response.data) {
            response.data = new Brand(response.data);
            this.pushBrand(response.data);
          }
          return { brand: response.data };
        }),
      );
  }

  private addBrandToMims(brand): Observable<BrandResult> {
    return this.http.post<BrandResult>(`${API_URL}/brands`, brand).pipe(
      map((response) => {
        if (response.brand) {
          response.brand = new Brand(response.brand);
          this.pushBrand(response.brand);
        }
        return response;
      }),
    );
  }

  pushBrand(brand: Brand) {
    this.brands$.getValue().push(brand);
    this.brands$.next(this.brands$.getValue());
  }

  getBrandByName(brandName: string): Observable<Brand> {
    const brands = this.brands$.getValue();
    return of(brands.find((brand) => brand.name === brandName));
  }
}
