import { environment } from 'src/environments/environment';
import { datadogRum } from '@datadog/browser-rum';

export function dataDogInitialize() {
  return (): void => {
    try {
      if (environment.enableDatadog) {
        datadogRum.init({
          env: environment.name,
          applicationId: 'dfe377a8-c7fa-4cc9-98e4-bbe2e0a4fc45',
          clientToken: 'pub5d89d6f309db076e9f02dd6a2391b043',
          site: 'datadoghq.com',
          service: 'Rewaa Platform',
          //  env: 'production',
          version: environment.appVersion,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          excludedActivityUrls: [
            // Google APIs
            /analytics\.google\.com/,
            /stats\.g\.doubleclick\.net/,
            /identitytoolkit\.googleapis\.com/,
            /www\.googletagmanager\.com/,
            // Firestore
            /firestore\.googleapis\.com/,
            // Intercom
            /intercom\.io/,
            // LaunchDarkly
            /launchdarkly\.com/,
            // Service Worker
            /platform.rewaatech.com\/ngsw\.json/,
            // User Guiding
            /userguiding\.com/,
            // Full Story
            (url) => url.startsWith('https://rs.fullstory.com/'),
          ],
          allowedTracingUrls: [
            (url) => url.startsWith('https://platform.rewaatech.com/api'),
            (url) => url.startsWith('https://api.platform.rewaatech.com'),
            (url) =>
              environment.name === 'prod' &&
              url.startsWith(environment.dashboardApiUrl),
            (url) =>
              environment.name === 'prod' &&
              url.startsWith(environment.reportingApiUrl),
            (url) =>
              environment.name === 'prod' &&
              url.startsWith(environment.imexUrl),
          ],
          defaultPrivacyLevel: 'allow',
        });
        datadogRum.startSessionReplayRecording();
      }
    } catch (e) {
      console.error(e);
    }
  };
}
