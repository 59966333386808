import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { HttpStatusCode } from '@rewaa-team/rewaa-common';
import { environment } from '../../../environments/environment';
import {
  Answer,
  GetBusinessSectorDTO,
  IMerchantOnboardingStatusDto,
  GetOnboardingStatusDTO,
  Question,
} from '../../getting-to-know-rewaa/models/user-onboarding.models';
import { LocalStorageKey, Sector } from '../constants';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { CompanyType } from './constants/business-types';
import { OnboardingStatus } from './constants/onboarding-status';

@Injectable()
export class UserOnboardingService {
  private apiUrl = environment.onboardingApiPath;

  private businessSector$ = new BehaviorSubject<Sector>(null);

  private businessCompanyType$ = new BehaviorSubject<CompanyType>(null);

  private onboardingStatus$ = new BehaviorSubject<OnboardingStatus>(null);

  private userHasAnswers = true;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
  ) {}

  getOnboardingQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.apiUrl}/questions`);
  }

  postAnswers(answers: Answer[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/answers`, {
      answers,
    });
  }

  updateOnboardingStatus(
    onboardingStatus: IMerchantOnboardingStatusDto,
  ): Promise<void> {
    return firstValueFrom(
      this.http.put<void>(
        `${this.apiUrl}/merchant/onboarding-status`,
        onboardingStatus,
      ),
    );
  }

  private async fetchBusinessTypeFromApi(): Promise<GetBusinessSectorDTO> {
    return firstValueFrom(
      this.http.get<GetBusinessSectorDTO>(
        `${this.apiUrl}/answers/question-type`,
      ),
    );
  }

  private async fetchOnboardingStatusFromApi(): Promise<GetOnboardingStatusDTO> {
    return firstValueFrom(
      this.http.get<GetOnboardingStatusDTO>(
        `${this.apiUrl}/merchant/onboarding-status`,
      ),
    );
  }

  private updateBusinessType(businessAnswers: GetBusinessSectorDTO): void {
    this.businessSector$.next(businessAnswers?.SECTOR);
    this.businessCompanyType$.next(businessAnswers?.COMPANY_TYPE);
  }

  private fetchFromLocalStorage(): void {
    const businessAnswers =
      this.localStorageService.getItem<GetBusinessSectorDTO>(
        LocalStorageKey.BusinessType,
        null,
      );
    if (!businessAnswers) return;
    this.updateBusinessType(businessAnswers);
    this.userHasAnswers = !!this.businessSector$.value;
    const onboardingStatus = this.localStorageService.getItem<OnboardingStatus>(
      LocalStorageKey.OnboardingStatus,
      null,
    );
    this.onboardingStatus$.next(onboardingStatus);
  }

  async refreshBusinessType(): Promise<void> {
    this.businessSector$.next(null);
    this.businessCompanyType$.next(null);
    this.localStorageService.removeItem(LocalStorageKey.BusinessType);
    await this.fetchBusinessType();
  }

  async refreshOnboardingStatus(): Promise<void> {
    this.localStorageService.removeItem(LocalStorageKey.OnboardingStatus);
    this.fetchOnboardingStatus();
  }

  async fetchBusinessType(): Promise<void> {
    this.fetchFromLocalStorage();
    if (this.businessSector$.value === null) {
      try {
        const businessAnswers = await this.fetchBusinessTypeFromApi();
        this.updateBusinessType(businessAnswers);
        this.localStorageService.setItem(
          LocalStorageKey.BusinessType,
          businessAnswers,
        );
        this.userHasAnswers = true;
      } catch (error) {
        this.updateBusinessType(null);
        if (error.error.statusCode === HttpStatusCode.NotFound) {
          this.userHasAnswers = false;
        }
      }
    }
    if (this.isBusinessSectorRetail()) await this.fetchOnboardingStatus();
  }

  private saveOnboardingStatusToLocalStorage(
    onboardingStatus: OnboardingStatus,
  ): void {
    if (!this.isStatusTrialDemo())
      this.localStorageService.setItem(
        LocalStorageKey.OnboardingStatus,
        onboardingStatus,
      );
  }

  async fetchOnboardingStatus(): Promise<void> {
    try {
      const status = await this.fetchOnboardingStatusFromApi();
      this.saveOnboardingStatusToLocalStorage(status.onboardingStatus);
      this.onboardingStatus$.next(status.onboardingStatus);
    } catch (error) {
      console.error(error);
    }
  }

  getOnboardingStatus(): Observable<OnboardingStatus> {
    return this.onboardingStatus$.asObservable();
  }

  doesUserHaveAnswers(): boolean {
    return this.userHasAnswers;
  }

  isBusinessSectorRetail(): boolean {
    return this.businessSector$.value === Sector.Retail;
  }

  isStatusTrialSetup(): boolean {
    return this.onboardingStatus$.value === OnboardingStatus.InTrialSetup;
  }

  isStatusTrialDemo(): boolean {
    return this.onboardingStatus$.value === OnboardingStatus.InTrialDemo;
  }

  getBusinessCompanyType(): CompanyType {
    return this.businessCompanyType$.value;
  }
}
