import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { Intercom } from 'ng-intercom';
import { UserService } from '../auth/services/user.service';
import { UserAuthenticationService } from '../auth/services/user-authentication.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { LocalStorageKey } from '../shared/constants';
import { RoutePermissionsService } from '../shared/services/route-permissions.service';
import { getLocalizedUrl } from '../utils/localizedUrl';

@Component({
  selector: 'rw-subscription-fallback',
  templateUrl: './subscription-fallback.component.html',
  styleUrls: ['./subscription-fallback.component.scss'],
})
export class SubscriptionFallbackComponent implements OnInit {
  visible = true;

  users: any;

  lang: string;

  langCss: string;

  changeLang: string;

  languageTitle: string;

  userMenuItems: any[];

  private switchLanguageSub: any | null;

  translationKey = 'subscriptionFallbackComponent.';

  constructor(
    private router: Router,
    private translate: TranslateService,
    public userService: UserService,
    private userAuthenticationService: UserAuthenticationService,
    private localStorageService: LocalStorageService,
    private readonly routePermissionsService: RoutePermissionsService,
    public intercom: Intercom,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );

    this.userService.getUser().subscribe((currentUser) => {
      this.users = currentUser;
    });

    this.switchLanguageSub = null;
    this.translate.setDefaultLang(this.lang);
    this.langCss = this.lang === 'en' ? 'ltr' : 'rtl';
    if (this.lang === 'en') {
      this.changeLang = 'ar';
      this.languageTitle = 'Arabic';
    } else {
      this.langCss = 'rtl';
      this.changeLang = 'en';
      this.languageTitle = 'English';
    }
  }

  ngOnInit(): void {
    const user = this.localStorageService.getItem<any>(LocalStorageKey.User);

    if (user) this.translate.use(this.lang);

    this.userMenuItems = [
      {
        label: this.translate.instant(this.languageTitle),
        icon: 'fa-regular fa-globe',
        styleClass: 'arabic-font',
        command: () => {
          this.switchLanguage(this.changeLang);
        },
      },
      {
        label: this.translate.instant('Logout'),
        icon: 'fa-regular fa-arrow-right-from-bracket',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  openIntercom() {
    this.visible = false;
    this.intercom.show();
  }

  logout() {
    this.userAuthenticationService.logout();
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
      'en',
    );
    this.router
      .navigateByUrl(`${lang}/login`)
      .then(() => window.location.reload());
  }

  switchLanguage(lang: string) {
    if (this.switchLanguageSub) this.switchLanguageSub.unsubscribe();

    this.switchLanguageSub = timer(2000).subscribe(() => {
      this.localStorageService.setItem<string>(LocalStorageKey.Language, lang);
      window.location.pathname = getLocalizedUrl(lang);
    });
  }

  async goToDashboard() {
    const landingUrl =
      await this.routePermissionsService.handleUrlPermissions('');
    if (typeof landingUrl === 'string') {
      this.router.navigate([landingUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
