import { Big as BigNumber } from 'big.js';
import { ICalculator } from '../interfaces/calculator.interface';

export class CalculationServiceV2 implements ICalculator {
  constructor(private readonly decimals = 2) {
    if (decimals < 1 || decimals > 10) {
      throw new Error('Precision must be between 1 and 10');
    }
  }

  add(a: number, b: number, decimals?: number): number {
    const bigA = new BigNumber(a);
    const bigB = new BigNumber(b);
    if (decimals) {
      return bigA.plus(bigB).round(decimals).toNumber();
    }
    return bigA.plus(bigB).round(this.decimals).toNumber();
  }

  subtract(a: number, b: number, decimals?: number): number {
    const bigA = new BigNumber(a);
    const bigB = new BigNumber(b);
    if (decimals) {
      return bigA.minus(bigB).round(decimals).toNumber();
    }
    return bigA.minus(bigB).round(this.decimals).toNumber();
  }

  divide(a: number, b: number, decimals?: number): number {
    const bigA = new BigNumber(a);
    const bigB = new BigNumber(b);
    if (decimals) {
      return bigA.div(bigB).round(decimals).toNumber();
    }
    return bigA.div(bigB).round(this.decimals).toNumber();
  }

  multiply(a: number, b: number, decimals?: number): number {
    const bigA = new BigNumber(a);
    const bigB = new BigNumber(b);
    if (decimals) {
      return bigA.mul(bigB).round(decimals).toNumber();
    }
    return bigA.mul(bigB).round(this.decimals).toNumber();
  }

  roundToFixedDigits(value: number | string, decimals = 2): number {
    if (typeof value === 'string' && isNaN(Number(value))) {
      throw new Error('value must be a numeric string');
    }
    const bigValue = new BigNumber(value);
    return bigValue.round(decimals).toNumber();
  }
}

export const calculationServiceV2 = new CalculationServiceV2();
