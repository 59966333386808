import { Promotion } from 'src/app/internal-apps/promotion/model/Promotions';
import { VariantFile } from './VariantFile';
import { AppProductStatus } from '../../shared/model/AppProductStatus';
import { StockLocation } from '../../shared/model/StockLocation';
import { ProductVariantStockLocation } from '../../shared/model/ProductVariantStockLocation';
// eslint-disable-next-line import/no-cycle
import { Extra, VariantExtraLocation } from './extra';
import { Ecard } from './ecard';
// eslint-disable-next-line import/no-cycle
import { Product } from './product';
import { CustomFieldData } from '../../shared/components/custom-fields-input/interfaces/CustomFieldData.interface';
import { PromotionTypes } from '@rewaa-team/pos-sdk';
import { VariantToInvoice } from '../../shared/model/invoice/VariantToInvoice';

export class ProductVariant {

  public createdAt?: string;

  public updatedAt?: string;

  public isSelected?: boolean;

  public id?: number;

  public name: string;

  public sku: string;

  public handlingTime?: number;

  public shippingHeight?: number;

  public shippingLength?: number;

  public shippingWeight?: number;

  public shippingWidth?: number;

  public initialStock?: number;

  public isContainsBattery?: boolean;

  public isFreeShipping?: boolean;

  public isWeightedScale?: boolean;

  public isPurchasable: boolean;

  public isPackgable: boolean;

  public selected? = false;

  public image?: any;

  public barCode: string;

  public supplierCode?: string;

  public description: string;

  public isSellable: boolean;

  public isTaxable?: boolean;

  public manageStockLevel: boolean;

  public stockLocationId?: number;

  public stockLocation?: StockLocation;

  public variantAttributes: any = [];

  public VariantToPackages: any = [];

  public VariantToInvoices?: VariantToInvoice[] = [];

  public batchTracked?: boolean;

  public Files?: VariantFile[];

  public productId?: number;

  public packageParentId?: number;

  public country?: number;

  public rate?: number;

  public productName?: string;

  public Ecards?: Ecard[];

  public mediaUrl?: string;

  public AppProductStatuses?: AppProductStatus[];

  public ProductVariantToStockLocations?: ProductVariantStockLocation[];

  public Extras?: Array<Extra> = [];

  public Product?: Product;

  public type?: string;

  public trackType?: 'batch' | 'serial';

  public VariantExtraLocations?: VariantExtraLocation[];

  public promotion?: PromotionTypes.Promotion;

  public promotions?: PromotionTypes.Promotion[];

  public CustomFieldsData?: CustomFieldData[];

  public categoryId?: number;

  public supplierId?: number;

  public brandId?: number;
}
