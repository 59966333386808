import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, switchMap, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { ValidateReferralCodeRequestDto } from '../types';

export interface WithdrawRequestPayload {
  amount: number;
  fullName: string;
  email: string;
  bankName: string;
  ibanNumber: string;
  idDocumentBase64: string;
  saveDetails?: boolean;
}

export interface WithdrawRequestSavedPayload {
  amount: number;
  fullName: string;
  email: string;
  bankName: string;
  ibanNumber: string;
  existingIdDocumentURL: string;
  saveDetails?: boolean;
}

const MARKETING_URL_PREFIX = '/api/marketing';
@Injectable()
export class ReferralProgramService {
  private userReferralLink: string;

  private userPoints$: any = null;

  constructor(
    private http: HttpClient,
    private readonly featureFlag: FeatureFlagService,
  ) {}

  getAffiliateLink(): Observable<any> {
    return this.http.get<any>(
      `${MARKETING_URL_PREFIX}/referrals/affiliate-link`,
    );
  }

  getInviteeCoupon(): Observable<any> {
    return this.http.get<any>(
      `${MARKETING_URL_PREFIX}/referrals/invitee-coupon`,
    );
  }

  getPaymentDetails(): Observable<any> {
    return this.http.get<any>(`${MARKETING_URL_PREFIX}/payments/details`);
  }

  claimPointsToChargeBee(appName, currentAmount): Observable<any> {
    const CHARGEBEE = 'chargebee';
    return this.http.post<any>(`${MARKETING_URL_PREFIX}/payments`, {
      amount: currentAmount,
      paymentMethod: CHARGEBEE,
      appName,
    });
  }

  claimReward(
    payload: WithdrawRequestPayload | WithdrawRequestSavedPayload,
  ): Observable<any> {
    return this.http.post<any>(
      `${MARKETING_URL_PREFIX}/payments/withdraw-request`,
      payload,
    );
  }

  public getAffiliateBalance(): Observable<any> {
    if (!this.userPoints$) {
      this.userPoints$ = new BehaviorSubject<any>(null);
      this.http
        .get(`${MARKETING_URL_PREFIX}/referrals/balance`)
        .pipe(
          tap((response: { balance: number }) => {
            if (response.balance) {
              this.userPoints$.next(response.balance);
            } else {
              this.userPoints$.next(0);
            }
          }),
        )
        .subscribe();
    }
    return this.userPoints$.asObservable();
  }

  public refreshUserPoints(): void {
    this.userPoints$ = null;
  }

  getInviteeCount(): Observable<any> {
    return this.http.get<any>(
      `${MARKETING_URL_PREFIX}/referrals/invitee-count`,
    );
  }

  findUserIsReseller(): Observable<any> {
    return this.featureFlag.isEnabled(FeatureFlagEnum.Marketing, true).pipe(
      switchMap((marketingEnabled) => {
        console.info('marketingEnabled:', marketingEnabled);

        if (marketingEnabled) {
          return this.http
            .get<{
              isReseller: boolean;
            }>(`${MARKETING_URL_PREFIX}/referrals/isreseller`)
            .pipe(
              map((result) => result.isReseller),
              catchError((_error) => of(false)),
            );
        }
        return of(false);
      }),
    );
  }

  validateReferralCode(referralCode: string): Observable<void> {
    const payload: ValidateReferralCodeRequestDto = { code: referralCode };
    return this.http.post<void>(
      `${MARKETING_URL_PREFIX}/referrals/validate-affiliate-code`,
      payload,
    );
  }

  get referralLink(): string {
    return this.userReferralLink;
  }

  set referralLink(value: string) {
    this.userReferralLink = value;
  }
}
